import React from 'react';
import CommonPreOrderView from 'components/PreOrder/CommonPreOrderView';

const PreOrderView = ({ preOrderDetail, onClose }) => {
  return (
    <CommonPreOrderView
      preOrderDetail={preOrderDetail}
      onClose={onClose}
      spacing={1}
    />
  );
};

export default PreOrderView; 