import React, { useState } from "react";
import { DialogContent, FormControl, MenuItem, Select } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Close } from '@mui/icons-material';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { dataService } from "services/data-service";
import CommonPreOrderView from "components/PreOrder/CommonPreOrderView";


function ViewPreOrderDialog({ onClose, open, setIsReload, isReload }) {

    const data = open?.data

    const [status, setStatus] = useState(open?.data?.Status);

    const handleClose = () => {
        onClose();

        if (open?.data?.Status !== status) {
            setIsReload(!isReload);
        }
    };

    const handleStatusUpdate = async (event) => {
        const newStatus = event.target.value;

        try {
            let res = await dataService('post', `PreOrderStatusUpdate/${data?.PreOrderId}`, { Status: newStatus });
            if (res.status === 200) {
                let resData = res?.data
                if (resData) {
                    setStatus(newStatus);
                }
            }
        } catch (error) {
            console.log("Error in updating status", error);
        }
    };

    const themeData = createTheme({
        components: {
            MuiSelect: {
                styleOverrides: {
                    select: {
                        color: "white",
                        border: "none !important",
                        fontWeight: "bold",
                        backgroundColor: status === "Pending" ? "#11CDEF" : status === "Approved" ? "#4CAF50" : "#F44336",
                        borderRadius: "8px",
                    },
                },
            },
        },
    })

    return (
        <Dialog onClose={handleClose} fullWidth={true} maxWidth="lg" aria-labelledby="simple-dialog-title" sx={{ margin: -2 }} open={open?.isVisible}>
            <div className={`border-2 flex flex-wrap items-center justify-between`}>
                        <DialogTitle id="form-dialog-title" className='flex flex-row items-center gap-2'>
                            <span className="text-base sm:text-xl">
                                {"Pre Order View"}
                            </span>
                            <ThemeProvider theme={themeData}>
                                <FormControl size="small" sx={{ minWidth: 120 }}>
                                    <Select
                                        labelId="status-select-label"
                                        value={status}
                                        disabled={status === "Approved"}
                                        onChange={handleStatusUpdate}
                                        size="small"
                                        sx={{
                                            "& .MuiSelect-MuiOutlinedInput  ": {
                                                color: "white",
                                                bgcolor: "red"
                                            },
                                        }}
                                    >
                                        <MenuItem value="Pending">Pending</MenuItem>
                                        <MenuItem value="Approved">Approved</MenuItem>
                                        <MenuItem value="Canceled">Canceled</MenuItem>
                                    </Select>
                                </FormControl>
                            </ThemeProvider>

                        </DialogTitle>
                        <Close className="w-6 h-auto mr-2 -mt-6 cursor-pointer" onClick={handleClose} />
                    </div>
            <DialogContent>
                <CommonPreOrderView
                    preOrderDetail={open.data}
                    onClose={onClose}
                    spacing={0.5}
                    showHeader={false}
                    isPreOrder={true}
                />
            </DialogContent>
        </Dialog>
    );
};
export default ViewPreOrderDialog;