import React, { useEffect, useState } from "react";

// @mui/material components
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
// core components
import Header from "components/Headers/Header.js";
import componentStyles from "assets/theme/views/admin/tables.js";
import { dataService } from "services/data-service";
import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import ProductionFlowTable from "components/Tables/ProductionFlowTable";
import ViewOrderDialog from "../../../../components/dialoges/ViewOrders/ViewOrderDialog";
import OrdersCommentsDialog from "components/dialoges/OrdersCommentsDialog";

const useStyles = makeStyles({
  ...componentStyles,
  tableContainer: {
    "& .MuiTableCell-root": {
      padding: "3px 6px",
      borderRight: "1px solid rgba(224, 224, 224, 0.3)",
      whiteSpace: "nowrap",
    },
    "& .MuiTableHead-root .MuiTableCell-root": {
      fontWeight: "bold",
      backgroundColor: "#f0f3f8",
      fontSize: "0.7rem",
      color: "#5e6e82",
      textTransform: "uppercase",
      letterSpacing: "0.5px",
    },
    "& .MuiTableBody-root .MuiTableRow-root:hover": {
      backgroundColor: "rgba(245, 247, 250, 0.5)",
    },
  },
  statusBox: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "1px 2px",
    textAlign: "center",
    borderRadius: "4px",
    padding: "3px 6px",
    fontWeight: "600",
    color: "white",
    minWidth: "32px",
    height: "22px",
    fontSize: "0.75rem",
    boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
  },
  statusContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    padding: "5px 0",
    gap: "3px",
  },
  orderNumberCell: {
    minWidth: "100px",
    paddingLeft: "16px !important",
  }
});

const getStatusColor = (status) => {
  switch (status?.trim()?.toUpperCase()) {
    case 'FINISHED':
      return 'bg-[#768c39]';
    case 'IN':
      return 'bg-[#3799cb]';
    case 'STARTED':
      return 'bg-[#b78d47]';
    case 'HOLD':
      return 'bg-[#9b1620]';
    default:
      return 'bg-[#b78d47]';
  }
};

const StatusBox = ({ value, status }) => {
  const classes = useStyles();
  return (
    <span className={`${classes.statusBox} ${getStatusColor(status)}`}>
      {value}
    </span>
  );
};

const ProcessStatus = ({ value }) => {
  const classes = useStyles();
  if (!value) return null;

  const hasHold = value.toUpperCase().includes("HOLD");

  const statusMatches = value.match(/(\d+)\s*{([^{}]+)}/g) || [];
  const statusSums = {};

  statusMatches.forEach(match => {
    const quantityMatch = match.match(/(\d+)/);
    const statusesMatch = match.match(/{([^{}]+)}/g);

    if (quantityMatch && statusesMatch) {
      const quantity = parseInt(quantityMatch[0], 10);

      statusesMatch.forEach(statusBraced => {
        const status = statusBraced.replace(/{|}/g, '').trim().toUpperCase();
        statusSums[status] = (statusSums[status] || 0) + quantity;
      });
    }
  });

  return (
    <div className={classes.statusContainer}>
      {Object.entries(statusSums).map(([status, totalQuantity], index) => (
        <StatusBox
          key={index}
          value={totalQuantity}
          status={hasHold ? "HOLD" : status}
        />
      ))}
    </div>
  );
};

const StickyColumnWrapper = ({ children, isHeader = false, onClick = () => { } }) => {
  return (
    <div className={isHeader ? "sticky-header cursor-pointer" : "sticky-column cursor-pointer"} onClick={onClick}>
      {children}
    </div>
  );
};

const ProductionFlowList = () => {
  const classes = useStyles();
  const [productionFlowList, setProductionFlowList] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [processColumns, setProcessColumns] = useState([]);

  const [page, setPage] = useState(1);
  const [orderBy, setOrderByData] = useState('OrderNumber');
  const [order, setOrderData] = useState('desc');
  const [offset, setOffset] = useState(10);
  const [statusFilter, setStatusFilter] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [productList, setProductList] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState(1);

  const [open, setOpen] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [isReload, setIsReload] = useState(false);

  const [loadingRows, setLoadingRows] = useState({});
  const [viewDetailDialog, setViewDetailDialog] = useState({
    isVisible: false,
    data: null,
    extraData: null
  });

  useEffect(() => {
    const fetchOrderData = async () => {
      setIsLoading(true);
      let queryParams = `page=${page}&offset=${offset}&productId=${selectedProductId}`;

      if (statusFilter && statusFilter !== "ALL") {
        queryParams += `&flowStatus=${statusFilter}`;
      }

      let res = await dataService('get', 'orders/production/flow', queryParams);
      setProductionFlowList(res?.data || []);
      setTotalRecord(res?.totalRecord || 0);
      setTotalQuantity(res?.totalQuantity || 0);

      if (res?.data && res.data.length > 0) {
        const firstItem = res.data[0];
        const columns = Object.keys(firstItem).filter(key => key !== 'OrderNumber' && key !== 'OrderId');
        setProcessColumns(columns);
      } else {
        if (processColumns.length === 0) {
          setProcessColumns(['PENDING ORDERS', 'PHOTO MAKING', 'TAGGING', 'CUTTING', 'BOARD CUTTING', 'PHOTO PRINT', 'LAMINATION', 'BINDING', 'INTERLOCK', 'ZIGZAG', 'SCREEN PRINTING', 'REVEAL CORNER', 'BUNCH SET']);
        }
      }

      setIsLoading(false);
    }

    fetchOrderData();
  }, [page, offset, selectedProductId, statusFilter]);

  const viewAction = (OrderDetail) => {
    setOpen(true);
    setOrderId(OrderDetail.OrderId);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (localStorage.getItem('products')) {
        let productsAr = JSON.parse(localStorage.getItem('products'));
        setProductList(productsAr);
      }
      else {
        let res = await getProductList();
        setProductList(res?.data);
      }
    }
    fetchData()
  }, [])

  const getProductList = async () => {
    let res = await dataService('get', 'ProductList', null);
    if (res?.data) {
      localStorage.setItem('products', JSON.stringify(res?.data));
      return res;
    }
  }

  const handleSelect = async (ProductId) => {
    setSelectedProductId(parseInt(ProductId));
  }

  const handleRowClick = async (row) => {
    setLoadingRows((prevRows) => ({
      ...prevRows,
      [row.OrderId]: true
    }));
    try {
      let res = await dataService('get', `OrdersCommentsList/${row?.OrderId}`, '');
      if (res.status === 200 && res?.data) {
        setViewDetailDialog({
          isVisible: true,
          data: res?.data,
          extraData: { ProductName: row?.ProductName || "", Quantity: row?.Quantity || "", CustomerName: row?.CustomerName || "", FileName: row?.FileName || "", OrderNumber: row?.OrderNumber || "", OrderId: row?.OrderId }
        });
      }
      setLoadingRows((prevRows) => ({
        ...prevRows,
        [row.OrderId]: false
      }));
    } catch (error) {
      setLoadingRows((prevRows) => ({
        ...prevRows,
        [row.OrderId]: false
      }));
    }
  }

  const columns = [
    {
      id: 'OrderNumber',
      numeric: false,
      disablePadding: false,
      label: 'ORDER NUMBER',
      className: classes.orderNumberCell,
      Component: ({ row }) => {
        return (
          <StickyColumnWrapper onClick={() => viewAction(row)}>
            <Typography variant="body2" fontWeight="bold" fontSize="0.85rem" sx={{ padding: '6px 2px' }}>#{row?.OrderNumber}</Typography>
          </StickyColumnWrapper>
        );
      },
      HeaderComponent: () => {
        return (
          <StickyColumnWrapper isHeader={true}>
            ORDER NUMBER
          </StickyColumnWrapper>
        );
      }
    },
    ...processColumns.map(column => ({
      id: column,
      numeric: false,
      disablePadding: false,
      label: column.toUpperCase(),
      Component: ({ row }) => <ProcessStatus value={row[column]} />
    }))
  ];

  return (
    <>
      <Header />
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <RadioGroup row aria-label="position" className="mb-0 text-black mr-auto ml-0" onChange={(e) => handleSelect(e?.target?.value)} name="position" value={selectedProductId}>
          {productList?.map((product, index) => {
            return <FormControlLabel className="z-10" key={index} value={product?.ProductId} control={<Radio color="default" />} label={product?.Name} />
          })}
        </RadioGroup>
        <ProductionFlowTable
          title="Production Flow"
          columns={columns}
          rows={productionFlowList}
          page={page - 1}
          total={totalRecord}
          totalQuantity={totalQuantity}
          pagedata={setPage}
          order={order}
          orderBy={orderBy}
          orderData={setOrderData}
          orderByData={setOrderByData}
          setOffset={setOffset}
          offset={offset}
          actions={[]}
          loading={isLoading}
          className={classes.tableContainer}
          isProductionFlow={true}
          statusFilter={statusFilter}
          setStatusFilter={setStatusFilter}
          loadingRows={loadingRows}
          handleRowClick={handleRowClick}
          uniqueKey="OrderId"
        />
        {orderId && open &&
          <ViewOrderDialog open={open} onClose={setOpen} OrderId={orderId} setIsReload={setIsReload} isReload={isReload} />
        }

        {viewDetailDialog?.isVisible &&
          <OrdersCommentsDialog
            isOrderFlow={false}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setIsReload={setIsReload}
            isReload={isReload}
            open={viewDetailDialog}
            setOpen={setViewDetailDialog}
            onClose={() => {
              setViewDetailDialog({ data: null, isVisible: false, extraData: null })
            }} />}
      </Container>
    </>
  );
};

export default ProductionFlowList;