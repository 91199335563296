import React from "react";
import { Checkbox, Grid, Stack, useMediaQuery, useTheme } from '@mui/material';
import { CheckboxGroup, CustomFormGroup, ReadOnlyInputRemarks, Section } from "./CommonOrderView";

function ViewShadeCard(props) {
    const { orderData } = props;

    const theme = useTheme();

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <Section title="Party Fabric">
                <Grid item xs={12}>
                    <Stack spacing={0.5}>
                        <CustomFormGroup items={[
                            { label: "Full", value: orderData?.PBFull || "-" },
                            { label: "Full Meter", value: orderData?.PBFullMtr || "-" },
                            { label: "Main", value: orderData?.PBMain || "-" },
                            { label: "Main Meter", value: orderData?.PBMainMtr || "-" },
                            { label: "Total", value: orderData?.PBTotal || "-" }
                        ]} />
                    </Stack>
                </Grid>
                {orderData?.PBRemarks &&
                <Grid item xs={12}>
                    <Stack spacing={0.5}>
                        <ReadOnlyInputRemarks label="Remarks" xs={12} lg={12} sm={12} value={orderData?.PBRemarks || "-"} />
                    </Stack>
                </Grid>}
            </Section>
            
            <Section title="Cutting">
                <Grid item xs={12} lg={7}>
                    <Stack spacing={0.5}>
                        <CustomFormGroup items={[
                               { label: "Small Piece Patta", value: orderData?.CuttingSmallPiecePatta || "-" },
                               { label: "Small Piece Size", value: orderData?.CuttingSmallPieceSize || "-" },
                               { label: "Table", value: orderData?.CuttingTable || "-" },
                        ]} />
                    </Stack>
                </Grid>
                <Grid item xs={12} lg={5}>
                    <Stack spacing={0.5}>
                        <CustomFormGroup items={[
                               { label: "Filler Patta", value: orderData?.CuttingFillerPatta || "-" },
                               { label: "Filler Size", value: orderData?.CuttingFillerSize || "-" },
                               { label: "Table", value: orderData?.CuttingTotal || "-" }
                        ]} />
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                        <CheckboxGroup   items={[
                              { label: "ZigZag", name: "TagZigZag", value: orderData?.TagZigZag },
                              { label: "Interlock", name: "TagInterlock", value: orderData?.TagInterlock }
                        ]} />
                    </Stack>
                </Grid>
                {orderData?.CuttingRemarks &&
                <Grid item xs={12}>
                    <Stack spacing={0.5}>
                        <ReadOnlyInputRemarks label="Remarks" xs={12} lg={12} sm={12} value={orderData?.CuttingRemarks || "-"} />
                    </Stack>
                </Grid>}
            </Section>
            
            <Section title="Binding">
                <Grid item xs={12}>
                    <Stack spacing={0.5}>
                        <CustomFormGroup items={[
                            { label: "Back to Back", value: orderData?.BindingsBackToBack || "-" },
                            { label: "Total", name: "BindingsKaan", value: orderData?.BindingsTotal || "-" }
                        ]} />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack spacing={0.5}>
                        <CheckboxGroup items={[
                             { label: "Die Cut", name: "BindingsDieCut", value: orderData?.BindingsDieCut },
                             { label: "Foil", name: "BindingsFoil", value: orderData?.BindingsFoil },
                             { label: "Tag Printed", name: "BindingsTagPrinted", value: orderData?.BindingsTagPrinted },
                             { label: "MDF", name: "BindingsMDF", value: orderData?.BindingsMDF },
                             { label: "Cappa Board", name: "BindingsCappaBoard", value: orderData?.BindingsCappaBoard }
                        ]} />
                    </Stack>
                </Grid>
                {orderData?.BindingsRemarks &&
                <Grid item xs={12}>
                    <Stack spacing={0.5}>
                        <ReadOnlyInputRemarks label="Remarks" xs={12} lg={12} sm={12} value={orderData?.BindingsRemarks || "-"} />
                    </Stack>
                </Grid>}
            </Section>

            <Section title="Photo Making">
                <Grid item xs={12} md={7}>
                    <Stack spacing={0.5}>
                        <CustomFormGroup items={[
                            { label: "Printer", value: orderData?.PhotosPrinter || "-" },
                            { label: "Photo Paper GSM", value: orderData?.PhotosPaperGSM || "-" },
                            { label: "Photo Size", value: orderData?.PhotosSize || "-" }
                            ]} />
                    </Stack>
                </Grid>
                <Grid item xs={12} md={5}>
                    <Stack spacing={0.5}>
                        <CheckboxGroup items={[
                            { label: "1", value: orderData?.PhotosPage?.includes(1) ? true : false },
                            { label: "2", value: orderData?.PhotosPage?.includes(2) ? true : false },
                            { label: "3", value: orderData?.PhotosPage?.includes(3) ? true : false },
                            { label: "4", value: orderData?.PhotosPage?.includes(4) ? true : false }
                        ]} />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack spacing={0.5}>
                        <CheckboxGroup items={[
                           { label: "Lamination", value: orderData?.PhotosLamination, name: "PhotosLamination" },
                           { label: "UV", value: orderData?.PhotosUV, name: "PhotosUV" },
                           { label: "Matt", value: orderData?.PhotosMatt, name: "PhotosMatt" },
                           { label: "Glossy", value: orderData?.PhotosGlossy, name: "PhotosGlossy" }
                        ]} />
                    </Stack>
                </Grid>
                {orderData?.PhotosRemarks &&
                <Grid item xs={12}>
                    <Stack spacing={0.5}>
                        <ReadOnlyInputRemarks label="Remarks" xs={12} lg={12} sm={12} value={orderData?.PhotosRemarks || "-"} />
                    </Stack>
                </Grid>
}
            </Section>

            <Section title="Screen Printing">
                <Grid item xs={12}>
                    <Stack spacing={0.5}>
                        <CustomFormGroup items={[
                             { label: "Gumming", value: orderData?.ScreenGumming || "-" },
                             { label: "Total", value: orderData?.ScreenTotal || "-" }
                        ]} />
                    </Stack>
                </Grid> 
                {orderData?.ScreenRemarks &&
                <Grid item xs={12}>
                    <Stack spacing={0.5}>
                        <ReadOnlyInputRemarks label="Remarks" xs={12} lg={12} sm={12} value={orderData?.ScreenRemarks || "-"} />
                    </Stack>
                </Grid>}
            </Section>

            <Section title="Fittings">
                <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                        <CustomFormGroup items={[
                            { label: "Filler", value: orderData?.FittingFiller || "-" },
                            { label: "Total", value: orderData?.FittingTotal || "-" }
                        ]} />
                    </Stack>
                </Grid>
                {orderData?.FittingRemarks &&
                <Grid item xs={12}>
                    <Stack spacing={0.5}>
                        <ReadOnlyInputRemarks label="Remarks" xs={12} lg={12} sm={12} value={orderData?.FittingRemarks || "-"} />
                    </Stack>
                </Grid>
}
            </Section>
           
            <div className="bg-gray-300 border-2 border-gray-300 shadow overflow-hidden sm:rounded-lg">
                <div className="bg-gray-300 px-4 py-2 border-b sm:px-6">
                    <h3 className="text-lg  text-center font-bold leading-6 text-gray-900">Extra</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-6 items-center">
                            <dt className="text-sm font-medium text-gray-500">Parcel Type:
                                <span className="md:border-r border-red-500 pr-3"> <Checkbox checked={orderData?.Parcel === 1 ? true : false} name="Parcel" color="primary" />Parcel</span>
                                <span className="md:border-r border-red-500 pr-3"><Checkbox checked={orderData?.Loose === 1 ? true : false} name="Loose" color="primary" />Loose</span>
                            </dt>

                            <dt className="text-sm font-medium text-gray-500">Chalan Sequence:
                                <span className="md:border-r border-red-500 pr-3"> <Checkbox checked={orderData?.ChallanSequence1 === 1 ? true : false} name="ChallanSequence1" color="primary" />1</span>
                                <span className="md:border-r border-red-500 pr-3"><Checkbox checked={orderData?.ChallanSequence3 === 1 ? true : false} name="ChallanSequence3" color="primary" />3</span>
                            </dt>
                        </div>
                    </dl>
                </div>
            </div>

        </>
    );
};
export default ViewShadeCard;