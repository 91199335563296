import React, { useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Close, VpnKey } from '@mui/icons-material';
import { dataService } from "services/data-service";
import { showVerificationDialog } from "../VerifiyOTPDialog";
import ViewOrderFile from "./ViewOrderFile";
import ViewOrderHeader from "./ViewOrderHeader";
import ViewShadeCard from "./ViewOrderShadeCard";
import ViewECatalogue from "./ViewOrderECatalogue";
// import ProductBGColors from "components/Common/ProductBGColors";
import ConfirmDialog from "../ConfirmDialog";
import { Box, Button, DialogContent, Grid, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import MessageBar from "components/MessageBar/MessageBar";
import { ReadOnlyInput } from "./CommonOrderView";
import moment from "moment";
import { currentUser } from "services/user-service";

function ViewOrderDialog(props) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { onClose, open, OrderId, setIsReload, isReload } = props;
  const [orderData, setOrderData] = useState();
  const [showPrice, setShowPrice] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false)

  const [eCateLougeValue, seteCateLougeValue] = useState({
    Remarks: null,
    UnitPrice: null
  })

  const [messageDialoge, setMessageDialoge] = useState({
    show: false,
    type: '',
    message: ''
  });

  const handleClose = () => {
    onClose(false);
  };

  const handleShowPrice = () => {
    showVerificationDialog('Verify to view price', true, () => {
    }, async () => {
      setShowPrice(true)
    })
  };

  useEffect(() => {
    fetchData()
  }, [OrderId])

  const fetchData = async () => {
    let res = await dataService('get', `OrderGet/${OrderId}`, '');
    if (res?.data) {
      res.data.PhotosPage = res?.data?.PhotosPage ? res?.data?.PhotosPage?.split(',')?.map(Number) : [];
      res.data.FittingNumber = res?.data?.FittingNumber ? res?.data?.FittingNumber?.split(',')?.map(Number) : [];
    }
    setOrderData(res?.data);
  }


  const renderSection = () => {
    switch (orderData?.ProductName) {
      case 'Header':
        return <ViewOrderHeader orderData={orderData} />;
      case 'Shade':
        return <ViewShadeCard orderData={orderData} />;
      case 'E-Catalogue':
        return <ViewECatalogue orderData={orderData} />;
      case 'File':
        return <ViewOrderFile orderData={orderData} />;
      default:
        return <></>;
    }
  }

  const handleGenerateOrder = async () => {
    let eCateLouge = {
      // ProductId: orderData ? orderData?.ProductId : null,
      CustomerId: orderData ? orderData?.CustomerId : null,
      Client: orderData ? orderData?.Client : null,
      Date: orderData ? orderData?.Date : null,
      ProductFileId: orderData ? orderData?.ProductFileId : null,
      FileId: orderData ? orderData?.FileId : null,
      Quantity: orderData ? orderData?.Quantity : null,
      DeliveryDate: orderData ? orderData?.DeliveryDate : null,
      IsRepeat: orderData ? orderData?.IsRepeat : null,
      RepeatOrderId: orderData ? orderData?.RepeatOrderId : null,
      UnitPrice: orderData ? eCateLougeValue?.UnitPrice : null,
      Parcel: orderData ? orderData?.Parcel : null,
      Loose: orderData ? orderData?.Loose : null,
      ChallanSequence1: orderData ? orderData?.ChallanSequence1 : null,
      ChallanSequence3: orderData ? orderData?.ChallanSequence3 : null,
      Remarks: orderData ? eCateLougeValue?.Remarks : null,
      ProductId: orderData ? 4 : null,
      ECataloguePhotoShoot: true,
      ECatalogueFabricLayout: true,
      ECatalogueColorMatching: true,
      ECatalogueApproval: true,
      ECatalogueMail: true,
      ECatalogueDelivery: true,
      BillingEmail: orderData ? orderData?.BillingEmail : null,
      ShippingEmail: orderData ? orderData?.ShippingEmail : null
    }

    eCateLouge.PhotosPage = orderData?.PhotosPageArry?.sort()?.join();
    eCateLouge.FittingNumber = orderData?.FittingNumberArry?.sort()?.join();



    let res = await dataService('post', `OrderSave/${0}`, eCateLouge);

    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    if (res && (res.status === 200 || res.status === 304)) {
      setMessageDialoge({ show: true, type: 'success', message: 'E-catalogue order generate successfully!' });
      setConfirmOpen(false)
      setIsReload(!isReload)
      setTimeout(() => {
        handleClose()
      }, 500);
    } else {
      setMessageDialoge({ show: true, type: 'error', message: res.message });
    }

  }

  return (
    <Dialog onClose={handleClose} fullWidth={true} maxWidth="lg" aria-labelledby="simple-dialog-title" open={open}>
      {/* <div className={`bg-${ProductBGColors[orderData?.ProductName]}`}> */}
      <div>
        <div className="flex flex-wrap items-center justify-between">
          <DialogTitle id="form-dialog-title" >
            <div className="flex flex-col sm:flex-row ">
              <div>
                <span className="text-lg sm:text-xl">Order Information </span><br />
                <span className="text-base sm:text-lg text-gray-500">  [Order #: {orderData?.OrderNumber}]</span>
              </div>
              {orderData?.ProductId !== 4 &&
                <Box
                  justifyContent="center"
                  display="flex"
                  flexWrap="wrap"
                  className="ml-0 mt-4 sm:ml-4 sm:mt-0"
                >
                  <Button
                    variant="contained"
                    className="bg-[#5e72e4] border-[#5e72e4] hover:bg-[#233dd2]"
                    color="primary"
                    size="small"
                    onClick={() => setConfirmOpen(true)}
                  >
                    {"Generate E-catalogue"}
                  </Button>
                </Box>
              }
            </div>
          </DialogTitle>
          <Close className="w-6 h-auto mr-2 -mt-6 cursor-pointer" onClick={handleClose} />
        </div>
        <MessageBar show={messageDialoge.show} message={messageDialoge.message} type={messageDialoge.type} />
        <DialogContent>
          <div className="bg-gray-300 border-gray-300 border-2 shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-2 sm:px-6">
              <h3 className="text-lg  text-center font-bold leading-6 text-gray-900">General Information</h3>
            </div>
            <div className="bg-white border-t border-gray-200">
              <div className="px-6 py-4">
                <Grid container spacing={isSmallScreen ? 0.5 : 1}>
                  <ReadOnlyInput label="Product" value={orderData?.ProductName} sm={6} lg={6} xs={12} />
                  <ReadOnlyInput label="Customer" value={orderData?.CustomerName} sm={6} lg={6} xs={12} />
                  <ReadOnlyInput label="Client" value={orderData?.Client} xs={12} lg={5} />
                  <ReadOnlyInput label="Order Date" value={moment(orderData?.Date).format('Do MMMM YYYY')} xs={12} lg={4} />
                  <ReadOnlyInput label="Quantity" sm={4} lg={3} xs={12} value={orderData?.Quantity} />
                  <ReadOnlyInput label="File Name" value={orderData?.FileName} xs={12} sm={7} lg={6} />
                  <ReadOnlyInput label="File Size" value={orderData?.FileSize} xs={6} sm={3} lg={4} />
                  <ReadOnlyInput label="IsRepeat?" lg={2} sm={2} xs={6} value={orderData?.IsRepeat === 0 || null ? 'No' : 'Yes'} />
                </Grid>
              </div>
            </div>
          </div>
          {renderSection()}

          <div className="bg-gray-300 border-gray-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-2 sm:px-6">
                    <h3 className="text-lg  text-center font-bold leading-6 text-gray-900">Remarks</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-1 sm:gap-4 sm:px-6">
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{orderData?.Remarks}</dd>
                        </div>
                    </dl>
                </div>
            </div>

            {currentUser()?.Role === 'SUPER_ADMIN' &&
                <div className="bg-gray-300 border-gray-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-2 sm:px-6">
                        <h3 className="text-lg  text-center font-bold leading-6 text-gray-900">Unit Price</h3>
                    </div>
                    <div className="border-t border-gray-200">
                        <dl>
                            <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-1 sm:gap-4 sm:px-6">
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                                    Price: {showPrice ? orderData?.UnitPrice : '****'}

                                    {!showPrice &&
                                        <Tooltip title="Verify to view price" placement="top" arrow>
                                            <Box className="bg-blue-500 text-white ml-4" aria-controls="simple-menu-1" aria-haspopup="true" onClick={handleShowPrice} size="small" component={Button}  >
                                                <VpnKey onClick={handleShowPrice} className="w-5 h-auto" />
                                            </Box>
                                        </Tooltip>
                                    }

                                </dd>

                            </div>
                        </dl>
                    </div>
                </div>
            }
        </DialogContent>
      </div>
      <ConfirmDialog onClose={() => setConfirmOpen(false)} open={confirmOpen} message="Are you sure you want to generate e-catelouge order" handleConfirm={handleGenerateOrder} title={"Generate E-catelouge Order"} confirmBtnTitle="Yes" orderModel={eCateLougeValue} setOrderModel={seteCateLougeValue} />
    </Dialog>
  );
};
export default ViewOrderDialog;