import React from "react";
import {  Checkbox,  Grid, Stack, useMediaQuery, useTheme } from '@mui/material';
import { CustomFormGroup, Section } from "./CommonOrderView";

function ViewECatalogue(props) {
    const { orderData } = props;

    const theme = useTheme();

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const iMDScreen = useMediaQuery(theme.breakpoints.down('lg'));
    return (
        <>

            <Section title="Photoshoot">
                <Grid item xs={12}>
                    <Stack spacing={0.5}>
                        <CustomFormGroup items={[
                            { label: "Photoshoot", value: orderData?.ECataloguePhotoShoot || "-" },
                        ]} />
                    </Stack>
                </Grid>
            </Section>
            <Section title="Fabric Layout">
                <Grid item xs={12}>
                    <Stack spacing={0.5}>
                        <CustomFormGroup items={[
                            { label: "Fabric Layout", value: orderData?.ECatalogueFabricLayout || "-" },
                        ]} />
                    </Stack>
                </Grid>
            </Section>
            <Section title="Color Matching">
                <Grid item xs={12}>
                    <Stack spacing={0.5}>
                        <CustomFormGroup items={[
                            { label: "Color Matching", value: orderData?.ECatalogueColorMatching || "-" },
                        ]} />
                    </Stack>
                </Grid>
            </Section>
            <Section title="Approval">
                <Grid item xs={12}>
                    <Stack spacing={0.5}>
                        <CustomFormGroup items={[
                            { label: "Approval", value: orderData?.ECatalogueApproval || "-" },
                        ]} />
                    </Stack>
                </Grid>
            </Section>
            <Section title="Mail">
                <Grid item xs={12}>
                    <Stack spacing={0.5}>
                        <CustomFormGroup items={[
                            { label: "Mail", value: orderData?.ECatalogueMail || "-" },
                        ]} />
                    </Stack>
                </Grid>
            </Section>
            <Section title="Delivery">
                <Grid item xs={12}>
                    <Stack spacing={0.5}>
                        <CustomFormGroup items={[
                            { label: "Delivery", value: orderData?.ECatalogueDelivery || "-" },
                        ]} />
                    </Stack>
                </Grid>
            </Section>
            <div className="bg-gray-300 shadow overflow-hidden sm:rounded-lg">
                <div className="bg-gray-300 px-4 py-2 border-b sm:px-6">
                    <h3 className="text-lg  text-center font-bold leading-6 text-gray-900">Extra</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-6 items-center">
                            <dt className="text-sm font-medium text-gray-500">Parcel Type:
                                <span className="md:border-r border-red-500 pr-3"> <Checkbox checked={orderData?.Parcel === 1 ? true : false} name="Parcel" color="primary" />Parcel</span>
                                <span className="md:border-r border-red-500 pr-3"><Checkbox checked={orderData?.Loose === 1 ? true : false} name="Loose" color="primary" />Loose</span>
                            </dt>

                            <dt className="text-sm font-medium text-gray-500">Chalan Sequence:
                                <span className="md:border-r border-red-500 pr-3"> <Checkbox checked={orderData?.ChallanSequence1 === 1 ? true : false} name="ChallanSequence1" color="primary" />1</span>
                                <span className="md:border-r border-red-500 pr-3"><Checkbox checked={orderData?.ChallanSequence3 === 1 ? true : false} name="ChallanSequence3" color="primary" />3</span>
                            </dt>
                        </div>
                    </dl>
                </div>
            </div>


        </>
    );
};
export default ViewECatalogue;