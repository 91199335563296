import axios from 'axios';
import { getAccessToken, getRefreshToken, setTokens, removeTokens } from './token-service';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL + '/api',
  validateStatus: function (status) {
    return status >= 200 && status < 300;
  }
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401) {
      const originalRequest = error.config;

      if (!originalRequest._retry) {
        if (isRefreshing) {
          try {
            const token = await new Promise((resolve, reject) => {
              failedQueue.push({ resolve, reject });
            });
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return axiosInstance(originalRequest);
          } catch (err) {
            return Promise.reject(err);
          }
        }

        originalRequest._retry = true;
        isRefreshing = true;

        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/refreshUserToken`,
            { refreshToken: getRefreshToken() },
            {
              headers: {
                Authorization: `Bearer ${getAccessToken()}`,
              },
              validateStatus: function (status) {
                return true;
              }
            }
          );
          const { token, refreshToken } = response?.data?.data || {};
          if (token && refreshToken) {
            setTokens(token, refreshToken);
            originalRequest.headers.Authorization = `Bearer ${token}`;
            processQueue(null, token);
            return axiosInstance(originalRequest);
          } else {
            removeTokens();
            window.location.href = '/auth/login';
          }
        } catch (refreshError) {
          processQueue(refreshError, null);
          removeTokens();
          window.location.href = '/auth/login';
        } finally {
          isRefreshing = false;
        }
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance; 