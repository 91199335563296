import React from 'react';

// @mui/material components
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { FormGroup, useMediaQuery, useTheme, Autocomplete, TextField, Chip } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import Table from '@mui/material/Table';
import { StyledEngineProvider } from '@mui/material/styles';

import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TableSortLabel from '@mui/material/TableSortLabel';
// @mui/icons-material components
//import MoreVert from "@mui/icons-material/MoreVert";
import EditIcon from '@mui/icons-material/Edit';

// @mui/icons-material components
import { useHistory } from 'react-router-dom';

import componentStyles from 'assets/theme/views/admin/tables.js';
import { TablePagination } from '@mui/material';
import moment from 'moment';
import ThreeDotsMenu from 'components/ThreeDotMenu/ThreeDotMenu';
import { LoadingIcon } from 'components/Icons/Loading';
import { currencyFormat } from 'services/data-service';
import {  Visibility } from '@mui/icons-material';
import { PRODUCTION_STATUS_DATA } from 'utils/constant';

const useStyles = makeStyles(theme => ({
  ...componentStyles,
  stickyColumn: {
    position: 'sticky',
    left: 0,
    backgroundColor: '#fff',
    zIndex: 2,
    boxShadow: '5px 0px 5px -5px rgba(0,0,0,0.1)',
  },
  stickyColumnHeader: {
    position: 'sticky',
    left: 0,
    backgroundColor: '#f0f3f8',
    zIndex: 3,
    boxShadow: '5px 0px 5px -5px rgba(0,0,0,0.1)',
  },
  tableContainer: {
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      height: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#c1c1c1',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#a1a1a1',
    }
  },
  fixedColumn: {
    minWidth: '120px',
    paddingLeft: '16px !important',
  },
  productionTable: {
    '& .MuiTableCell-root': {
      padding: '3px 4px',
      borderRight: '1px solid rgba(224, 224, 224, 0.3)',
      whiteSpace: 'nowrap',
    },
    '& .MuiTableHead-root .MuiTableCell-root': {
      fontWeight: 'bold',
      backgroundColor: '#f0f3f8',
      fontSize: '0.68rem',
      color: '#5e6e82',
      textTransform: 'uppercase',
      letterSpacing: '0.5px',
      padding: '0px 2px 0px 2px !important',
      textAlign: 'center',
    },
    '& .MuiTableBody-root .MuiTableRow-root:hover': {
      backgroundColor: 'rgba(245, 247, 250, 0.5)',
    },
    '& .MuiTableSortLabel-root': {
      padding: '0px 2px !important',
    },
    '& .MuiTableCell-root:first-child': {
      borderLeft: 'none',
    },
    '& .MuiTableCell-root:last-child': {
      borderRight: 'none',
    },
  },
  compactPadding: {
    padding: '0px 2px !important',
  },
  centerText: {
    textAlign: 'center',
    justifyContent: 'center',
  },
  orderNumberCell: {
    minWidth: '100px',
    paddingLeft: '16px !important',
    fontWeight: 'bold',
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '2px',
  }
}));

const ProductionFlowTable = (props) => {
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  let timeout = 0;
  const {
    view,
    title,
    rows,
    customHeaderTitle,
    page,
    total,
    pagedata,
    searchText,
    setSearchText,
    order,
    orderBy,
    orderData,
    orderByData,
    columns,
    editAction,
    actions,
    setOffset,
    offset,
    loading,
    component,
    pagination = true,
    viewURL, loadingRows, handleRowClick, onSigleClick, uniqueKey, setOrderFilter, 
    isSearch = true,
    isProductionFlow = false,
    className,
    statusFilter,
    setStatusFilter
  } = props;
  const headCells = columns;
  const classes = useStyles();
  const [selectedRowIndex, setSelectedRowIndex] = React.useState(null);
  const [seachTextInputVal, setSeachTextInputVal] = React.useState(searchText);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleEditClick = (row) => {
    editAction(row);
  };

  const handleChangePage = (event, newPage) => {
    pagedata(newPage + 1);
  };

  const handleViewClick = (row) => {
    let action = actions?.find(rs => { return rs?.label === 'View' })
    action.action(row)
  };


  const handleChangePageRows = (event) => {
    setOffset(parseInt(event.target.value, 10));
    pagedata(1);
  };

  /*------------- Table Headers Start ------------*/
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    orderData(isAsc ? 'desc' : 'asc');
    orderByData(property);
  };

  function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
         
          {headCells.map((headCell, index) => (
            <TableCell
              key={headCell.id}
              align={isProductionFlow ? 'center' : (headCell.numeric ? 'right' : 'left')}
              padding={'none'}
              sortDirection={orderBy === headCell.id ? order : false}
              classes={{
                root: `${classes.tableCellRoot} ${classes.tableCellRootHead} ${isProductionFlow && index === 0 ? classes.stickyColumnHeader : ''} ${isProductionFlow ? classes.compactPadding : ''}`,
              }}
              sx={{
                padding: isProductionFlow ? '0px 2px !important' : '2px 5px !important',  
                '&.MuiTableCell-root': {
                  padding: isProductionFlow ? '0px 2px !important' : '2px 5px !important',
                  ...(isProductionFlow && index === 0 && { minWidth: '100px', paddingLeft: '16px !important' }),
                },
              }}
            >
              {headCell.HeaderComponent ? (
                <headCell.HeaderComponent />
              ) : (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                  className={isProductionFlow ? classes.centerText : ''}
                >
                  {isProductionFlow ?
                    <div className={`!text-[10px] ${index === 0 ? 'pl-4 text-left' : 'text-center'}`}>
                      {headCell.label}
                    </div>
                    :
                    headCell.label
                  }
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {/* {order === 'desc' ? 'sorted descending' : 'sorted ascending'} */}
                    </span>
                  ) : null}
                </TableSortLabel>
              )}
            </TableCell>
          ))}
          {actions?.length > 0 && (
            <TableCell
              key={100}
              align={'center'}
              padding={'none'}
              classes={{
                root: classes.tableCellRoot + ' ' + classes.tableCellRootHead,
              }}
            >
              Action
            </TableCell>
          )}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  /*------------- Table Headers End ------------*/

  /*------------- Table Content Start ------------*/

  function EnhancedTableRows(props) {
    const { classes, rows } = props;

    const displayRowData = (row, headCellData, index) => {
      let returnStr = null;

      if (headCellData?.Component) {
        const Component = headCellData?.Component;
        return <Component row={row} headCellData={headCellData} />;
      }
      if (headCellData?.dataType === 'string') {
        return row[headCellData.id];
      } else if (headCellData?.dataType === 'date') {
        return row[headCellData.id]
          ? moment(row[headCellData.id]).format('D-MM-YYYY')
          : '';
      } else if (headCellData.dataType === 'date' && headCellData.dateFromNow) {
        moment(row[headCellData.id]).fromNow(true);
      }
      else if (headCellData.dataType === 'currency') {
        return currencyFormat(row[headCellData.id]);
      }
      return returnStr;
    };

    return (
      <TableBody className="relative">
        {rows?.map((rowData, index) => {
          return (
            // <TableRow key={index} onClick={(e) => {handleViewClick(rowData)}} className="cursor-pointer">
            <TableRow
              onClick={() => onSigleClick && onSigleClick(rowData)}
              onDoubleClick={() => handleRowClick && handleRowClick(rowData)}
              key={index}
              className={`${selectedRowIndex === index ? 'bg-blue-100' : ''
                }`}
            >
              {loadingRows && loadingRows[rowData[uniqueKey]] ? (
                <TableCell colSpan={columns.length + 2} align="center">
                  <LoadingIcon />
                </TableCell>
              ) : (
                <>

                  {viewURL === "pre-order" ?
                    <TableCell
                      classes={{ root: classes.tableCellRoot }}
                      align="left"
                    >
                      <Box
                        aria-controls="simple-menu-1"
                        aria-haspopup="true"
                        onClick={(e) => { handleViewClick(rowData) }}
                        size="small"
                        component={Button}
                        className="p-0"
                        width="2rem!important"
                        height="2rem!important"
                        minWidth="2rem!important"
                        minHeight="2rem!important"
                      >
                        <Visibility fontSize="small" style={{ color: "#424242" }} />
                      </Box>
                    </TableCell> : null}
                  {headCells.map((headCell, hdIndex) => (
                    <TableCell
                      key={hdIndex}
                      classes={{
                        root: `${classes.tableCellRoot} ${classes.tableCellRootBodyHead} ${isProductionFlow && hdIndex === 0 ? classes.stickyColumn : ''} ${isProductionFlow ? classes.compactPadding : ''}`,
                      }}
                      component="th"
                      variant="head"
                      scope="row"
                      className={`${headCell.className || ''} ${isProductionFlow && hdIndex !== 0 ? classes.centerText : ''}`}
                      align={isProductionFlow && hdIndex !== 0 ? 'center' : 'left'}
                    >
                      <div className={isProductionFlow && hdIndex !== 0 ? 'flex justify-center' : ''}>{displayRowData(rowData, headCell, hdIndex)}</div>
                    </TableCell>
                  ))}

                  {actions?.length > 0 && (
                    <TableCell
                      classes={{ root: classes.tableCellRoot }}
                      align="center"
                    >
                      {editAction && (
                        <Box
                          aria-controls="simple-menu-1"
                          aria-haspopup="true"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEditClick(rowData);
                          }}
                          size="small"
                          component={Button}
                          className='p-0'
                          width="2rem!important"
                          height="2rem!important"
                          minWidth="2rem!important"
                          minHeight="2rem!important"
                        >
                          <EditIcon fontSize="large" className='w-5 h-5' style={{ color: theme.palette.gray[500] }} />
                        </Box>
                      )}

                      {actions?.length > 0 && (
                        <ThreeDotsMenu
                          data={rowData}
                          actions={actions}
                          index={index}
                          setSelectedRowIndex={setSelectedRowIndex}
                        />
                      )}
                    </TableCell>
                  )}
                </>
              )}
            </TableRow>
          );
        })}
        {rows?.length === 0 && !loading && (
          <TableRow key={0}>
            <TableCell
              colSpan={columns?.length + 2}
              classes={{ root: classes.tableCellRoot }}
              align="center"
              className="font-bold"
            >
              Record Not Found
            </TableCell>
          </TableRow>
        )}
        {loading && (
          <TableRow key={0}>
            <TableCell
              colSpan={columns?.length + 2}
              classes={{ root: classes.tableCellRoot }}
              align="center"
              className="font-bold"
            >
              <LoadingIcon overlay={rows?.length > 0} />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  }

  EnhancedTableRows.propTypes = {
    // rows, page, total, pagedata, setSearchText
    classes: PropTypes.object.isRequired,
    rows: PropTypes.array,
  };

  /*------------- Table Content End ------------*/

  const commonFormControlLabelSx = {
    '& .MuiFormControlLabel-label': {
      fontSize: {
        xs: '12px !important',
        sm: "14px !important"
      },
    },
  };

  const handleChangeOrderFilter = (event) => {
    const selectedValue = event.target.value;

    setOrderFilter(prevState => {
      if (prevState[selectedValue] === 1) {
        return {
          ...prevState,
          [selectedValue]: null
        };
      } else {
        return {
          Pending : null,
          Approved : null,
          Canceled : null,
          [selectedValue]: 1
        };
      }
    });
  };

  const handleChangeStatusFilter = (event) => {
    const selectedValue = event.target.value;
    
    if (selectedValue === "ALL") {
      setStatusFilter("ALL");
    } else {
      setStatusFilter(selectedValue);
    }
    
    pagedata(1);
  };

  const getStatusOption = (value) => {
    return PRODUCTION_STATUS_DATA.find(option => option.value === value) || PRODUCTION_STATUS_DATA[0];
  };

  const tableContainerClassName = isProductionFlow ? classes.tableContainer : '';

  return (
    <StyledEngineProvider injectFirst>
      <Card classes={{ root: classes.cardRoot }} className={isProductionFlow ? classes.productionTable : ''}>
        <CardHeader
          subheader={
            <>
              <Grid
                spacing={2}
                container
              >
                {customHeaderTitle ?
                  customHeaderTitle()
                  :
                  <Grid item xs={12} sm={9} md={component ? 6 : 9} lg={component ? 3 : 9} >
                    <Box
                      component={Typography}
                      variant="h3"
                      marginBottom="0!important"
                    >
                      {title}
                    </Box>
                  </Grid>
                }
               
                  <Grid item xs={12} sm={3} md={3} lg={3}>
                    <Box justifyContent="flex-end" display="flex" flexWrap="wrap">
                    <FormGroup sx={{ width: { xs: '100%', sm: 'auto' } }}>
                    <Autocomplete
                      id="status-filter"
                      options={PRODUCTION_STATUS_DATA.filter(option => option.value !== "ALL")}
                      getOptionLabel={(option) => typeof option === 'string' ? getStatusOption(option).label : option.label}
                      isOptionEqualToValue={(option, value) => {
                        if (typeof value === 'string') {
                          return option.value === value;
                        }
                        return option.value === value.value;
                      }}
                      value={statusFilter && statusFilter !== "ALL" ? getStatusOption(statusFilter) : null}
                      onChange={(event, newValue) => {
                        handleChangeStatusFilter({ 
                          target: { 
                            value: newValue ? newValue.value : "ALL"  
                          } 
                        });
                      }}
                      renderOption={(props, option) => (
                        <Box component="li" {...props} sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box 
                            component="span"
                            sx={{ 
                              display: 'inline-block',
                              width: '12px',
                              height: '12px',
                              borderRadius: '50%',
                              backgroundColor: option.color,
                              marginRight: '10px'
                            }}
                          />
                          {option.label}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField 
                          {...params} 
                          placeholder={statusFilter === "ALL" || !statusFilter ? "Select Status" : ""}
                          variant="outlined"
                          size="small"
                          sx={{
                            backgroundColor: 'white',
                            minWidth: { xs: '100%', sm: '250px' },
                            '& .MuiOutlinedInput-root': {
                              borderRadius: '4px',
                            }
                          }}
                        />
                      )}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          height: '35px',
                        },
                        '& .MuiAutocomplete-input': {
                          borderRight: '0px',
                          fontSize: isSmallScreen ? "12px" : "14px"
                        },
                        border: "none"
                      }}
                      clearOnBlur={false}
                      clearOnEscape={false}
                      noOptionsText="No statuses available"
                      renderTags={(value, getTagProps) => (
                        <Chip
                          label={value.label}
                          {...getTagProps({ index: 0 })}
                          sx={{ 
                            backgroundColor: value.color,
                            color: 'white',
                            fontWeight: 'bold',
                            '& .MuiChip-deleteIcon': {
                              color: 'white',
                              '&:hover': {
                                color: 'rgba(255, 255, 255, 0.7)',
                              }
                            }
                          }}
                        />
                      )}
                    />
                  </FormGroup>
                    </Box>
                  </Grid>
              </Grid>

            </>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
        <TableContainer className={tableContainerClassName}>
          <Box component={Table} alignItems="center" marginBottom="0!important" className={className}>
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
            />
            <EnhancedTableRows
              classes={classes}
              rows={rows}
              page={page}
              total={total}
            />
          </Box>
        </TableContainer>

        <Box
          classes={{ root: classes.cardActionsRoot }}
          component={CardActions}
          justifyContent="flex-end"
        >
          {pagination &&
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={total}
              rowsPerPage={offset}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangePageRows}
            />
          }
        </Box>
      </Card>
    </StyledEngineProvider>
  );
};

export default ProductionFlowTable;
