import React, { useEffect, useState } from "react";

// @mui/material components
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
// core components
import Header from "components/Headers/Header.js";

import componentStyles from "assets/theme/views/admin/tables.js";
import CommonTable from "components/Tables/CommonTable";
import { dataService } from "services/data-service";
import { useHistory } from 'react-router-dom';
import { currentUser } from "services/user-service";
import { formatMessage } from "utils/helper";
import OrdersCommentsDialog from "components/dialoges/OrdersCommentsDialog";

const useStyles = makeStyles(componentStyles);

const Notifications = () => {
  const classes = useStyles();
  const [userList, setUserList] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const history = useHistory();

  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [orderBy, setOrderByData] = useState('CreatedAt');
  const [order, setOrderData] = useState('desc');
  const [offset, setOffset] = useState(10);
  const [isReload, setIsReload] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [viewDetailDialog, setViewDetailDialog] = useState({
    isVisible: false,
    data: null,
    extraData: null
  });
  const [loadingRows, setLoadingRows] = useState({});

 
  const columns = [
    {
      id: 'OrderNumber', numeric: false, disablePadding: true, label: 'OrderNumber', dataType: 'component',
      Component: ({ row }) => {
        return (
          <Box>
            #{row.OrderNumber}
          </Box>
        )
      }
    },
    { id: 'ReferenceType', numeric: false, disablePadding: false, label: 'Reference Type', dataType: 'string' },
    { id: 'CommentByName', numeric: false, disablePadding: false, label: 'Message By', dataType: 'string' },
    {
      id: 'Message', numeric: false, disablePadding: false, label: 'Message', dataType: 'component',
      Component: ({ row }) => {
        return (
          <Box
            sx={{
              maxWidth: "400px",
              minWidth: "200px",
              overflowWrap: "break-word",
              wordBreak: "break-word",
              whiteSpace: "normal",
            }}
          >
            {formatMessage(row.Message, currentUser()?.UserId)}
          </Box>
        )
      }
    },
    { id: 'Username', numeric: false, disablePadding: false, label: 'Username', dataType: 'string' },
    { id: 'CreatedAt', numeric: false, disablePadding: false, label: 'Created At', dataType: 'date' },
  ];

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      let res = await dataService('get', 'NotificatiosList', `searchText=${searchText}&sortBy=${orderBy}&sortOrder=${order}&page=${page}&offset=${offset}
                                  &userId=${currentUser()?.UserId}&showUnreadOnly=${0}`);
      setUserList(res?.data);
      setTotalRecord(res?.totalRecord);
      setIsLoading(false);
    };
    fetchData()
  }, [page, searchText, order, orderBy, offset, isReload])

  let actionList = [
  ]

  

  const onSigleClick = async (row) => {
    setLoadingRows((prevRows) => ({
      ...prevRows,
      [row.NotificationsId]: true
    }));
    try {
      let res = await dataService('get', `OrdersCommentsList/${row?.OrderId}`, '');
      if (res.status === 200 && res?.data) {
        setViewDetailDialog({
          isVisible: true,
          data: res?.data,
          extraData: { ProductName: row?.ProductName || "", Quantity: row?.Quantity || "", CustomerName: row?.CustomerName || "", FileName: row?.FileName || "", OrderNumber: row?.OrderNumber || "", OrderId: row?.OrderId }
        });
      }
      setLoadingRows((prevRows) => ({
        ...prevRows,
        [row.NotificationsId]: false
      }));
    } catch (error) {
      setLoadingRows((prevRows) => ({
        ...prevRows,
        [row.NotificationsId]: false
      }));
    }
  }

  return (
    <>

      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <CommonTable
          title="Notifications List"
          columns={columns}
          rows={userList}
          page={page - 1}
          total={totalRecord}
          pagedata={setPage}
          setSearchText={setSearchText}
          order={order}
          orderBy={orderBy}
          orderData={setOrderData}
          orderByData={setOrderByData}
          //editAction={handleEdit}
          actions={actionList}
          setOffset={setOffset}
          offset={offset}
          loading={isLoading}
          loadingRows={loadingRows}
          onSigleClick={onSigleClick}
          uniqueKey="NotificationsId"

        />
      </Container>
      {viewDetailDialog?.isVisible &&
        <OrdersCommentsDialog
          isOrderFlow={false}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setIsReload={setIsReload}
          isReload={isReload}
          open={viewDetailDialog}
          setOpen={setViewDetailDialog}
          onClose={() => {
            setViewDetailDialog({ data: null, isVisible: false, extraData: null })
          }} />}
    </>
  );
};

export default Notifications;