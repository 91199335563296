import React, { useState } from "react";

// @mui/material components
import { makeStyles } from "@mui/styles";
import PropTypes from 'prop-types';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardHeader from "@mui/material/CardHeader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TableSortLabel from '@mui/material/TableSortLabel';
// @mui/icons-material components
//import MoreVert from "@mui/icons-material/MoreVert";

import InputBase from "@mui/material/InputBase";
// @mui/icons-material components
import SearchIcon from "@mui/icons-material/Search";
import { useHistory } from 'react-router-dom';

import componentStyles from "assets/theme/views/admin/tables.js";
import { ButtonGroup, Chip, CircularProgress, Fab, IconButton, Popover, TablePagination, Tooltip, useTheme, useMediaQuery } from "@mui/material";
import moment from "moment";
import ThreeDotsMenu from "components/ThreeDotMenu/ThreeDotMenu";
import { LoadingIcon } from "components/Icons/Loading";
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { GoodsEntryIcon } from "components/Icons/CustomIcons";
import { BarChart } from "@mui/icons-material";
import CardStatsDialog from "components/dialoges/CardStatsDialog";
import { dataService } from "services/data-service";
import { Link } from "react-router-dom";
import FilterListIcon from '@mui/icons-material/FilterList';
import { GoodsEntryFilter } from "../../components/Filters/GoodsEntryFilter";
//import moment from "moment";

import classNames from 'classnames';

const useStyles = makeStyles(componentStyles);

const GoodsEntryTable = (props) => {
  const history = useHistory();
  let timeout = 0;
  const { view, title, rows, page, total, pagedata, setSearchText, setSearchReceiveDate, searchReceiveDate, order, orderBy, orderData, orderByData, columns, actions, setOffset, offset, loading, searchStatus, setSearchStatus, handleViewOrder, setFilterModel, filterModel, isCustomerData, setIsCustomerData, searchCustomerStatus, setSearchCustomerStatus, loadingRows } = props;
  const headCells = columns;
  const classes = useStyles();
  const [goodsEntryStats, setGoodsEntryStats] = useState([]);
  const [openGoodsEntryStats, setOpenGoodsEntryStats] = useState(false);
  const [showGoodsEntryStatsLoading, setShowGoodsEntryStatsLoading] = useState(false);


  const [goodsEntryModel, setGoodsEntryModel] = useState({
    startDate: null,
    endDate: null
  });

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    console.log('event.currentTarget: ', event.currentTarget)
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //Handle Page changes
  const handleChangePage = (event, newPage) => {
    pagedata(newPage + 1);
  };

  //Handle Page changes
  const handleChangePageRows = (event) => {
    setOffset(parseInt(event.target.value, 10))
    pagedata(1);
  };

  //Search data
  const handleSearchInput = (e) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      setSearchText(e.target.value)
      pagedata(1);
    }, 400);
  }

  const handleSelectChange = (id, value) => {
    switch (id) {
      case 'ReceiveDateStart':
        if (value === null || value.toString() !== "Invalid Date") {
          setGoodsEntryModel({ ...goodsEntryModel, startDate: value })
          setSearchReceiveDate({ ...searchReceiveDate, startDate: value });
        }
        //            setGoodsEntryValidateModel({ ...goodsEntryValidateModel, 'ReceiveDate': { message: checkEmptyValidation(value, 'Receive Date'), touched: true } });
        break;
      case 'ReceiveDateEnd':
        if (value === null || value.toString() !== "Invalid Date") {
          //setCustomerModel({ ...customerModel, BillingCountryId: countryList?.find(c => { return c.CountryId === value.CountryId })});
          setGoodsEntryModel({ ...goodsEntryModel, endDate: value })
          setSearchReceiveDate({ ...searchReceiveDate, endDate: value });
        }
        break;
      default:
        setGoodsEntryModel({ ...goodsEntryModel })
        break;
    }
  }

  const searchStatusHandle = (selSearchStatus) => {
    pagedata(1);
    setSearchStatus(selSearchStatus)
  }

  /*------------- Table Headers Start ------------*/
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    orderData(isAsc ? 'desc' : 'asc');
    orderByData(property);
  };

  function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={'none'}
              sortDirection={orderBy === headCell.id ? order : false}
              classes={{
                root:
                  classes.tableCellRoot + " " + classes.tableCellRootHead,
              }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {/* {order === 'desc' ? 'sorted descending' : 'sorted ascending'} */}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell
            key={100}
            align={'center'}
            padding={'none'}
            classes={{
              root:
                classes.tableCellRoot + " " + classes.tableCellRootHead,
            }}
          >
            Action
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  /*------------- Table Headers End ------------*/

  /*------------- Table Content Start ------------*/

  function EnhancedTableRows(props) {
    const { classes, rows } = props;


    return (
      <TableBody className="relative">
        {rows?.map((rowData, iIndex) => {
          return (


            <TableRow key={iIndex} className="cursor-pointer">
              {loadingRows[rowData.GoodsEntryId] ? (
                <TableCell colSpan={columns.length + 2} align="center">
                  <LoadingIcon />
                </TableCell>
              ) : (
                <>
                  {headCells.map((headCell, hdIndex) => (
                    <TableCell
                      key={hdIndex}
                      classes={{
                        root:
                          classes.tableCellRoot +
                          " " +
                          classes.tableCellRootBodyHead,
                      }}
                      component="th"
                      variant="head"
                      scope="row"
                    >
                      {headCell.dataType === "component" && headCell.Component(rowData)}
                      {headCell.dataType === 'string' && rowData[headCell.id]}

                      {headCell.dataType === 'date' && rowData[headCell.id] ? moment(rowData[headCell.id]).format('D-MM-YYYY') : ''}

                      {headCell.dataType === 'jsonArray' && rowData[headCell.id] !== null && (

                        JSON.parse(rowData[headCell.id]).map((fileData) => (

                          Number(fileData?.OrderId) === 0 ? (
                            <Tooltip title={
                              <span>
                                Generate Order<br /><hr />
                                <span>
                                  Quantity: {fileData?.Quantity}<br />
                                  Client: {fileData?.Client}
                                </span>
                              </span>
                            } placement="top" arrow>
                              <Link to={`/admin/order-by-goodsentry/${rowData['GoodsEntryId']}/${fileData?.GoodsEntryFileId}`} >
                                <span className="pr-2 text-green-500">
                                  {fileData?.Name},
                                </span>
                              </Link>

                            </Tooltip>
                          ) : (
                            <Tooltip title={fileData?.OrderNumber} placement="top" arrow>
                              <span className="pr-2 text-red-500" onClick={() => handleViewOrder(fileData?.OrderId)}>
                                {fileData?.Name},
                              </span>
                            </Tooltip>
                          )
                        ))
                      )}
                    </TableCell>
                  ))
                  }

                  <TableCell classes={{ root: classes.tableCellRoot }} align="center">
                    {actions?.length > 0 &&
                      <ThreeDotsMenu data={rowData} actions={actions} index={iIndex} />
                    }
                  </TableCell>
                </>
              )}
            </TableRow>
          )
        })
        }
        {rows?.length === 0 && !loading &&
          <TableRow key={0}>
            <TableCell colSpan={columns?.length + 2} classes={{ root: classes.tableCellRoot }} align="center" className='font-bold'>
              Record Not Found
            </TableCell>
          </TableRow>
        }
        {loading &&
          <TableRow key={0}>
            <TableCell colSpan={columns?.length + 2} classes={{ root: classes.tableCellRoot }} align="center" className='font-bold'>
              <LoadingIcon overlay={rows?.length > 0} />
            </TableCell>
          </TableRow>
        }
      </TableBody>
    )
  }


  EnhancedTableRows.propTypes = {
    // rows, page, total, pagedata, setSearchText
    classes: PropTypes.object.isRequired,
    rows: PropTypes.array
  };

  /*------------- Table Content End ------------*/


  const handleStatsAction = async () => {
    setShowGoodsEntryStatsLoading(true);
    let res = await dataService('get', 'GoodsEntryStats', `type=ByProducts&status=${searchStatus || 'InProgress'}&startDate=null&endDate=null`);

    if (res?.status === 200 || res?.status === 304) {
      setOpenGoodsEntryStats(true);
      setGoodsEntryStats(res?.data);
    }
    setShowGoodsEntryStatsLoading(false);

  }

  const handleProductFilter = (val) => {

    setAnchorEl(null)

  }

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>

      {/* Page content */}
      <Card classes={{ root: classes.cardRoot }}>
        {isCustomerData ?
          <Box
            justifyContent="flex-end"
            display="flex"
            flexWrap="wrap"
            style={{
              marginTop: 10,
              marginRight: 10
            }}
          >
            <Button
              className="bg-[#5e72e4] hover:bg-[#233dd2]"
              variant="contained"
              color="primary"
              size="small" onClick={() => {
                setIsCustomerData(false);
              }}>
              Good entries
            </Button>
          </Box>
          :
          <Box
            justifyContent="flex-end"
            display="flex"
            flexWrap="wrap"
            style={{
              marginTop: 10,
              marginRight: 10
            }}
          >
            <Button variant="contained" className="bg-[#5e72e4] hover:bg-[#233dd2]"
              color="primary"
              size="small" onClick={() => {
                setIsCustomerData(true);
              }} >
              Customers good entries
            </Button>
          </Box>}
        <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={12} sm={8} md={12}>
                <Grid container direction='row' alignItems='center'>
                  <Grid item xs={12} sm={4} md={2}>
                    <Box component={Typography} variant="h3" className="md:my-1">
                      <span className="mb-2 sm:my-1 block">{title}</span>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={5} className="md:pl-0">
                    <Box display="flex" alignItems="center" className="w-full my-1 pr-3">
                      <Box
                        display="flex"
                        alignItems="center"
                        // className="border border-gray-300 w-full"
                        className={classNames('border', 'border-gray-300', 'w-full', classes.searchBox)}
                      >
                        <SearchIcon className={`${classes.searchIcon} text-black`} />
                        <InputBase
                          name="CommonSearch"
                          placeholder="Search"
                          sx={{
                            height: '35px'
                          }}
                          // className={classNames('text-black', classes.searchInput)}
                          classes={{
                            input: `${classes.searchInput} text-black`,
                          }}
                          onChange={(e) => { handleSearchInput(e) }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  {!isCustomerData &&
                    <Grid item xs={12} sm={8} md={4} className="md:pl-0">
                      <Grid container direction='row' alignItems='center'>

                        <Box display="flex" alignItems="center" className="w-6/12 my-1 pr-3" >
                          <div className="bg-white custom-datepicker">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                className="w-full"
                                name="ReceiveDate"
                                format="dd/MM/yyyy"
                                defaultValue={null}
                                sx={{
                                  '& .MuiOutlinedInput-input': {
                                    height: 35,
                                  },
                                }}
                                size="small"
                                value={goodsEntryModel?.startDate ? new Date(goodsEntryModel?.startDate) : null}
                                onChange={e => handleSelectChange('ReceiveDateStart', e)}
                              />
                            </LocalizationProvider>
                          </div>
                        </Box>

                        <Box display="flex" alignItems="center" className="w-6/12 my-1" >
                          <div className="bg-white custom-datepicker">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                className="w-full"
                                name="ReceiveDate"
                                format="dd/MM/yyyy"
                                sx={{
                                  '& .MuiOutlinedInput-input': {
                                    height: 35,
                                  },
                                }}
                                size="small"
                                value={goodsEntryModel?.endDate ? new Date(goodsEntryModel?.endDate) : null}
                                onChange={e => handleSelectChange('ReceiveDateEnd', e)}
                              />
                            </LocalizationProvider>
                          </div>
                        </Box>

                      </Grid>
                    </Grid>}
                </Grid>
              </Grid>
              <Box sx={{ justifyContent: "flex-end", display: "flex", width: "100%", mt: 1 }}>
                <Grid item xs={12} lg={8} md={12} >
                  <Grid container direction='row' alignItems='center' justifyContent={"flex-end"}>
                    {!isCustomerData &&
                      <Grid item xs={1} sm={1} md={1}>
                        <IconButton
                          style={{ marginLeft: "auto" }}
                          color="primary"

                          aria-label="filterButton"
                          onClick={(e) => { e.stopPropagation(); handleClick(e) }}
                        >
                          <FilterListIcon className="w-5 h-auto" />
                        </IconButton>
                        <Popover
                          //id={id}
                          open={Boolean(anchorEl)}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                        >
                          <GoodsEntryFilter handleClose={handleClose} handleProductFilter={handleProductFilter} setFilterModel={setFilterModel} filterModel={filterModel} />
                        </Popover>

                      </Grid>}

                    <Grid item xs={11} sm={8}>
                      {isCustomerData ?
                        <ButtonGroup variant="text" className="bg-white" aria-label="outlined button group">
                          <Button className={`${searchCustomerStatus === 'Pending' ? "text-white border-[#172b4d] bg-[#172b4d]" : "text-[#424242] border-y-0 border-l-0 border-r-[#0000001A]"}`} variant={`${searchCustomerStatus === 'Pending' ? 'contained' : 'text'}`} onClick={() => setSearchCustomerStatus('Pending')}>Pending</Button>
                          <Button className={`${searchCustomerStatus === 'Approved' ? "text-white border-[#172b4d] bg-[#172b4d]" : "text-[#424242] border-y-0 border-l-0 border-r-[#0000001A]"}`} variant={`${searchCustomerStatus === 'Approved' ? 'contained' : 'text'}`} onClick={() => setSearchCustomerStatus('Approved')}>Approved</Button>
                        </ButtonGroup>
                        :
                        <ButtonGroup variant="text" className="bg-white" aria-label="outlined button group">
                          <Button className={`${searchStatus === 'InProgress' ? "text-white border-[#172b4d] bg-[#172b4d]" : "text-[#424242] border-y-0 border-l-0 border-r-[#0000001A]"} text-xs sm:px-4 sm:py-3 px-2 py-2`} variant={`${searchStatus === 'InProgress' ? 'contained' : 'text'}`} onClick={() => searchStatusHandle('InProgress')}>In Progress</Button>
                          <Button className={`${searchStatus === 'Pending' ? "text-white border-[#172b4d] bg-[#172b4d]" : "text-[#424242] border-y-0 border-l-0 border-r-[#0000001A]"} text-xs sm:px-4 sm:py-3 px-2 py-2`} variant={`${searchStatus === 'Pending' ? 'contained' : 'text'}`} onClick={() => searchStatusHandle('Pending')}>Pending</Button>
                          <Button className={`${searchStatus === 'Completed' ? "text-white border-[#172b4d] bg-[#172b4d]" : "text-[#424242] border-y-0 border-l-0 border-r-[#0000001A]"} text-xs sm:px-4 sm:py-3 px-2 py-2`} variant={`${searchStatus === 'Completed' ? 'contained' : 'text'}`} onClick={() => searchStatusHandle('Completed')}>Completed</Button>
                        </ButtonGroup>
                      }
                    </Grid>
                    {!isCustomerData &&
                      <Grid item xs={12} sm={3} className="md:pl-0 mt-3">
                        <Box
                          justifyContent="flex-end"
                          display="flex"
                          flexWrap="wrap"
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            className="bg-[#5e72e4] border-[#5e72e4] hover:bg-[#233dd2] capitalize"
                            onClick={() => { history.push(view) }}
                          >
                            Add {view}
                          </Button>
                        </Box>
                      </Grid>
                    }
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
        <TableContainer>
          <Box
            component={Table}
            alignItems="center"
            marginBottom="0!important"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
            />

            <EnhancedTableRows
              classes={classes}
              rows={rows}
              page={page}
              total={total}
            />
          </Box>
        </TableContainer>
        <Box
          classes={{ root: classes.cardActionsRoot }}
          component={CardActions}
          justifyContent={isMobile ? 'center' : 'space-between'}
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: 'center',
            justifyContent: { xs: 'center', sm: 'space-between' },
            gap: { xs: 2, sm: 0 }
          }}
        >

          <div>
            <Fab color="primary" aria-label="edit" onClick={handleStatsAction} size="small" className="mr-2">
              {showGoodsEntryStatsLoading ?
                <CircularProgress size={24} className="text-white mt-0.50 ml-2 w-4 h-4" />
                :
                <BarChart className="w-6 h-auto" />
              }
            </Fab>
            <Chip className="pl-2 font-bold bg-blue-500" color="primary" icon={<GoodsEntryIcon />} label={`Total Records : ${total}`} />
          </div>

          <div>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={total}
              rowsPerPage={offset}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangePageRows}
            />
          </div>
        </Box>
      </Card>
      {openGoodsEntryStats &&
        <CardStatsDialog open={openGoodsEntryStats} onClose={setOpenGoodsEntryStats} viewDialogData={{ title: 'Goods Entry Stats' }} rows={goodsEntryStats} />
      }

    </>
  );
};

export default GoodsEntryTable;