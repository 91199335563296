import React, { useEffect, useState } from "react";
// javascipt plugin for creating charts
import { Chart } from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// @mui/material components
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// core components
import Header from "components/Headers/Header.js";
import CardStats from "components/Cards/CardStats.js";

// @mui/icons-material components
import InsertChartOutlined from "@mui/icons-material/InsertChartOutlined";
import PieChart from "@mui/icons-material/PieChart";

import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";

import dashboardStyles from "assets/theme/views/admin/dashboard.js";
import { dataService } from "services/data-service";
import { ButtonGroup, MenuItem, Select } from "@mui/material";
import moment from "moment";
import { useHistory } from 'react-router-dom';
import { Inventory2,  People,  Report } from "@mui/icons-material";

const useStyles = makeStyles(dashboardStyles);

function Dashboard() {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const [activeNav, setActiveNav] = useState(1);
  const [lineChartReportType, setLineChartReportType] = useState("order");
  const [monthlyOrderReportData, setMonthlyOrderReportData] = useState(null);
  const [yearlyOrderReportData, setYearlyOrderReportData] = useState(null);
  const [lineChartOrderReportData, setLineChartOrderReportData] = useState(null);
  const [quantityData, setQuantityData] = useState({})

  const yearOptions = Array.from({ length: 10 }, (value, index) => 2022 + index);
  const [selectedYear, setSelectedYear] = useState(moment().year());

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const [ordersReport, setOrdersReport] = useState({
    totalOrders: 0,
    totalQuantity: 0
  });

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const lineChartTypeHandleChange = (type) => {
    setLineChartReportType(type)
    if (type === 'order') {
      generateMonthlyOrderChartReport(monthlyOrderReportData, 'order')
    }
    else {
      generateYearlyOrderChartReport(yearlyOrderReportData, 'quantity');
    }
  }

  const toggleNavs = (index, reportType) => {
    setActiveNav(index);
    if (reportType === 'monthlyReport') {
      generateMonthlyOrderChartReport(monthlyOrderReportData, lineChartReportType)
    }
    else {
      generateYearlyOrderChartReport(yearlyOrderReportData, lineChartReportType);
    }
  };

  const generateMonthlyOrderChartReport = (monthlyReport, rptType) => {
    monthlyReport.sort(function (a, b) { return a.Month - b.Month });
    let rptDataset = null;
    if (rptType === 'order') {
      rptDataset = {
        label: "Orders",
        data: monthlyReport.map((rpt) => { return rpt.TotalOrders })
      }
    } else {
      rptDataset = {
        label: "Quantity",
        data: monthlyReport.map((rpt) => { return rpt.TotalQuantity })
      }
    }
    let reportData = {
      labels: monthlyReport.map((rpt) => { return rpt.MonthName }),
      datasets: [rptDataset]
    }
    setLineChartOrderReportData(reportData);
  }

  const generateYearlyOrderChartReport = (yearlyReport, rptType) => {
    yearlyReport.sort(function (a, b) { return a.Year - b.Year });
    let rptDataset = null;
    if (rptType === 'order') {
      rptDataset = {
        label: "Orders",
        data: yearlyReport.map((rpt) => { return rpt.TotalOrders })
      }
    } else {
      rptDataset = {
        label: "Quantity",
        data: yearlyReport.map((rpt) => { return rpt.TotalQuantity })
      }
    }
    let reportData = {
      labels: yearlyReport.map((rpt) => { return rpt.Year }),
      datasets: [rptDataset]
    }
    setLineChartOrderReportData(reportData);
  }

  const getOrderReportData = async () => {
    let res = await dataService('get', 'ReportDashboardStatistics', `year=${selectedYear || 2022}`);
    if (res?.status === 200 || res?.status === 304) {
      let yearlyReport = res?.data?.yearlyReport;
      let monthlyReport = res?.data?.monthlyReport;
      setMonthlyOrderReportData(monthlyReport);
      setYearlyOrderReportData(yearlyReport);
      generateMonthlyOrderChartReport(monthlyReport, lineChartReportType);
      setQuantityData(res?.data?.totalQuantity)

      //let resOrders = res?.data;
      let totalOrders = yearlyReport.map(resOrder => resOrder.TotalOrders).reduce((acc, resOrder) => resOrder + acc);
      let totalQuantity = yearlyReport.map(resOrder => resOrder.TotalQuantity).reduce((acc, resOrder) => resOrder + acc);
      setOrdersReport({ ...ordersReport, totalOrders: totalOrders, totalQuantity: totalQuantity })
      //setOrdersReport(res?.data);
    }
  }


  useEffect(() => {
    //let res = await dataService('get', 'OrderCountStats', `type=ByProducts&status=InProgress&startDate=null&endDate=null`);
    fetchData()
  }, [selectedYear])

  const fetchData = async () => {
    await getOrderReportData();
  }

  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6.5rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container spacing={2}>
          <Grid item lg={4} md={6} xs={12}>
            <CardStats
              subtitle="Total Orders"
              title={ordersReport?.totalOrders.toString()}
              icon={InsertChartOutlined}
              color="bgPrimary"
            />
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            <CardStats
              subtitle="Total Quantity"
              title={ordersReport?.totalQuantity.toString()}
              icon={PieChart}
              color="bgWarning"
            />
          </Grid>
          <Grid item lg={4} md={6} xs={12} onClick={() => history.push('orderflow/holded-orders')}>
            <CardStats
              subtitle="Hold Orders"
              title={quantityData?.HoldOrderTotalQuantityCount?.toString() ?? "0"}
              icon={InsertChartOutlined}
              color="bgError"
            />
          </Grid>
          <Grid item lg={4} md={6} xs={12} onClick={() => history.push('orders/running')}>
            <CardStats
              subtitle="Running Orders"
              title={quantityData?.RunningOrderTotalQuantityCount?.toString() ?? "0"}
              icon={InsertChartOutlined}
              color="bgInfo"
            />
          </Grid>
          <Grid item lg={4} md={6} xs={12} style={{ cursor: "pointer" }} onClick={() => history.push('goods-entries')}>
            <CardStats
              subtitle="GoodsEntry Count"
              title={quantityData?.GoodEntryTotalQuantityCount?.toString() ?? ""}
              icon={InsertChartOutlined}
              color="bgInfo"
            />
          </Grid>
          <Grid item lg={4} md={6} xs={12} style={{ cursor: "pointer" }} onClick={() => history.push('report/sales-report')}>
            <CardStats
              subtitle="Sales Report"
              icon={Report}
              color="bgInfo"
            />
          </Grid>
          <Grid item lg={4} md={6} xs={12} style={{ cursor: "pointer" }} onClick={() => history.push('report/customers-report')}>
            <CardStats
              subtitle="Customer Report"
              icon={People}
              color="bgInfo"
            />
          </Grid>
          <Grid item lg={4} md={6} xs={12} style={{ cursor: "pointer" }} onClick={() => history.push('report/productions-report')}>
            <CardStats
              subtitle="Production Report"
              icon={Inventory2}
              color="bgInfo"
            />
          </Grid>
        </Grid>
        <Grid container sx={{mt: 2}}>
          <Grid
            item
            xs={12}
            xl={8}
            component={Box}
            marginBottom="3rem!important"
            classes={{ root: classes.gridItemRoot }}
          >
            <Card
              classes={{
                root: classes.cardRoot + " " + classes.cardRootBgGradient,
              }}
            >
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h6"
                        letterSpacing=".0625rem"
                        marginBottom=".25rem!important"
                        className={classes.textUppercase}
                      >
                        <ButtonGroup size="small" disableElevation variant="contained" color="primary">
                          <Button
                            onClick={() => { lineChartTypeHandleChange('order') }}
                            className={lineChartReportType === 'order' ? "bg-[#5e72e4] border-[#5e72e4] hover:bg-[#233dd2]" : classes.buttonRootUnselected}
                            classes={{
                              root:
                                lineChartReportType === 'order'
                                  ? ""
                                  : classes.buttonRootUnselected,
                            }}
                          >Orders</Button>
                          <Button
                            className={lineChartReportType === 'quantity' ? "bg-[#5e72e4] border-[#5e72e4] hover:bg-[#233dd2]" : classes.buttonRootUnselected}
                            onClick={() => { lineChartTypeHandleChange('quantity') }}
                            classes={{
                              root:
                                lineChartReportType === 'quantity'
                                  ? ""
                                  : classes.buttonRootUnselected,
                            }}
                          >Quantity</Button>
                        </ButtonGroup>
                      </Box>
                      <Box
                        component={Typography}
                        variant="h2"
                        marginBottom="0!important"
                      >
                        <Box component="span" color={theme.palette.white.main}>
                          <span className="capitalize">{lineChartReportType}</span> Report
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs="auto">
                      <Box
                        justifyContent="flex-end"
                        display="flex"
                        flexWrap="wrap"
                      >

                        <Select
                          value={selectedYear}
                          onChange={handleYearChange}
                          displayEmpty
                          className={`${classes.selectEmpty} mr-2`}
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          {yearOptions.map((year, index) => {
                            return <MenuItem key={index} value={year}>{year}</MenuItem>
                          })}
                        </Select>
                        <ButtonGroup disableElevation variant="contained" color="primary">
                          <Button
                            onClick={() => toggleNavs(1, 'monthlyReport')}
                            className={activeNav === 1 ? "bg-[#5e72e4] border-[#5e72e4] hover:bg-[#233dd2]" : classes.buttonRootUnselected}
                            classes={{
                              root:
                                activeNav === 1
                                  ? ""
                                  : classes.buttonRootUnselected,
                            }}
                          >
                            Month
                          </Button>
                          <Button
                            onClick={() => toggleNavs(2, 'yearlyReport')}
                            className={activeNav === 2 ? "bg-[#5e72e4] border-[#5e72e4] hover:bg-[#233dd2]" : classes.buttonRootUnselected}
                            classes={{
                              root:
                                activeNav === 2
                                  ? ""
                                  : classes.buttonRootUnselected,
                            }}
                          >
                            Year
                          </Button>
                        </ButtonGroup>

                      </Box>
                    </Grid>
                  </Grid>
                }
                classes={{ root: classes.cardHeaderRoot }}
              ></CardHeader>
              <CardContent>
                <Box position="relative" height="350px">
                  {lineChartOrderReportData &&
                    <Line
                      data={lineChartOrderReportData}
                      options={chartExample1.options}
                      getDatasetAtEvent={(e) => console.log(e)}
                    />
                  }
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} xl={4}>
            <Card classes={{ root: classes.cardRoot }}>
              <CardHeader
                title={
                  <Box color={theme.palette.gray[600]}>
                    Performane
                  </Box>
                }
                subheader={<span className="capitalize">Total {lineChartReportType}</span>}
                classes={{ root: classes.cardHeaderRoot }}
                titleTypographyProps={{
                  variant: "h6",
                  letterSpacing: ".0625rem",
                  marginBottom: ".25rem!important",
                  classes: {
                    root: classes.textUppercase,
                  },
                }}
                subheaderTypographyProps={{
                  component: Box,
                  variant: "h2",
                  marginBottom: "0!important",
                  color: "initial",
                }}
              ></CardHeader>
              <CardContent>
                <Box position="relative" height="350px">
                  {lineChartOrderReportData &&
                    <Bar
                      data={lineChartOrderReportData}
                      options={chartExample2.options}
                    />
                  }
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Dashboard;
