import Cookies from 'js-cookie';

export const getAccessToken = () => {
  return Cookies.get('token');
};

export const getRefreshToken = () => {
  return Cookies.get('refreshToken');
};

export const setTokens = (accessToken, refreshToken) => {
  Cookies.set('token', accessToken, { expires: 7 });
  Cookies.set('refreshToken', refreshToken, { expires: 7 });
};

export const removeTokens = () => {
  Cookies.remove('token');
  Cookies.remove('refreshToken');
};

export const isAuthenticated = () => {
  return !!getAccessToken() && !!getRefreshToken();
}; 