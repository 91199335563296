import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material";
import { Box, Card, CardContent, Typography, Stack } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    position: 'relative',
    overflow: 'hidden',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    '&:hover': {
      boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12)',
    },
  },
  iconBox: {
    borderRadius: '12px',
    padding: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 0.3s ease',
  },
}));

function CardStats({ subtitle, title, icon, color }) {
  const classes = useStyles();
  const theme = useTheme();

  const isMobile = theme.breakpoints.down('sm');

  const getColorScheme = (color) => {
    const colorSchemes = {
      bgPrimary: {
        background: theme.palette.primary.main,
        light: `${theme.palette.primary.main}15`,
      },
      bgError: {
        background: theme.palette.error.main,
        light: `${theme.palette.error.main}15`,
      },
      bgWarning: {
        background: theme.palette.warning.main,
        light: `${theme.palette.warning.main}15`,
      },
      bgInfo: {
        background: theme.palette.info.main,
        light: `${theme.palette.info.main}15`,
      },
    };
    return colorSchemes[color] || colorSchemes.bgPrimary;
  };

  const colorScheme = getColorScheme(color);

  return (
    <Card 
      className={classes.cardRoot} 
      elevation={0}
      sx={{
        height: '100%',
        backgroundColor: 'background.paper',
        border: "1px solid #E0E0E0",
      }}
    >
      <CardContent sx={{ p: 2, pb : "16px !important" }}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Box
            className={classes.iconBox}
            sx={{
              backgroundColor: colorScheme.light,
            }}
          >
            {icon && typeof icon === "object" && (
              <Box
                component={icon}
                sx={{
                  width: 24,
                  height: 24,
                  color: colorScheme.background,
                }}
              />
            )}
          </Box>

          <Stack spacing={0.5} flex={1}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ 
                fontSize: '0.875rem',
                fontWeight: "bold",
                textTransform: 'uppercase',
                letterSpacing: '0.5px'
              }}
            >
              {subtitle}
            </Typography>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 600,
                  color: 'text.primary',
                  fontSize: isMobile ? '1.2rem' : '1.5rem',
                  marginTop: '0 !important',
                  marginBottom: '0 !important',
                }}
              >
                {title}
              </Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

CardStats.defaultProps = {
  color: "bgPrimary",
};

CardStats.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string,
  trend: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  color: PropTypes.oneOf([
    "bgPrimary",
    "bgError",
    "bgWarning",
    "bgInfo",
  ]),
};

export default CardStats;
