import React from "react";
import Box from "@mui/material/Box";
import FilledInput from "@mui/material/FilledInput";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel, InputLabel, MenuItem, Stack, useMediaQuery, useTheme } from "@mui/material";

export function OrderShadeCard(props) {

  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const iMDScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const iXLScreen = useMediaQuery(theme.breakpoints.down('xl'));

  const commonFormControlLabelSx = {
    '& .MuiFormControlLabel-label': {
      fontSize: {
        xs: '12px !important',
        sm: "14px !important"
      },
    },
  };

  return (
    <>
      {/* Party Fabric Information Start */}
      <div className="border-2 border-gray-200 rounded-md mb-6" >
        <div className="bg-[#e2e4e9] px-4 py-2 border-b sm:px-6">
          <Typography variant="h3" marginBottom="0!important">Party Fabric</Typography>
        </div>
        <div className="px-6 py-4">
          <Grid container spacing={isSmallScreen ? 0.5 : 1}>
            <Grid item xs={6} sm={4} lg={3} pr={1} pl={1} >
              <Stack spacing={0.5}>
                <InputLabel htmlFor="PBFull" className="text-xs sm:text-sm">Full</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="PBFull"
                  value={props?.orderModel?.PBFull}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      fontSize: isSmallScreen ? "12px" : "14px",
                      height: 35,
                    },
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={4} lg={3} pr={1} pl={1}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="PBFullMtr">Full Meter</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="PBFullMtr"
                  value={props?.orderModel?.PBFullMtr}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      fontSize: isSmallScreen ? "12px" : "14px",
                      height: 35,
                    },
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={4} lg={3} pr={1} pl={1}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="PBMain">Main</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="PBMain"
                  value={props?.orderModel?.PBMain}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      fontSize: isSmallScreen ? "12px" : "14px",
                      height: 35,
                    },
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={4} lg={3} pr={1} pl={1}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="PBMainMtr">Main Meter</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="PBMainMtr"
                  value={props?.orderModel?.PBMainMtr}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      fontSize: isSmallScreen ? "12px" : "14px",
                      height: 35,
                    },
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={12} pl={1} pr={1}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="PBRemarks">Remarks</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="PBRemarks"
                  value={props?.orderModel?.PBRemarks}
                  multiline
                  minRows={2}
                  maxRows={4}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      height: 35,
                      fontSize: isSmallScreen ? "12px" : "14px",
                      border: 'none',
                      padding: '0px'

                    },
                    '& .MuiFilledInput-root': {
                      border: '1px solid #cad1d7 !important',
                      borderRadius: '8px',
                    },
                    marginBottom: '1rem!important',
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
          </Grid>
        </div>
      </div>
      {/* Party Fabric Information End */}


      {/* Cutting Information Start */}
      <div className="border-2 border-gray-200 rounded-md mb-6" >
        <div className="bg-[#e2e4e9] px-4 py-2 border-b sm:px-6">
          <Typography variant="h3" marginBottom="0!important">Cutting</Typography>
        </div>
        <div className="px-6 py-4">
          <Grid container spacing={isSmallScreen ? 0.5 : 1}>
            <Grid item xs={6} sm={4} lg={4} pl={1} pr={1}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="CuttingSmallPiecePatta">Small Piece Patta</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="CuttingSmallPiecePatta"
                  value={props?.orderModel?.CuttingSmallPiecePatta}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      fontSize: isSmallScreen ? "12px" : "14px",
                      height: 35,
                    },
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={4} lg={4} pl={1} pr={1}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="CuttingSmallPieceSize">Small Piece Size</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="CuttingSmallPieceSize"
                  value={props?.orderModel?.CuttingSmallPieceSize}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      fontSize: isSmallScreen ? "12px" : "14px",
                      height: 35,
                    },
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={4} lg={4} pl={1} pr={1}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="CuttingTable">Table</InputLabel>
                <Select value={props?.orderModel?.CuttingTable} name="CuttingTable"
                  onChange={e => props?.handleSelectChange('CuttingTable', e.target.value)}
                  sx={{
                    '& .MuiOutlinedInput-input': {
                      height: '35px !important',
                    },
                  }}
                >
                  <MenuItem value={null}>Select</MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                  <MenuItem value={7}>7</MenuItem>
                </Select>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={4} lg={4} pl={1} pr={1}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="CuttingFillerPatta">Filler Patta</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="CuttingFillerPatta"
                  value={props?.orderModel?.CuttingFillerPatta}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      fontSize: isSmallScreen ? "12px" : "14px",
                      height: 35,
                    },
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={4} lg={4} pl={1} pr={1}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="CuttingFillerSize">Filler Size</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="CuttingFillerSize"
                  value={props?.orderModel?.CuttingFillerSize}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      fontSize: isSmallScreen ? "12px" : "14px",
                      height: 35,
                    },
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={4} lg={4} pl={1} pr={1}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="CuttingTotal">Total</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="CuttingTotal"
                  value={props?.orderModel?.CuttingTotal}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      fontSize: isSmallScreen ? "12px" : "14px",
                      height: 35,
                    },
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <Stack spacing={0.5}>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: '1rem', flexWrap: 'wrap' }}>
                  <FormGroup style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: "0rem" }}>
                    <FormControlLabel
                      // className="sm:ml-2"
                      control={
                        <Checkbox
                          checked={props?.orderModel?.TagZigZag}
                          onChange={props?.handleCheckBoxes}
                          name="TagZigZag"
                          color="primary"
                        />
                      }
                      label="ZigZag"
                      sx={commonFormControlLabelSx}
                    />
                    <FormControlLabel
                      // className="sm:ml-2"
                      control={
                        <Checkbox
                          checked={props?.orderModel?.TagInterlock}
                          onChange={props?.handleCheckBoxes}
                          name="TagInterlock"
                          color="primary"
                        />
                      }
                      label="Interlock"
                      sx={commonFormControlLabelSx}
                    />
                  </FormGroup>
                </div>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="CuttingRemarks">Remarks</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="CuttingRemarks"
                  value={props?.orderModel?.CuttingRemarks}
                  multiline
                  minRows={2}
                  maxRows={4}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      height: 35,
                      fontSize: isSmallScreen ? "12px" : "14px",
                      border: 'none',
                      padding: '0px'

                    },
                    '& .MuiFilledInput-root': {
                      border: '1px solid #cad1d7 !important',
                      borderRadius: '8px',
                    },
                    marginBottom: '1rem!important',
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
          </Grid>
        </div>
      </div>
      {/* Cutting Information End */}

      {/* Binding Information Start */}
      <div className="border-2 border-gray-200 rounded-md mb-6" >
        <div className="bg-[#e2e4e9] px-4 py-2 border-b sm:px-6">
          <Typography variant="h3" marginBottom="0!important">Binding</Typography>
        </div>
        <div className="px-6 py-4">
          <Grid container spacing={isSmallScreen ? 0.5 : 1}>
            <Grid item xs={12} sm={6} lg={3} px={1}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="BindingsBackToBack">Back to Back</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="BindingsBackToBack"
                  value={props?.orderModel?.BindingsBackToBack}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      fontSize: isSmallScreen ? "12px" : "14px",
                      height: 35,
                    },
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} lg={3} pr={1} pl={1}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="BindingsTotal">Total</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="BindingsTotal"
                  value={props?.orderModel?.BindingsTotal}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      fontSize: isSmallScreen ? "12px" : "14px",
                      height: 35,
                    },
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <Stack spacing={0.5}>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: '1rem', flexWrap: 'wrap' }}>
                  <FormGroup style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: "0rem" }}>
                    <FormControlLabel
                      // className="sm:ml-2"
                      control={
                        <Checkbox
                          checked={props?.orderModel?.BindingsDieCut}
                          onChange={props?.handleCheckBoxes}
                          name="BindingsDieCut"
                          color="primary"
                        />
                      }
                      label="Die Cut"
                      sx={commonFormControlLabelSx}
                    />
                    <FormControlLabel
                      // className="sm:ml-2"
                      control={
                        <Checkbox
                          checked={props?.orderModel?.BindingsFoil}
                          onChange={props?.handleCheckBoxes}
                          name="BindingsFoil"
                          color="primary"
                        />
                      }
                      label="Foil"
                      sx={commonFormControlLabelSx}
                    />
                    <FormControlLabel
                      // className="sm:ml-2"
                      control={
                        <Checkbox
                          checked={props?.orderModel?.BindingsTagPrinted}
                          onChange={props?.handleCheckBoxes}
                          name="BindingsTagPrinted"
                          color="primary"
                        />
                      }
                      label="Tag Printed"
                      sx={commonFormControlLabelSx}
                    />
                    <FormControlLabel
                      // className="sm:ml-2"
                      control={
                        <Checkbox
                          checked={props?.orderModel?.BindingsMDF}
                          onChange={props?.handleCheckBoxes}
                          name="BindingsMDF"
                          color="primary"
                        />
                      }
                      label="MDF"
                      sx={commonFormControlLabelSx}
                    />
                    <FormControlLabel
                      // className="sm:ml-2"
                      control={
                        <Checkbox
                          checked={props?.orderModel?.BindingsCappaBoard}
                          onChange={props?.handleCheckBoxes}
                          name="BindingsCappaBoard"
                          color="primary"
                        />
                      }
                      label="Cappa Board"
                      sx={commonFormControlLabelSx}
                    />
                  </FormGroup>
                </div>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="BindingsCorner">Remarks</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="BindingsRemarks"
                  value={props?.orderModel?.BindingsRemarks}
                  multiline
                  minRows={2}
                  maxRows={4}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      height: 35,
                      fontSize: isSmallScreen ? "12px" : "14px",
                      border: 'none',
                      padding: '0px'

                    },
                    '& .MuiFilledInput-root': {
                      border: '1px solid #cad1d7 !important',
                      borderRadius: '8px',
                    },
                    marginBottom: '1rem!important',
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
          </Grid>
        </div>

      </div>

      {/* Binding Information End */}

      {/* Photos Information Start */}
      <div className="border-2 border-gray-200 rounded-md mb-6" >
        <div className="bg-[#e2e4e9] px-4 py-2 border-b sm:px-6">
          <Typography variant="h3" marginBottom="0!important">Photo Making</Typography>
        </div>
        <div className="px-6 py-4">
          <Grid container spacing={isSmallScreen ? 0.5 : 1}>
            <Grid item xs={12} sm={4} md={12} lg={1.5} xl={2} pl={1} pr={1}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="PhotosPrinter">Printer</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="PhotosPrinter"
                  value={props?.orderModel?.PhotosPrinter}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      height: 35,
                      fontSize: isSmallScreen ? "12px" : "14px",
                    },
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={4} md={12} lg={2} xl={2} pl={1} pr={1}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="PhotosPaperGSM">Photo Paper GSM</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="PhotosPaperGSM"
                  value={props?.orderModel?.PhotosPaperGSM}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      height: 35,
                      fontSize: isSmallScreen ? "12px" : "14px",
                    },
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={4} md={12} lg={2} xl={2} pl={1} pr={1}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="PhotosSize">Photo Size</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="PhotosSize"
                  value={props?.orderModel?.PhotosSize}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      height: 35,
                      fontSize: isSmallScreen ? "12px" : "14px",
                    },
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={12} md={12} lg={6.5} xl={6} >
              <Stack spacing={0.5}>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: '1rem', flexWrap: 'wrap' }}>
                  <Typography className="font-bold " style={{ marginRight: '1rem' }}>Page:</Typography>
                  <FormGroup style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: "0rem" }}>
                    <FormControlLabel 
                      className="sm:ml-0"
                      control={
                        <Checkbox
                          checked={props?.orderModel?.PhotosPageArry?.includes(1)}
                          onChange={props?.handleMultiCheckBoxes}
                          value={1}
                          name="PhotosPage"
                          color="primary"
                        />
                      }
                      label="1"
                      sx={commonFormControlLabelSx}
                    />
                    <FormControlLabel
                      className="sm:ml-0"
                      control={
                        <Checkbox
                          checked={props?.orderModel?.PhotosPageArry?.includes(2)}
                          onChange={props?.handleMultiCheckBoxes}
                          value={2}
                          name="PhotosPage"
                          color="primary"
                        />
                      }
                      label="2"
                      sx={commonFormControlLabelSx}
                    />
                    <FormControlLabel
                      className="sm:ml-0"
                      control={
                        <Checkbox
                          checked={props?.orderModel?.PhotosPageArry?.includes(3)}
                          onChange={props?.handleMultiCheckBoxes}
                          value={3}
                          name="PhotosPage"
                          color="primary"
                        />
                      }
                      label="3"
                      sx={commonFormControlLabelSx}
                    />
                    <FormControlLabel
                      className="sm:ml-0"
                      control={
                        <Checkbox
                          checked={props?.orderModel?.PhotosPageArry?.includes(4)}
                          onChange={props?.handleMultiCheckBoxes}
                          value={4}
                          name="PhotosPage"
                          color="primary"
                        />
                      }
                      label="4"
                      sx={commonFormControlLabelSx}
                    />
                  </FormGroup>
                </div>
              </Stack>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormGroup style={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : "row", alignItems: isSmallScreen ? 'flex-start' : "center", marginBottom: "0rem" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={props?.orderModel?.PhotosLamination}
                      onChange={props?.handleCheckBoxes}
                      name="PhotosLamination"
                      color="primary"
                    />
                  }
                  label="Lamination"
                  sx={commonFormControlLabelSx}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={props?.orderModel?.PhotosUV}
                      onChange={props?.handleCheckBoxes}
                      name="PhotosUV"
                      color="primary"
                    />
                  }
                  label="UV"
                  sx={commonFormControlLabelSx}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={props?.orderModel?.PhotosMatt}
                      onChange={props?.handleCheckBoxes}
                      name="PhotosMatt"
                      color="primary"
                    />
                  }
                  label="Matt"
                  sx={commonFormControlLabelSx}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={props?.orderModel?.PhotosGlossy}
                      onChange={props?.handleCheckBoxes}
                      name="PhotosGlossy"
                      color="primary"
                    />
                  }
                  label="Glossy"
                  sx={commonFormControlLabelSx}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="PhotosRemarks">Remarks</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="PhotosRemarks"
                  value={props?.orderModel?.PhotosRemarks}
                  multiline
                  minRows={2}
                  maxRows={4}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      height: 35,
                      fontSize: isSmallScreen ? "12px" : "14px",
                      border: 'none',
                      padding: '0px'

                    },
                    '& .MuiFilledInput-root': {
                      border: '1px solid #cad1d7 !important',
                      borderRadius: '8px',
                    },
                    marginBottom: '1rem!important',
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
          </Grid>
        </div>
      </div>
      {/* Photos Information End */}

      {/* Screen Printing Information Start */}

      <div className="border-2 border-gray-200 rounded-md mb-6" >
        <div className="bg-[#e2e4e9] px-4 py-2 border-b sm:px-6">
          <Typography variant="h3" marginBottom="0!important">Screen Printing</Typography>
        </div>
        <div className="py-4 px-6">
          <Grid container spacing={isSmallScreen ? 0.5 : 1}>
            <Grid item xs={6} sm={6} lg={3} pl={1} pr={1}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="ScreenGumming">Gumming</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="ScreenGumming"
                  value={props?.orderModel?.ScreenGumming}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      height: 35,
                      fontSize: isSmallScreen ? "12px" : "14px",
                    },
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={6} lg={3} pl={1} pr={1}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="ScreenTotal">Total</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="ScreenTotal"
                  value={props?.orderModel?.ScreenTotal}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      height: 35,
                      fontSize: isSmallScreen ? "12px" : "14px",
                    },
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <Stack>
                <InputLabel className="text-xs sm:text-sm" htmlFor="ScreenRemarks">Remarks</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="ScreenRemarks"
                  value={props?.orderModel?.ScreenRemarks}
                  multiline
                  minRows={2}
                  maxRows={4}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      height: 35,
                      fontSize: isSmallScreen ? "12px" : "14px",
                      border: 'none',
                      padding: '0px'

                    },
                    '& .MuiFilledInput-root': {
                      border: '1px solid #cad1d7 !important',
                      borderRadius: '8px',
                    },
                    marginBottom: '1rem!important',
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
          </Grid>

        </div>


      </div>
      {/* Screen Printing Information End */}

      {/* Fitting Information Start */}
      <div className="border-2 border-gray-200 rounded-md mb-6" >
        <div className="bg-[#e2e4e9] px-4 py-2 border-b sm:px-6">
          <Typography variant="h3" marginBottom="0!important">Fittings</Typography>
        </div>
        <div className="py-5 px-4">
          <Grid container spacing={isSmallScreen ? 0.5 : 1}>
            <Grid item xs={12} lg={4} pl={1} pr={1}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="FittingFiller">Filler</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="FittingFiller"
                  value={props?.orderModel?.FittingFiller}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      height: 35,
                      fontSize: isSmallScreen ? "12px" : "14px"
                    },
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={4} pl={1} pr={1}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="FittingTotal">Total</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="FittingTotal"
                  value={props?.orderModel?.FittingTotal}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      height: 35,
                      fontSize: isSmallScreen ? "12px" : "14px"
                    },
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>

            <Grid item xs={12} lg={12} >
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="FittingRemarks">Remarks</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="FittingRemarks"
                  value={props?.orderModel?.FittingRemarks}
                  multiline
                  minRows={2}
                  maxRows={4}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      height: 35,
                      fontSize: isSmallScreen ? "12px" : "14px",
                      border: 'none',
                      padding: '0px'

                    },
                    '& .MuiFilledInput-root': {
                      border: '1px solid #cad1d7 !important',
                      borderRadius: '8px',
                    },
                    marginBottom: '1rem!important',
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
          </Grid>
        </div>


      </div>
      {/* Fitting Information End */}
    </>
  );
};