import React from "react";
// @mui/material components
import Grid from "@mui/material/Grid";
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";

export function OrderECatalogue(props) {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const commonFormControlLabelSx = {
        '& .MuiFormControlLabel-label': {
            fontSize: {
                xs: '12px !important',
                sm: "14px !important"
            },
        },
    };

    return (
        <>
            {/* PhotoShoot Information Start */}
            <div className="border-2 border-gray-200 rounded-md mb-6">
                <div className="bg-[#e2e4e9] mb-4 px-4 py-2 border-b sm:px-6">
                    <Typography variant="h3" marginBottom="0!important">Information</Typography>
                </div>
                <div className="px-6 pb-2 -mt-2">
                    <Grid container spacing={isSmallScreen ? 0.5 : 1}>
                        <Grid item xs={12} lg={4}>
                            <Stack spacing={0.5}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={props?.orderModel?.ECataloguePhotoShoot}
                                            onChange={props?.handleCheckBoxes}
                                            name="ECataloguePhotoShoot"
                                            color="primary"
                                        />
                                    }
                                    label="Photoshoot"
                                    sx={commonFormControlLabelSx}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Stack spacing={0.5}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={props?.orderModel?.ECatalogueFabricLayout}
                                            onChange={props?.handleCheckBoxes}
                                            name="ECatalogueFabricLayout"
                                            color="primary"
                                        />
                                    }
                                    label="Fabric Layout"
                                    sx={commonFormControlLabelSx}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Stack spacing={0.5}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={props?.orderModel?.ECatalogueColorMatching}
                                            onChange={props?.handleCheckBoxes}
                                            name="ECatalogueColorMatching"
                                            color="primary"
                                        />
                                    }
                                    label="Color Matching"
                                    sx={commonFormControlLabelSx}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Stack spacing={0.5}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={props?.orderModel?.ECatalogueApproval}
                                            onChange={props?.handleCheckBoxes}
                                            name="ECatalogueApproval"
                                            color="primary"
                                        />
                                    }
                                    label="Approval"
                                    sx={commonFormControlLabelSx}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Stack spacing={0.5}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={props?.orderModel?.ECatalogueMail}
                                            onChange={props?.handleCheckBoxes}
                                            name="ECatalogueMail"
                                            color="primary"
                                        />
                                    }
                                    label="Mail"
                                    sx={commonFormControlLabelSx}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Stack spacing={0.5}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={props?.orderModel?.ECatalogueDelivery}
                                            onChange={props?.handleCheckBoxes}
                                            name="ECatalogueDelivery"
                                            color="primary"
                                        />
                                    }
                                    label="Delivery"
                                    sx={commonFormControlLabelSx}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                </div>
            </div>

        </>
    );
};