import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@mui/styles";
import { createFilterOptions, debounce, FormHelperText, useTheme, useMediaQuery, Drawer, IconButton, InputLabel, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import FilledInput from "@mui/material/FilledInput";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Checkbox from '@mui/material/Checkbox';

import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import componentStyles from "assets/theme/views/admin/profile.js";
import { dataService } from "services/data-service";
import { checkEmptyValidation } from "services/validation-service";
import { Divider, FormControlLabel } from "@mui/material";
import MessageBar from "components/MessageBar/MessageBar";
import Header from "components/Headers/Header";
import { useHistory } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams } from 'react-router-dom';
import ChangePasswordDialog from 'components/dialoges/ChangePasswordDialog';
import { Autocomplete } from "@mui/material";
import moment from "moment";
import { OrderFile } from "../../../components/OrderForm/OrderFile";
import { OrderHeader } from "../../../components/OrderForm/OrderHeader";
import { OrderShadeCard } from "components/OrderForm/OrderShadeCard";
import { OrderECatalogue } from "components/OrderForm/OrderECatalogue";
import CatelougeDialog from "components/dialoges/CatelougeDialog";
import ViewInventoryDialog from "components/dialoges/ViewInventoryDialog";
import PreOrderView from './PreOrderView';
import { KeyboardArrowLeft } from "@mui/icons-material";

const filter = createFilterOptions();

const useStyles = makeStyles(componentStyles);

function AddOrder() {

  const classes = useStyles();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

  const history = useHistory();

  const { id, CopyOrderId, GoodsEntryId, GoodsEntryFileId, PreOrderId } = useParams();
  const PageTitle = id ? 'Update Order' : 'Add Order';

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const iMDScreen = useMediaQuery(theme.breakpoints.down('md'));
  const iXLScreen = useMediaQuery(theme.breakpoints.down('xl'));

  const setOrderModelData = (orderData) => {
    let orderModelDetail = {
      ProductId: orderData ? orderData?.ProductId : null,
      CustomerId: orderData ? orderData?.CustomerId : null,
      Client: orderData ? orderData?.Client : null,
      Date: CopyOrderId ? moment() : orderData ? orderData?.Date : null,
      ProductFileId: orderData ? orderData?.ProductFileId : null,
      FileId: orderData ? orderData?.FileId : null,
      Quantity: orderData ? orderData?.Quantity : null,
      Patta: orderData ? orderData?.Patta : null,
      IsRepeat: orderData ? orderData?.IsRepeat : null,
      RepeatOrderId: orderData ? orderData?.RepeatOrderId : null,
      UnitPrice: orderData ? orderData?.UnitPrice : null,
      PBFull: orderData ? orderData?.PBFull : null,
      PBFGaze: orderData ? orderData?.PBFGaze : null,
      PBTGaze: orderData ? orderData?.PBTGaze : null,
      PBFullMtr: orderData ? orderData?.PBFullMtr : null,
      PBHalf: orderData ? orderData?.PBHalf : null,
      PBHalfMtr: orderData ? orderData?.PBHalfMtr : null,
      PBStep: orderData ? orderData?.PBStep : null,
      PBStepMtr: orderData ? orderData?.PBStepMtr : null,
      PBMain: orderData ? orderData?.PBMain : null,
      PBMainMtr: orderData ? orderData?.PBMainMtr : null,
      PBWaterfall: orderData ? orderData?.PBWaterfall : null,
      PBWaterfallMtr: orderData ? orderData?.PBWaterfallMtr : null,
      PBTotal: orderData ? orderData?.PBTotal : null,
      PBTotalMtr: orderData ? orderData?.PBTotalMtr : null,
      PBRemarks: orderData ? orderData?.PBRemarks : null,
      CuttingPatta: orderData ? orderData?.CuttingPatta : null,
      CuttingTukda: orderData ? orderData?.CuttingTukda : null,
      CuttingTable: orderData ? orderData?.CuttingTable : null,
      CuttingFold: orderData ? orderData?.CuttingFold : null,
      CuttingMainPatta: orderData ? orderData?.CuttingMainPatta : null,
      CuttingMainTukda: orderData ? orderData?.CuttingMainTukda : null,
      CuttingMainTable: orderData ? orderData?.CuttingMainTable : null,
      CuttingMainFold: orderData ? orderData?.CuttingMainFold : null,
      CuttingSmallPiecePatta: orderData ? orderData?.CuttingSmallPiecePatta : null,
      CuttingSmallPieceSize: orderData ? orderData?.CuttingSmallPieceSize : null,
      CuttingFillerPatta: orderData ? orderData?.CuttingFillerPatta : null,
      CuttingFillerSize: orderData ? orderData?.CuttingFillerSize : null,
      CuttingTotal: orderData ? orderData?.CuttingTotal : null,
      CuttingRemarks: orderData ? orderData?.CuttingRemarks : null,
      BindingsFabricColor: orderData ? orderData?.BindingsFabricColor : null,
      BindingsTopPhoto: orderData ? orderData?.BindingsTopPhoto : null,
      Bindings11: orderData ? orderData?.Bindings11 : null,
      Bindings15: orderData ? orderData?.Bindings15 : null,
      BindingsFull: orderData ? orderData?.BindingsFull : null,
      BindingsBackToBack: orderData ? orderData?.BindingsBackToBack : null,
      BindingsTotal: orderData ? orderData?.BindingsTotal : null,
      BindingsKaan: orderData ? orderData?.BindingsKaan : null,
      BindingsTop: orderData ? orderData?.BindingsTop : null,
      BindingsBottom: orderData ? orderData?.BindingsBottom : null,
      BindingsPatti: orderData ? orderData?.BindingsPatti : null,
      BindingsDori: orderData ? orderData?.BindingsDori : null,
      BindingsHook: orderData ? orderData?.BindingsHook : null,
      BindingsCorner: orderData ? orderData?.BindingsCorner : null,
      BindingsRemarks: orderData ? orderData?.BindingsRemarks : null,
      TagLogo: orderData ? orderData?.TagLogo : null,
      TagCollection: orderData ? orderData?.TagCollection : null,
      TagDesign: orderData ? orderData?.TagDesign : null,
      TagShed: orderData ? orderData?.TagShed : null,
      TagInstruction: orderData ? orderData?.TagInstruction : null,
      TagSymbol: orderData ? orderData?.TagSymbol : null,
      TagSrNo: orderData ? orderData?.TagSrNo : null,
      TagWidth: orderData ? orderData?.TagWidth : null,
      TagWidthValue: orderData ? orderData?.TagWidthValue : null,
      TagInterlock: orderData ? orderData?.TagInterlock : null,
      TagZigZag: orderData ? orderData?.TagZigZag : null,
      TagRemarks: orderData ? orderData?.TagRemarks : null,
      TagComposition: orderData ? orderData?.TagComposition : null,
      TaggingRubs: orderData ? orderData?.TaggingRubs : null,
      TaggingRubsValue: orderData ? orderData?.TaggingRubsValue : null,
      TaggingGSM: orderData ? orderData?.TaggingGSM : null,
      TaggingGSMValue: orderData ? orderData?.TaggingGSMValue : null,
      PhotosSize: orderData ? orderData?.PhotosSize : null,
      PhotosPrinter: orderData ? orderData?.PhotosPrinter : null,
      PhotosPaperGSM: orderData ? orderData?.PhotosPaperGSM : null,
      PhotosPage: orderData ? orderData?.PhotosPage : null,
      PhotosPageArry: orderData?.PhotosPageArry ? orderData?.PhotosPageArry : [],
      PhotosLamination: orderData ? orderData?.PhotosLamination : null,
      PhotosColdPage: orderData ? orderData?.PhotosColdPage : null,
      PhotosUV: orderData ? orderData?.PhotosUV : null,
      PhotosMatt: orderData ? orderData?.PhotosMatt : null,
      PhotosGlossy: orderData ? orderData?.PhotosGlossy : null,
      PhotosRemarks: orderData ? orderData?.PhotosRemarks : null,
      ScreenTop: orderData ? orderData?.ScreenTop : null,
      ScreenBottom: orderData ? orderData?.ScreenBottom : null,
      ScreenPatti: orderData ? orderData?.ScreenPatti : null,
      ScreenPrinted: orderData ? orderData?.ScreenPrinted : null,
      ScreenPrintedBit: orderData ? orderData?.ScreenPrintedBit : null,
      ScreenGumming: orderData ? orderData?.ScreenGumming : null,
      ScreenRemarks: orderData ? orderData?.ScreenRemarks : null,
      FittingFirstStep: orderData ? orderData?.FittingFirstStep : null,
      FittingMainPiece: orderData ? orderData?.FittingMainPiece : null,
      FittingNumber: orderData ? orderData?.FittingNumber : null,
      FittingNumberArry: orderData?.FittingNumberArry ? orderData?.FittingNumberArry : [],
      FittingStepGap: orderData ? orderData?.FittingStepGap : null,
      FittingFiller: orderData ? orderData?.FittingFiller : null,
      FittingTotal: orderData ? orderData?.FittingTotal : null,
      FittingRemarks: orderData ? orderData?.FittingRemarks : null,
      Parcel: orderData ? orderData?.Parcel : null,
      Loose: orderData ? orderData?.Loose : null,
      ChallanSequence1: orderData ? orderData?.ChallanSequence1 : null,
      ChallanSequence3: orderData ? orderData?.ChallanSequence3 : null,
      Remarks: orderData ? orderData?.Remarks : null,

      ECataloguePhotoShoot: orderData ? orderData?.ECataloguePhotoShoot : null,
      ECatalogueFabricLayout: orderData ? orderData?.ECatalogueFabricLayout : null,
      ECatalogueColorMatching: orderData ? orderData?.ECatalogueColorMatching : null,
      ECatalogueApproval: orderData ? orderData?.ECatalogueApproval : null,
      ECatalogueMail: orderData ? orderData?.ECatalogueMail : null,
      ECatalogueDelivery: orderData ? orderData?.ECatalogueDelivery : null,

      BillingEmail: orderData ? orderData?.BillingEmail : null,
      ShippingEmail: orderData ? orderData?.ShippingEmail : null,

      BindingsDieCut: orderData ? orderData?.BindingsDieCut : null,
      BindingsFoil: orderData ? orderData?.BindingsFoil : null,
      BindingsTagPrinted: orderData ? orderData?.BindingsTagPrinted : null,
      BindingsMDF: orderData ? orderData?.BindingsMDF : null,
      BindingsCappaBoard: orderData ? orderData?.BindingsCappaBoard : null,
      PreviousQuantity: orderData ? orderData?.Quantity : null
    }
    return orderModelDetail;
  }


  const [isOpenInventoryDialog, setIsOpenInventoryDialog] = useState({
    isVisible: false,
    data: null,
    materialList: []
  });
  const [orderModel, setOrderModel] = useState(setOrderModelData(null));
  const [isEcatelouge, setIsCatelouge] = useState(false)

  const [goodsEntryDetail, setGoodsEntryDetail] = useState(null);
  const [goodsEntryFilesDetail, setGoodsEntryFilesDetail] = useState(null);

  const [FileNamesList, setFileNamesList] = useState([]);
  const [fileLoading, setFileLoading] = useState(false);

  const [orderValidateModel, setOrderValidateModel] = useState({

    ProductId: { message: '', touched: false },
    CustomerId: { message: '', touched: false },
    Date: { message: '', touched: false },
    ProductFileId: { message: '', touched: false },
    Quantity: { message: '', touched: false },
    UnitPrice: { message: '', touched: false },
    IsValidationError: false
  });

  const [preOrderDetail, setPreOrderDetail] = useState(null);
  const [showSubmitLoading, setShowSubmitLoading] = useState(false);
  const [showOrderLoading, setShowOrderLoading] = useState(false);

  const [productList, setProductList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [productFileList, setProductFileList] = useState([]);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedProductFile, setSelectedProductFile] = useState(null);

  const [isEcatelougeModel, setIsCatelougeModel] = useState(false)
  const [eCateLougeValue, seteCateLougeValue] = useState({
    Remarks: null,
    UnitPrice: null
  })

  let intialProductList = [];
  let intialCustomerList = [];
  let intialFileList = [];
  let intialProductFileList = [];

  const [messageDialoge, setMessageDialoge] = useState({
    show: false,
    type: '',
    message: ''
  });

  const [openChangePasswordDialog, setOpenChangePasswordDialog] = useState(false);
  const [showPreOrder, setShowPreOrder] = useState(false);


  useEffect(() => {
    fetchData()
  }, [id])

  const fetchData = async () => {
    setShowOrderLoading(true);
    await getProductList();
    await getCustomerList();
    await getFileList();
    if (id || CopyOrderId) {
      await getOrderData();
    }
    else if (GoodsEntryId && GoodsEntryFileId) {
      await getGoodsEntryData();
    }
    else if (PreOrderId) {
      await getPreOrderData();
    }
    else {
      await getProductFileList(orderModel?.CustomerId, orderModel?.ProductId);
    }
    setShowOrderLoading(false);
  }

  const getProductList = async () => {
    let res = await dataService('get', 'CommonList', `Query=products`);
    if (res.status === 200) {
      if (res?.data?.length > 0) {
        let mList = res?.data //reduce((acc, e) => { acc.push({ Id: e.Id, WorkCenterId: e.WorkCenterId, WorkCenterName: e.WorkCenterName, Name: e.Name, Default: e.Default }); return acc }, []);
        intialProductList = mList;
        setProductList(mList);
      }
    }
  }

  const getCustomerList = async () => {
    let res = await dataService('get', 'CommonList', `Query=customers`);
    if (res.status === 200) {
      if (res?.data?.length > 0) {
        let mList = res?.data //reduce((acc, e) => { acc.push({ Id: e.Id, WorkCenterId: e.WorkCenterId, WorkCenterName: e.WorkCenterName, Name: e.Name, Default: e.Default }); return acc }, []);

        //let sC = productList?.find(c => c.Id === (orderModel.ProductId  ? orderModel.ProductId : 103))
        intialCustomerList = mList;
        setCustomerList(mList);
      }
    }
  }

  const getFileList = async () => {
    let res = await dataService('get', 'CommonList', `Query=files`);
    if (res.status === 200) {
      if (res?.data?.length > 0) {
        let mList = res?.data //reduce((acc, e) => { acc.push({ Id: e.Id, WorkCenterId: e.WorkCenterId, WorkCenterName: e.WorkCenterName, Name: e.Name, Default: e.Default }); return acc }, []);
        //let sC = productList?.find(c => c.Id === (orderModel.ProductId  ? orderModel.ProductId : 103))
        intialFileList = mList;
        setFileList(mList);
      }
    }
  }

  const fetchFileListData = useCallback(async (search = '') => {
    setFileLoading(true);
    try {
      let res = await dataService('get', 'FileSearchByName', `searchText=${search}`);
      setFileNamesList(res?.data);
    } catch (error) {
      console.error("Error fetching file list:", error);
    } finally {
      setFileLoading(false);
    }
  }, []);

  const handleTextChange = useCallback(
    debounce(async function (event) {
      if (event.target.value.length === 0 || event.target.value.length >= 3) {
        await fetchFileListData(event?.target?.value ?? '');
      }
    }, 300),
    [fetchFileListData]
  );


  const getProductFileList = async (CustomerIdVal, ProductIdVal) => {
    let res = await dataService('get', 'CommonList', `Query=productFilesByCustomers&Param1=${CustomerIdVal}&Param2=${ProductIdVal}`);
    if (res.status === 200) {
      if (res?.data) {
        let mList = res?.data //reduce((acc, e) => { acc.push({ Id: e.Id, WorkCenterId: e.WorkCenterId, WorkCenterName: e.WorkCenterName, Name: e.Name, Default: e.Default }); return acc }, []);
        //let sC = productList?.find(c => c.Id === (orderModel.ProductId  ? orderModel.ProductId : 103))
        intialProductFileList = mList;
        setProductFileList(mList);
      }
    }
  }


  const getOrderData = async () => {
    let res = await dataService('get', `OrderGet/${id || CopyOrderId}`, '');
    if (res.status === 200) {
      let OrderData = res?.data;
      if (OrderData) {
        OrderData.PhotosPageArry = OrderData?.PhotosPage ? OrderData?.PhotosPage?.split(',')?.map(Number) : [];
        OrderData.FittingNumberArry = OrderData?.FittingNumber ? OrderData?.FittingNumber?.split(',')?.map(Number) : [];
        setOrderModel(setOrderModelData({
          ...OrderData, Patta: OrderData?.ProductFilePatta || 0
        }));
        let productData = intialProductList?.find(c => Number(c.ProductId) === Number(OrderData.ProductId));
        setSelectedProduct(productData)
        let customerData = intialCustomerList?.find(c => Number(c.CustomerId) === Number(OrderData.CustomerId));
        setSelectedCustomer(customerData)

        let fileData = intialFileList?.find(c => Number(c.FileId) === Number(OrderData.FileId));
        setSelectedFile(fileData)

        await getProductFileList(OrderData?.CustomerId, OrderData?.ProductId);

        let productFileData = intialProductFileList?.find(c => Number(c.ProductFileId) === Number(OrderData.ProductFileId));
        setSelectedProductFile(productFileData)

      }
    }
  }

  const getPreOrderData = async () => {
    let res = await dataService('get', `PreOrderGet/${PreOrderId}`, '');
    if (res.status === 200) {
      let preOrderData = res?.data;
      if (preOrderData) {
        setOrderModel({
          ...orderModel,
          Client: preOrderData?.Logo,
          PBFull: preOrderData?.SKU,
          PBFullMtr: preOrderData?.MTR,
          TagRemarks: preOrderData?.TechnicalDetails,
          TagComposition: preOrderData?.Composition,
          TechnicalDetails: preOrderData?.TagRemarks,
          Composition: preOrderData?.Tagging,
          ProductId: 1,
          Quantity: preOrderData?.Quantity,
          TagWidth: preOrderData?.Width ? true : false,
          TagWidthValue: preOrderData?.Width,
          TaggingGSM: preOrderData?.Weight ? true : false,
          TaggingGSMValue: preOrderData?.Weight,
          TaggingRubs: preOrderData?.Rubs ? true : false,
          TaggingRubsValue: preOrderData?.Rubs,
          PBRemarks: "",
          TagInterlock: preOrderData?.Interlock ? true : false,
          TagZigZag: preOrderData?.ZigZag ? true : false,
          BindingsFoil: Boolean(preOrderData?.Foil),
          BindingsTagPrinted: Boolean(preOrderData?.Print),
          PhotosRemarks: preOrderData?.SpecialFeatures || "",
          CuttingRemarks: preOrderData?.Story || ""
        })
        let productData = intialProductList?.find(c => Number(c.ProductId) === Number(1));
        setSelectedProduct(productData)
        setIsCatelouge(Boolean(preOrderData?.ECatalogue))
        setPreOrderDetail(preOrderData)
      }
    }
  }

  const getGoodsEntryData = async () => {
    let res = await dataService('get', `GoodsEntryGet/${GoodsEntryId}`, '');
    if (res.status === 200) {
      const { GoodsEntryData, GoodsEntryFiles } = res?.data;
      console.log('GoodsEntryData: ', GoodsEntryData, GoodsEntryFiles);

      if (GoodsEntryData) {
        let GoodsEntryFileData = GoodsEntryFiles?.find(c => Number(c.GoodsEntryFileId) === Number(GoodsEntryFileId));
        setGoodsEntryDetail(GoodsEntryData);
        setGoodsEntryFilesDetail(GoodsEntryFileData);
        GoodsEntryData.Date = new Date();
        if (GoodsEntryFileData) {
          GoodsEntryData.Quantity = GoodsEntryFileData?.Quantity;
          GoodsEntryData.FileId = GoodsEntryFileData?.FileId;
          GoodsEntryData.Client = GoodsEntryFileData?.Client;
          GoodsEntryData.ProductId = GoodsEntryFileData?.ProductId;
          GoodsEntryData.ProductFileId = GoodsEntryFileData?.ProductFileId;
          GoodsEntryData.Patta = GoodsEntryFileData?.ProductFilePatta || 0;

          let fileData = intialFileList?.find(c => Number(c.FileId) === Number(GoodsEntryFileData?.FileId));
          setFileList([fileData]);
          setSelectedFile(fileData)
        }

        let productData = intialProductList?.find(c => Number(c.ProductId) === Number(GoodsEntryData.ProductId));
        setProductList([productData])
        setSelectedProduct(productData)

        let customerData = intialCustomerList?.find(c => Number(c.CustomerId) === Number(GoodsEntryData.CustomerId));
        setCustomerList([customerData]);
        setSelectedCustomer(customerData);



        setOrderModel(setOrderModelData({
          ...GoodsEntryData,
          BillingEmail: customerData?.BillingEmail,
          ShippingEmail: customerData?.ShippingEmail
        }));

        await getProductFileList(GoodsEntryData?.CustomerId, GoodsEntryData?.ProductId);

        let productFileData = intialProductFileList?.find(c => Number(c.ProductFileId) === Number(GoodsEntryData.ProductFileId));
        if (productFileData) {
          setProductFileList([productFileData])
          setSelectedProductFile(productFileData)
        }

      }
    }
  }

  const handleChange = (evt, type, checkValidation) => {
    setOrderModel({ ...orderModel, [evt.target.name]: evt.target.value });
    if (evt.target.name === 'PBHalfMtr' || evt.target.name === 'PBFullMtr' || evt.target.name === 'PBHalf' || evt.target.name === 'PBFull') {
      let PBHalfMtrVal = evt.target.name === 'PBHalfMtr' ? evt.target.value : orderModel?.PBHalfMtr || 0;
      let PBFullMtrVal = evt.target.name === 'PBFullMtr' ? evt.target.value : orderModel?.PBFullMtr || 0;
      let PBHalfVal = evt.target.name === 'PBHalf' ? evt.target.value : orderModel?.PBHalf || 0;
      let PBFullVal = evt.target.name === 'PBFull' ? evt.target.value : orderModel?.PBFull || 0;

      let PBTotalMtrVal = (parseFloat(PBFullVal) * parseFloat(PBFullMtrVal)) + (parseFloat(PBHalfVal) * parseFloat(PBHalfMtrVal));
      let PBTotal = parseFloat(PBFullVal) + parseFloat(PBHalfVal);
      setOrderModel({
        ...orderModel,
        [evt.target.name]: evt.target.value,
        PBTotal: PBTotal?.toFixed(2),
        PBTotalMtr: PBTotalMtrVal?.toFixed(2)
      });
    }
    if (checkValidation) {
      setOrderValidateModel({ ...orderValidateModel, [evt.target.name]: { message: checkEmptyValidation(evt.target.value, evt.target.name), touched: true } });
    }
  };

  const handleTopBottomChange = (id, value) => {
    const updateTopBottom = (fileSize, bindingsPatti, patta) => {
      const bindingsPattiValue = Number(bindingsPatti) || 0;
      const pattaValue = Number(patta) || 0;

      if (bindingsPattiValue === 0 || pattaValue === 0) {
        return { top: "0", bottom: "0" };
      }

      const fileSizeValues = fileSize.match(/\d+/g).slice(0, 2).map(Number);
      const [value1, value2] = fileSizeValues || [0, 0];

      const topLeft = (value1 * 25.40).toFixed(2);
      const topRight = ((value2 * 25.40) + bindingsPattiValue + 6.35).toFixed(2);
      const top = `${topLeft} X ${topRight}`;

      const bottomLeft = (value1 * 25.40).toFixed(2);
      const bottomRight = ((value2 * 25.40) + bindingsPattiValue + (pattaValue * 25.4)).toFixed(2);
      const bottom = `${bottomLeft} X ${bottomRight}`;

      return { top, bottom };
    };


    if (id === "ProductFileId") {
      if (value?.FileSize) {
        const fileSize = value.FileSize;
        const cuttingTukda = fileSize.split("X")[0];
        const cuttingPatta = fileSize.split("X").pop().replace(/\D+$/g, "");

        let top = null;
        let bottom = null;

        if (selectedProduct?.Name === "File") {
          const { top: calcTop, bottom: calcBottom } = updateTopBottom(
            fileSize,
            orderModel.BindingsPatti,
            Number(value?.Patta)
          );
          top = calcTop;
          bottom = calcBottom;
        }

        setOrderModel({
          ...orderModel,
          ProductFileId: value.ProductFileId,
          CuttingPatta: cuttingPatta,
          CuttingTukda: cuttingTukda,
          BindingsTop: top,
          BindingsBottom: bottom,
          Patta: Number(value?.Patta),
        });
      }

      setOrderValidateModel({
        ...orderValidateModel,
        ProductFileId: {
          message: checkEmptyValidation(value.ProductFileId, "ProductFileId"),
          touched: true,
        },
      });

      setSelectedProductFile(
        productFileList?.find(
          (c) => Number(c.ProductFileId) === Number(value.ProductFileId)
        )
      );
    } else if (["PBFull", "PBFGaze", "PBTGaze"].includes(id)) {
      const PBFullVal = id === "PBFull" ? Number(value) : orderModel.PBFull || 0;
      const PBFGazeVal = id === "PBFGaze" ? Number(value) : orderModel.PBFGaze || 0;
      const PBTGazeVal = id === "PBTGaze" ? Number(value) : orderModel.PBTGaze || 0;
      const totalGazeValue =
        parseFloat(PBFGazeVal) + parseFloat(PBTGazeVal);

      const bindingsKaan = (parseFloat(PBFullVal) * totalGazeValue + 9.85);
      const bindingsPatti = bindingsKaan ? (bindingsKaan + 6.35) : 0;

      let top = null;
      let bottom = null;

      if (
        selectedProduct?.Name === "File" &&
        selectedProductFile?.FileSize
      ) {
        const { top: calcTop, bottom: calcBottom } = updateTopBottom(
          selectedProductFile.FileSize,
          bindingsPatti.toFixed(2) || 0,
          orderModel?.Patta
        );
        top = calcTop;
        bottom = calcBottom;
      }

      setOrderModel({
        ...orderModel,
        [id]: value,
        BindingsKaan: bindingsKaan.toFixed(2) || 0,
        BindingsPatti: bindingsPatti.toFixed(2) || 0,
        BindingsTop: top,
        BindingsBottom: bottom,
      });
    } else if (id === "BindingsPatti") {
      let top = null;
      let bottom = null;

      if (
        selectedProduct?.Name === "File" &&
        selectedProductFile?.FileSize
      ) {
        const { top: calcTop, bottom: calcBottom } = updateTopBottom(
          selectedProductFile.FileSize,
          value,
          orderModel?.Patta
        );
        top = calcTop;
        bottom = calcBottom;
      }

      setOrderModel({
        ...orderModel,
        BindingsPatti: value,
        BindingsTop: top,
        BindingsBottom: bottom,
      });
    } else if (id === "Patta") {
      let top = null;
      let bottom = null;

      if (
        selectedProduct?.Name === "File" &&
        selectedProductFile?.FileSize
      ) {
        const { top: calcTop, bottom: calcBottom } = updateTopBottom(
          selectedProductFile.FileSize,
          orderModel.BindingsPatti,
          value
        );
        top = calcTop;
        bottom = calcBottom;
      }

      setOrderModel({
        ...orderModel,
        Patta: value,
        BindingsTop: top,
        BindingsBottom: bottom,
      });
    } else {
      setOrderModel({ ...orderModel, [id]: value });
    }
  };


  const handleSelectChange = (id, value) => {
    switch (id) {
      case 'Date':
        if (moment(value, "DD/MM/YYYY", true).isValid()) {
          setOrderModel({ ...orderModel, Date: value });
        }
        else {
          setOrderValidateModel({ ...orderValidateModel, 'Date': { message: 'invalid order date', touched: true } });
        }
        setOrderValidateModel({ ...orderValidateModel, 'Date': { message: checkEmptyValidation(value, 'Order Date'), touched: true } });
        break;
      case 'CustomerId':
        //setCustomerModel({ ...customerModel, BillingCountryId: countryList?.find(c => { return c.CountryId === value.CountryId })});
        setOrderModel({ ...orderModel, CustomerId: value.CustomerId, BillingEmail: value?.BillingEmail, ShippingEmail: value?.ShippingEmail });
        setOrderValidateModel({ ...orderValidateModel, 'CustomerId': { message: checkEmptyValidation(value.CustomerId, 'CustomerId'), touched: true } });
        setSelectedCustomer(customerList?.find(c => Number(c.CustomerId) === Number(value.CustomerId)))
        getProductFileList(value.CustomerId, orderModel?.ProductId);
        break;
      case 'ProductId':
        setOrderModel({ ...orderModel, ProductId: value.ProductId, Quantity: value.ProductId === 4 ? 1 : orderModel?.Quantity });
        setOrderValidateModel({ ...orderValidateModel, 'ProductId': { message: checkEmptyValidation(value.ProductId, 'ProductId'), touched: true } });
        setSelectedProduct(productList?.find(c => Number(c.ProductId) === Number(value.ProductId)))
        getProductFileList(orderModel?.CustomerId, value?.ProductId);
        break;
      case 'FileId':
        setOrderModel({ ...orderModel, FileId: value.FileId });
        setOrderValidateModel({ ...orderValidateModel, 'FileId': { message: checkEmptyValidation(value.FileId, 'FileId'), touched: true } });
        break;
      case 'CuttingTable':
        setOrderModel({ ...orderModel, CuttingTable: value });
        break;
      case 'CuttingMainTable':
        setOrderModel({ ...orderModel, CuttingMainTable: value });
        break;
      default:
        setOrderModel({ ...orderModel });
        break;

    }
  }

  const handleCheckBoxes = (evt) => {
    setOrderModel({ ...orderModel, [evt.target.name]: evt.target.checked });
  }

  const handleMultiCheckBoxes = (evt) => {
    switch (evt.target.name) {
      case 'PhotosPage':
        let listPhotosPage = orderModel?.PhotosPageArry;
        if (evt.target.checked)
          listPhotosPage.push(parseInt(evt.target.value));
        else
          listPhotosPage = listPhotosPage.filter(item => item !== parseInt(evt.target.value));

        setOrderModel({ ...orderModel, PhotosPageArry: [...new Set(listPhotosPage)] });
        break;
      case 'FittingNumber':
        let listFittingNumber = orderModel?.FittingNumberArry;
        if (evt.target.checked)
          listFittingNumber.push(parseInt(evt.target.value));
        else
          listFittingNumber = listFittingNumber.filter(item => item !== parseInt(evt.target.value));
        setOrderModel({ ...orderModel, FittingNumberArry: [...new Set(listFittingNumber)] });
        break;
      default:
        setOrderModel({ ...orderModel });
        break;

    }

  }

  const getMaterialFindByRequiiredFile = async (productId, productFileId) => {

    try {
      let res = await dataService('get', 'GetMaterialFindByRequiiredFile', `ProductId=${productId}&ProductFileId=${productFileId}`);
      if (res.status === 200) {
        return res?.data;
      }
    } catch (error) {
      return null
    }
  }
  const handleShowOrderInventory = async () => {
    const result = await getMaterialFindByRequiiredFile(orderModel?.ProductId, orderModel?.ProductFileId);
    setIsOpenInventoryDialog({
      isVisible: true,
      data: {
        quantity: orderModel.Quantity,
        fileName: fileList?.find((ele) => Number(ele?.FileId) === Number(orderModel?.FileId))?.Name,
        fileSize: productFileList?.find((ele) => Number(ele?.ProductFileId) === Number(orderModel?.ProductFileId))?.FileSize,
        productName: productList?.find((ele) => Number(ele?.ProductId) === Number(orderModel?.ProductId))?.Name,
      },
      materialList: result
    });
  }

  const handleSubmitOrder = async () => {
    if (!checkValidations()) {
      if (orderModel?.ProductId !== 4 && isEcatelouge && !eCateLougeValue.Remarks && !eCateLougeValue.UnitPrice) {
        setIsCatelougeModel(true)
      }
      else {
        setShowSubmitLoading(true);
        let reqData = {
          ...orderModel, BindingsTop: selectedProduct?.Name === "File" ? orderModel?.BindingsTop : null,
          BindingsBottom: selectedProduct?.Name === "File" ? orderModel?.BindingsBottom : null,
          PreviousQuantity: orderModel?.PreviousQuantity || 0
        };

        reqData.PhotosPage = orderModel?.PhotosPageArry?.sort()?.join();
        reqData.FittingNumber = orderModel?.FittingNumberArry?.sort()?.join();
        let res = await dataService('post', `OrderSave/${id ? id : 0}`, reqData);

        if (isEcatelouge && orderModel?.ProductId !== 4) {
          let eCateLouge = {
            ProductId: orderModel ? 4 : null,
            ECataloguePhotoShoot: true,
            ECatalogueFabricLayout: true,
            ECatalogueColorMatching: true,
            ECatalogueApproval: true,
            ECatalogueMail: true,
            ECatalogueDelivery: true,
            CustomerId: orderModel ? orderModel?.CustomerId : null,
            Client: orderModel ? orderModel?.Client : null,
            Date: orderModel ? orderModel?.Date : null,
            ProductFileId: orderModel ? orderModel?.ProductFileId : null,
            FileId: orderModel ? orderModel?.FileId : null,
            Quantity: orderModel ? 1 : null,
            IsRepeat: orderModel ? orderModel?.IsRepeat : null,
            RepeatOrderId: orderModel ? orderModel?.RepeatOrderId : null,
            UnitPrice: orderModel ? eCateLougeValue?.UnitPrice : null,
            Parcel: orderModel ? orderModel?.Parcel : null,
            Loose: orderModel ? orderModel?.Loose : null,
            ChallanSequence1: orderModel ? orderModel?.ChallanSequence1 : null,
            ChallanSequence3: orderModel ? orderModel?.ChallanSequence3 : null,
            Remarks: orderModel ? eCateLougeValue?.Remarks : null,
            BillingEmail: orderModel ? orderModel?.BillingEmail : null,
            ShippingEmail: orderModel ? orderModel?.ShippingEmail : null
          }


          await dataService('post', `OrderSave/0`, eCateLouge);
        }
        if (GoodsEntryId) {
          if (res && (res.status === 200 || res.status === 304)) {
            let mapOrderRes = await handleMapOrderDialog(res?.data);
            console.log('mapOrderRes: ', mapOrderRes);
          }
        }
        if (preOrderDetail && preOrderDetail?.Status !== "Approved") {
          if (res && (res.status === 200 || res.status === 304)) {
            try {
              let res = await dataService("post", `PreOrderStatusUpdate/${preOrderDetail?.PreOrderId}`, {
                Status: "Approved",
              });
            } catch (error) {
              console.log("Error in updating status", error);
            }
          }
        }
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
        setShowSubmitLoading(false);
        if (res && (res.status === 200 || res.status === 304)) {
          setMessageDialoge({ show: true, type: 'success', message: id ? 'Order updated successfully!' : 'Order registered successfully!' });
          if (!id) {
            setTimeout(() => {
              //history.push('/orders')
              history.push('/admin/orders/running')
            }, 500);
          }

        } else {
          //setOpen(res.message);
          setMessageDialoge({ show: true, type: 'error', message: res.message });
        }
      }
    }

  }


  const checkValidations = () => {

    let validateErrors = {
      ProductId: { message: checkEmptyValidation(orderModel.ProductId === null ? '' : orderModel.ProductId, 'Product'), touched: true },
      CustomerId: { message: checkEmptyValidation(orderModel.CustomerId === null ? '' : orderModel.CustomerId, 'Customer'), touched: true },
      Date: { message: checkEmptyValidation(orderModel.Date === null ? '' : orderModel.Date, 'Order Date'), touched: true },
      ProductFileId: { message: checkEmptyValidation(orderModel.ProductFileId === null ? '' : orderModel.ProductFileId, 'File Size'), touched: true },
      Quantity: { message: checkEmptyValidation(orderModel.Quantity === null ? '' : orderModel.Quantity, 'Quantity'), touched: true },
      UnitPrice: { message: checkEmptyValidation(orderModel.UnitPrice === null ? '' : orderModel.UnitPrice, 'Price'), touched: true }

    }
    if (validateErrors.ProductId.message !== '' ||
      validateErrors.CustomerId.message !== '' ||
      validateErrors.Date.message !== '' ||
      validateErrors.ProductFileId.message !== '' ||
      validateErrors.Quantity.message !== '' ||
      validateErrors.UnitPrice.message !== '') {

      validateErrors.IsValidationError = true;

    }
    else {
      validateErrors.IsValidationError = false;
    }
    console.log('validateErrors: ', validateErrors)
    setOrderValidateModel(validateErrors);
    return validateErrors.IsValidationError;

  }

  // Generate order with goodsentry map.
  const handleMapOrderDialog = async (generatedOrder) => {

    //FileId
    //GoodsEntryFileId
    //OrderId
    //GoodsEntryId

    let reqData = {
      MappingList: [{
        FileId: goodsEntryFilesDetail?.FileId,
        GoodsEntryFileId: parseInt(GoodsEntryFileId), //goodsEntryFilesDetail?.GoodsEntryFileId,
        OrderId: generatedOrder?.OrderId,
        GoodsEntryId: parseInt(GoodsEntryId) //goodsEntryDetail?.GoodsEntryId
      }]
    };
    let res = await dataService('post', `GoodsEntryOrdersMapping`, reqData);

    return res;
  };

  const handleViewPreOrder = () => {
    setShowPreOrder(true);
  };

  const renderSection = () => {
    switch (selectedProduct?.Name) {
      case 'Header':
        return <OrderHeader orderModel={orderModel} handleChange={handleChange} handleSelectChange={handleSelectChange} handleTopBottomChange={handleTopBottomChange} handleCheckBoxes={handleCheckBoxes} handleMultiCheckBoxes={handleMultiCheckBoxes} selectedProductFile={selectedProductFile} />;
      case 'Shade':
        return <OrderShadeCard orderModel={orderModel} handleChange={handleChange} handleSelectChange={handleSelectChange} handleCheckBoxes={handleCheckBoxes} handleMultiCheckBoxes={handleMultiCheckBoxes} selectedProductFile={selectedProductFile} />;
      case 'E-Catalogue':
        return <OrderECatalogue orderModel={orderModel} handleChange={handleChange} handleSelectChange={handleSelectChange} handleCheckBoxes={handleCheckBoxes} handleMultiCheckBoxes={handleMultiCheckBoxes} selectedProductFile={selectedProductFile} />;
      case 'File':
        return <OrderFile orderModel={orderModel} handleChange={handleChange} handleSelectChange={handleSelectChange} handleTopBottomChange={handleTopBottomChange} handleCheckBoxes={handleCheckBoxes} handleMultiCheckBoxes={handleMultiCheckBoxes} selectedProductFile={selectedProductFile} />;
      default:
        return <></>
    }
  }

  const saveFile = async (fileName) => {
    try {
      const response = await dataService('post', `FileSave/${0}`, { Name: fileName });
      fetchFileListData(fileName);
      return response.data.FileId;
    } catch (error) {
      console.error("Error saving file:", error);
      return null;
    }
  };

  const handleFileChange = async (event, newValue) => {
    if (newValue && newValue.FileId) {
      setSelectedFile(newValue);
      fetchFileListData(newValue.Name);
      handleSelectChange('FileId', newValue);
    } else if (newValue && newValue.inputValue) {
      const fileId = await saveFile(newValue.inputValue);
      if (fileId) {
        const newFile = { FileId: fileId, Name: newValue.inputValue };
        handleSelectChange('FileId', newFile);
        setSelectedFile(newFile);
      } else {
        console.error("Failed to save the file.");
      }
    } else {
      setSelectedFile(null);
      setFileNamesList([]);
      handleSelectChange('FileId', { FileId: null });
    }
  };

  const commonFormControlLabelSx = {
    '& .MuiFormControlLabel-label': {
      fontSize: {
        xs: '12px !important',
        sm: "14px !important"
      },
    },
  };


  return (
    <>
      <Header />
      {/* Page content */}
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      // paddingTop="10rem"
      // paddingBottom="3rem"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Card
              // className={`bg-${ProductBGColors[selectedProduct?.Name]}`}
              classes={{
                root: classes.cardRoot + " " + classes.cardRootSecondary,
              }}
              sx={{
                height: '100%',
                overflow: 'visible',
              }}
            >
              <CardHeader
                subheader={
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        marginBottom="0!important"
                      >
                        {PageTitle}
                      </Box>
                    </Grid>
                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h3"
                        marginBottom="0!important"
                      >
                        {goodsEntryDetail && GoodsEntryId && GoodsEntryFileId &&
                          <div>
                            <Typography className="text-base text-blue-800">
                              Map with Goods Entry - <strong>({goodsEntryDetail?.GoodsEntryNumber})</strong>
                            </Typography>
                            <Typography className="text-base text-blue-800">
                              File Name - <strong>({goodsEntryFilesDetail?.Name})</strong>
                            </Typography>
                          </div>
                        }
                        {preOrderDetail && (
                          <div className="flex flex-row gap-2 mr-4">
                            <div>
                              <Typography className="text-base text-blue-800">
                                Map with Pre Order - <strong>({preOrderDetail?.PreOrderId})</strong>
                              </Typography>
                              <Typography className="text-base text-blue-800">
                                Collection Name - <strong>({preOrderDetail?.CollectionName})</strong>
                              </Typography>
                            </div>
                          </div>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                }
                classes={{ root: classes.cardHeaderRoot }}
              ></CardHeader>
              <CardContent sx={theme => ({ p: "1.5rem", [theme.breakpoints.down("sm")]: { p: "0.5rem" } })}>
                <Grid item xs={12} lg={12}>
                  <MessageBar show={messageDialoge.show} message={messageDialoge.message} type={messageDialoge.type} />
                </Grid>
                <Box
                  component={Typography}
                  variant="h6"
                  color={theme.palette.gray[600] + "!important"}
                  paddingTop=".25rem"
                  paddingBottom=".25rem"
                  fontSize=".75rem!important"
                  letterSpacing=".04em"
                  marginBottom="1.5rem!important"
                  display="flex"
                  alignItems="center"
                  classes={{ root: classes.typographyRootH6 }}
                >
                  <span> Order Information </span> {showOrderLoading && <CircularProgress size={24} className="ml-2 w-4 h-4" />}
                </Box>
                <div >
                  <div className="px-3 xs:px-4 xs:py-2">
                    <Grid container spacing={isSmallScreen ? 0.5 : 1}>
                      <Grid item xs={12} md={6} lg={3} xl={2} pr={1} pl={1}>
                        <Stack spacing={0.5}>
                          <InputLabel className="text-xs sm:text-sm" htmlFor="selectedProduct">Product</InputLabel>
                          {productList?.length > 0 &&
                            <Autocomplete
                              id="order-page-ProductId"
                              value={selectedProduct}
                              //value={productList?.find(c => c.ProductId === orderModel.ProductId)}
                              //defaultValue={productList?.length > 0 ? productList[0] : null}
                              options={productList}
                              onChange={(event, newValue) => {
                                newValue = newValue || { ProductId: null };
                                handleSelectChange('ProductId', newValue)
                              }}
                              autoHighlight
                              getOptionLabel={(option) => option.Name}
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  height: '35px',
                                },
                                '& .MuiAutocomplete-input': {
                                  borderRight: '0px',
                                  fontSize: isSmallScreen ? "12px" : "14px"
                                },
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  placeholder="Select Product"
                                  autoComplete="new-password"
                                  error={orderValidateModel.ProductId.message !== '' && orderValidateModel.ProductId.touched}
                                  helperText={orderValidateModel.ProductId.message !== '' && orderValidateModel.ProductId.touched ? orderValidateModel.ProductId.message : ''}

                                />
                              )}
                            />
                          }
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6} lg={9} xl={4} pr={1} pl={1}>
                        <Stack spacing={0.5}>
                          <InputLabel className="text-xs sm:text-sm" htmlFor="selectedCustomer">Customer</InputLabel>
                          {customerList?.length > 0 &&
                            <Autocomplete
                              id="order-page-CustomerId"
                              value={selectedCustomer}
                              //value={customerList?.find(c => c.CustomerId === orderModel.CustomerId)}
                              //defaultValue={customerList?.find(c => c.CustomerId === orderModel.CustomerId)}
                              options={customerList}
                              onChange={(event, newValue) => {
                                newValue = newValue || { CustomerId: null };
                                handleSelectChange('CustomerId', newValue)
                              }}
                              autoHighlight
                              getOptionLabel={(option) => option.Name}
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  height: '35px',
                                },
                                '& .MuiAutocomplete-input': {
                                  borderRight: '0px',
                                  fontSize: isSmallScreen ? "12px" : "14px"
                                },
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  placeholder="Select Customer"
                                  autoComplete="new-password"
                                  error={orderValidateModel.CustomerId.message !== '' && orderValidateModel.CustomerId.touched}
                                  helperText={orderValidateModel.CustomerId.message !== '' && orderValidateModel.CustomerId.touched ? orderValidateModel.CustomerId.message : ''}
                                />
                              )}
                            />

                          }
                          {preOrderDetail?.CustomerName &&
                            <Typography className="text-black font-semibold text-sm mt-1">
                              Customer - <strong className="text-green-500">({preOrderDetail?.CustomerName})</strong>
                            </Typography>
                          }

                        </Stack>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={4} xl={3.5} pr={1} pl={1}>
                        <Stack spacing={0.5}>
                          <InputLabel className="text-xs sm:text-sm" htmlFor="Client">Client</InputLabel>
                          <TextField
                            variant="filled"
                            name="Client"
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            value={orderModel.Client}
                            onChange={e => handleChange(e)}
                            sx={{
                              '& .MuiFilledInput-input': {
                                height: 35,
                                fontSize: isSmallScreen ? "12px" : "14px"
                              },
                            }}
                            size="small"
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={4} xl={2.5} pr={1} pl={1}>
                        <Stack spacing={0.5}>
                          <InputLabel className="text-xs sm:text-sm" htmlFor="Date">Order Date</InputLabel>
                          <div className="bg-white custom-datepicker">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                className="w-full"
                                name="Date"
                                format="dd/MM/yyyy"
                                value={orderModel.Date ? new Date(orderModel.Date) : null}
                                onChange={e => handleSelectChange('Date', e)}
                                sx={{
                                  '& .MuiOutlinedInput-input': {
                                    height: 35,
                                  },
                                }}
                                size="small"
                              />
                            </LocalizationProvider>
                          </div>
                        </Stack>
                        <FormHelperText className="text-red-500">{orderValidateModel.Date.message !== '' && orderValidateModel.Date.touched ? orderValidateModel.Date.message : ''}</FormHelperText>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={4} xl={2} pr={1} pl={1}>
                        <Stack spacing={0.5}>
                          <InputLabel className="text-xs sm:text-sm" htmlFor="Quantity">Quantity</InputLabel>
                          <TextField
                            variant="filled"
                            disabled={orderModel?.ProductId === 4 || isEcatelouge}
                            name="Quantity"
                            type="number"
                            width="100%"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            value={orderModel.Quantity}
                            onChange={e => handleChange(e, null, true)}
                            error={orderValidateModel.Quantity.message !== '' && orderValidateModel.Quantity.touched}
                            helperText={orderValidateModel.Quantity.message !== '' && orderValidateModel.Quantity.touched ? orderValidateModel.Quantity.message : ''}
                            sx={{
                              '& .MuiFilledInput-input': {
                                height: 35,
                                fontSize: isSmallScreen ? "12px" : "14px"
                              },
                              '& input[type=number]::-webkit-outer-spin-button': {
                                WebkitAppearance: 'none'
                              },
                              '& input[type=number]::-webkit-inner-spin-button': {
                                WebkitAppearance: 'none'
                              },
                            }}
                            inputProps={{ min: 0 }}
                            size="small"
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="text"
                            />
                          </TextField>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={4} xl={4} pr={1} pl={1}>
                        <Stack spacing={0.5}>
                          <InputLabel className="text-xs sm:text-sm" htmlFor="selectedFile">File Name</InputLabel>
                          {fileList?.length > 0 &&
                             <Autocomplete
                             classes={{
                               inputRoot: classes.inputRoot,
                             }}
                             loading={fileLoading}
                             filterSelectedOptions
                             id="tags-standard"
                             options={FileNamesList}
                             value={selectedFile}
                             onChange={handleFileChange}
                             filterOptions={(options, params) => {
                               const filtered = filter(options, params);
                               if (params.inputValue !== '') {
                                 filtered.push({
                                   inputValue: params.inputValue,
                                   Name: params.inputValue
                                 });
                               }
                               return filtered;
                             }}
                             sx={{
                              '& .MuiOutlinedInput-root': {
                                height: '35px',
                              },
                              '& .MuiAutocomplete-input': {
                                borderRight: '0px',
                                fontSize: isSmallScreen ? "12px" : "14px"
                              },
                              border: "none"
                            }}
                             isOptionEqualToValue={(option, value) => Number(option.FileId) === Number(value.FileId)}
                             getOptionLabel={(option) => option.Name}
                             className="w-full flex items-center"
                             renderInput={(params) => (
                               <TextField
                                 {...params}
                                 onChange={handleTextChange}
                                 variant="outlined"
                                 InputProps={{
                                   ...params.InputProps,
                                   endAdornment: (
                                     <React.Fragment>
                                       {fileLoading ? <CircularProgress sx={{ marginRight: 5 }} color="inherit" size={20} /> : null}
                                       {params.InputProps.endAdornment}
                                     </React.Fragment>
                                   )
                                 }}
                               />
                             )}
                           />
                          }
                               {preOrderDetail?.CollectionName &&
                            <Typography className="text-black font-semibold text-sm mt-1">
                              Collection Name - <strong className="text-green-500">({preOrderDetail?.CollectionName})</strong>
                            </Typography>
                          }
                        </Stack>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={4} xl={4} pr={1} pl={1}>
                        <Stack spacing={0.5}>
                          <InputLabel className="text-xs sm:text-sm" htmlFor="selectedProductFile">File Size</InputLabel>
                          <Autocomplete
                            id="order-page-ProductFileId"
                            value={selectedProductFile}
                            //value={productFileList?.find(c => c.ProductFileId === orderModel.ProductFileId)}
                            //defaultValue={productFileList?.find(c => c.ProductFileId === orderModel.ProductFileId)}
                            options={productFileList}
                            onChange={(event, newValue) => {
                              newValue = newValue || { ProductFileId: null };
                              handleTopBottomChange('ProductFileId', newValue)
                            }}
                            autoHighlight
                            getOptionLabel={(option) => option.FileSize}
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                height: '35px',
                              },
                              '& .MuiAutocomplete-input': {
                                borderRight: '0px',
                                fontSize: isSmallScreen ? "12px" : "14px"
                              },
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Select File Size"
                                autoComplete="new-password"
                                error={orderValidateModel.ProductFileId.message !== '' && orderValidateModel.ProductFileId.touched}
                                helperText={orderValidateModel.ProductFileId.message !== '' && orderValidateModel.ProductFileId.touched ? orderValidateModel.ProductFileId.message : ''}
                              />
                            )}
                          />
                        </Stack>
                      </Grid>
                      {selectedProduct?.Name === "File" &&
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={2}>
                          <Stack spacing={0.5}>
                            <InputLabel className="text-xs sm:text-sm" htmlFor="Patta">Patta</InputLabel>
                            <TextField
                              disabled
                              variant="filled"
                              name="Patta"
                              component={Box}
                              type="number"
                              width="100%"
                              marginBottom="1rem!important"
                              autoComplete="off"
                              value={orderModel.Patta}
                              onChange={e => handleTopBottomChange("Patta", e.target.value)}
                              sx={{
                                '& .MuiFilledInput-input': {
                                  height: 35,
                                  fontSize: isSmallScreen ? "12px" : "14px"
                                },
                                '& input[type=number]::-webkit-outer-spin-button': {
                                  WebkitAppearance: 'none'
                                },
                                '& input[type=number]::-webkit-inner-spin-button': {
                                  WebkitAppearance: 'none'
                                },
                              }}
                              inputProps={{ min: 0 }}
                              size="small"
                            >
                              <Box
                                paddingLeft="0.75rem"
                                paddingRight="0.75rem"
                                component={FilledInput}
                                autoComplete="off"
                                type="text"
                              />
                            </TextField>
                          </Stack>
                        </Grid> 
                      }
                      </Grid>
                      <Grid container spacing={isSmallScreen ? 0.5 : 1}>
                      <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
                        <FormControlLabel
                          className="ml-2"
                          control={
                            <Checkbox
                              checked={orderModel?.IsRepeat}
                              onChange={handleCheckBoxes}
                              name="IsRepeat"
                              color="primary"
                            />
                          }
                          label="Repeat?"
                          sx={commonFormControlLabelSx}
                        />
                      </Grid>
                      {orderModel?.ProductId !== 4 &&
                          <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                          <FormControlLabel
                            className="ml-2"
                            control={
                              <Checkbox
                                checked={isEcatelouge}
                                onChange={(e) => setIsCatelouge(e.target.checked)}
                                name="isEcatelouge"
                                color="primary"
                              />
                            }
                            label="E-catelouge"
                            sx={commonFormControlLabelSx}
                          />
                        </Grid>
                      }
                    </Grid>
                    {orderModel?.ProductId && orderModel?.ProductFileId && orderModel.Quantity ?
                      <Box className="flex justify-end">
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={handleShowOrderInventory}
                          className="bg-[#5e72e4] border-[#5e72e4]"
                        >
                          Show Order Inventory
                        </Button>
                      </Box>
                      : null}

                  </div>
                  {/* Basic Order Information End */}
                  <Box
                    component={Divider}
                    marginBottom="1.5rem!important"
                    marginTop="1.5rem!important"
                  />
                  {renderSection()}
                  {/* {selectedProduct?.Name === 'Header' ?  
                    (<OrderHeader orderModel={orderModel} handleChange={handleChange} handleSelectChange={handleSelectChange} handleCheckBoxes={handleCheckBoxes} handleMultiCheckBoxes={handleMultiCheckBoxes} />)
                    :
                    (<OrderFile orderModel={orderModel} handleChange={handleChange} handleSelectChange={handleSelectChange} handleCheckBoxes={handleCheckBoxes} handleMultiCheckBoxes={handleMultiCheckBoxes} />)
                  } */}

                  {/* Extras Information Start */}

                  <div className="border-2 border-gray-200 rounded-md mb-6" >
                    <div className="bg-[#e2e4e9] px-4 py-2 border-b sm:px-6">
                      <Typography variant="h3" marginBottom="0!important">Extra</Typography>
                    </div>
                    <div className="py-5 px-4">
                      <Grid container spacing={0.5}>
                        <Grid item xs={12}>
                          <div style={{ display: 'flex', alignItems: 'center', marginRight: '1rem', flexWrap: 'wrap' }}>
                            <Typography className="font-semibold " style={{ marginRight: '0.75rem' }}>Parcel Type:</Typography>
                            <FormGroup style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: "0rem" }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={orderModel?.Parcel}
                                    onChange={handleCheckBoxes}
                                    name="Parcel"
                                    color="primary"
                                  />
                                }
                                label="Parcel"
                                sx={commonFormControlLabelSx}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={orderModel?.Loose}
                                    onChange={handleCheckBoxes}
                                    name="Loose"
                                    color="primary"
                                  />
                                }
                                label="Loose"
                                sx={commonFormControlLabelSx}
                              />
                            </FormGroup>
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <div style={{ display: 'flex', justifyContent: "flex-start", alignItems: 'center', marginRight: '1rem', flexWrap: 'wrap' }}>
                            <Typography className="font-semibold" style={{ marginRight: '0.75rem' }}>Chalan Sequence:</Typography>
                            <FormGroup style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: "0rem" }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={orderModel?.ChallanSequence1}
                                    onChange={handleCheckBoxes}
                                    name="ChallanSequence1"
                                    color="primary"
                                  />
                                }
                                label="1"
                                sx={commonFormControlLabelSx}
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={orderModel?.ChallanSequence3}
                                    onChange={handleCheckBoxes}
                                    name="ChallanSequence3"
                                    color="primary"
                                  />
                                }
                                label="3"
                                sx={commonFormControlLabelSx}
                              />
                            </FormGroup>
                          </div>
                        </Grid>
                      </Grid>

                    </div>



                  </div>
                  {/* Extras Information End */}

                  {/* Remarks Information Start */}
                  <div className="border-2 border-gray-200 rounded-md mb-6" >
                    <div className="bg-[#e2e4e9] px-4 py-2 border-b sm:px-6">
                      <Typography variant="h3" marginBottom="0!important">Remarks</Typography>
                    </div>
                    <div className="py-3 px-4">
                      <Grid container>
                        <Grid item xs={12} lg={12}>
                          <Stack>
                            <InputLabel className="text-xs sm:text-sm" htmlFor="Remarks">Remarks</InputLabel>
                            <TextField
                              variant="filled"
                              component={Box}
                              width="100%"
                              marginBottom="1rem!important"
                              autoComplete="off"
                              name="Remarks"
                              value={orderModel?.Remarks}
                              multiline
                              minRows={4}
                              maxRows={6}
                              onChange={e => handleChange(e)}
                              sx={{
                                '& .MuiFilledInput-input': {
                                  height: 35,
                                  fontSize: isSmallScreen ? "12px" : "14px",
                                  border: 'none',
                                  padding: '0px'

                                },
                                '& .MuiFilledInput-root': {
                                  border: '1px solid #cad1d7 !important',
                                  borderRadius: '8px',
                                },
                                marginBottom: '1rem!important',
                              }}
                              size="small"
                            >
                              <Box
                                paddingLeft="0.75rem"
                                paddingRight="0.75rem"
                                component={FilledInput}
                                autoComplete="off"
                                type="text"
                              />
                            </TextField>
                          </Stack>
                        </Grid>
                      </Grid>
                    </div>


                  </div>


                  {/* Unit Price Information Start */}
                  <div className="border-2 border-gray-200 rounded-md mb-6" >
                    <div className="bg-[#e2e4e9] px-4 py-2 border-b sm:px-6">
                      <Typography variant="h3" marginBottom="0!important">Unit Price</Typography>
                    </div>
                    <div className="py-3 px-4">
                      <Grid container>
                        <Grid item xs={12} lg={3} pl={1} pr={1}>
                          <InputLabel className="text-xs sm:text-sm" htmlFor="UnitPrice">Price</InputLabel>
                          <TextField
                            variant="filled"
                            marginBottom="1rem!important"
                            autoComplete="off"
                            name="UnitPrice"
                            type="number"
                            value={orderModel?.UnitPrice}
                            onChange={e => handleChange(e, null, true)}
                            error={orderValidateModel.UnitPrice.message !== '' && orderValidateModel.UnitPrice.touched}
                            helperText={orderValidateModel.UnitPrice.message !== '' && orderValidateModel.UnitPrice.touched ? orderValidateModel.UnitPrice.message : ''}
                            sx={{
                              '& input[type=number]::-webkit-outer-spin-button': {
                                WebkitAppearance: 'none'
                              },
                              '& input[type=number]::-webkit-inner-spin-button': {
                                WebkitAppearance: 'none'
                              },
                              '& .MuiFilledInput-input': {
                                height: 35,
                                fontSize: isSmallScreen ? "12px" : "14px"
                              },
                              marginBottom: '1rem!important',
                            }}
                            inputProps={{ min: 0 }}
                            size="small"
                          >
                            <Box
                              paddingLeft="0.75rem"
                              paddingRight="0.75rem"
                              component={FilledInput}
                              autoComplete="off"
                              type="number"
                            />
                          </TextField>
                        </Grid>
                      </Grid>
                    </div>


                  </div>
                  <Grid container>
                    <Grid item xs={12} lg={12}>
                      <Box
                        justifyContent="center"
                        display="flex"
                        flexWrap="wrap"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          onClick={handleSubmitOrder}
                          disabled={showSubmitLoading}
                          className="bg-[#5e72e4] border-[#5e72e4]"
                        >
                          {GoodsEntryId && GoodsEntryFileId && goodsEntryDetail ? ("Generate Order & Map") : ("Generate Order")}
                          {showSubmitLoading && <CircularProgress size={24} className="text-white mt-0.50 ml-2 w-4 h-4" />}
                        </Button>
                      </Box>
                    </Grid>
                    {goodsEntryDetail && GoodsEntryId && GoodsEntryFileId &&
                      <Grid item xs={12} lg={12}>
                        <Box
                          justifyContent="center"
                          display="flex"
                          flexWrap="wrap"
                        >
                          <Typography className="mt-2 text-sm text-blue-800">
                            Map with Goods Entry - <strong>({goodsEntryDetail?.GoodsEntryNumber})</strong>
                          </Typography>
                        </Box>
                      </Grid>
                    }
                  </Grid>

                </div>
              </CardContent>
            </Card>

            <Drawer
              anchor="right"
              open={showPreOrder}
              onClose={() => setShowPreOrder(false)}
              PaperProps={{
                sx: {
                  width: '100%',
                  maxWidth: '500px',
                  borderTopLeftRadius: 16,
                  borderBottomLeftRadius: 16,
                  mt: isTablet ? '4rem' : 0
                }
              }}
            >
              <PreOrderView preOrderDetail={preOrderDetail} onClose={() => setShowPreOrder(false)} />
            </Drawer>

          </Grid >
        </Grid >
      </Container >

      {preOrderDetail && (
        <IconButton
          onMouseEnter={() => setShowPreOrder(true)}
          onClick={handleViewPreOrder}
          sx={{
            width: 35,
            height: 35,
            position: 'fixed',
            backgroundColor: '#fff',
            top: 115,
            right: 20,
            zIndex: 1000,
            border: '1px solid #00000040',
            borderRadius: '50%'
          }}
        >
          <KeyboardArrowLeft
            sx={{
              width: '1.8rem',
              height: '1.8rem',
              color: 'black',
              transition: 'all 0.3s ease',
            }}
          />
        </IconButton>
      )}

      <Drawer
        anchor="right"
        hideBackdrop={true}
        open={showPreOrder}
        onClose={() => setShowPreOrder(false)}
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '500px',
            borderTopLeftRadius: 16,
            borderBottomLeftRadius: 16,
            mt: isTablet ? '4rem' : 0
          }
        }}
      >
        <PreOrderView preOrderDetail={preOrderDetail} onClose={() => setShowPreOrder(false)} />
      </Drawer>
      {
        id && openChangePasswordDialog &&
        <ChangePasswordDialog title="Change Password" open={openChangePasswordDialog} setOpen={setOpenChangePasswordDialog} OrderId={id} orderType="other" />
      }
      {
        isEcatelougeModel && <CatelougeDialog open={isEcatelougeModel} setOpen={setIsCatelougeModel} orderModel={eCateLougeValue} setOrderModel={seteCateLougeValue} handleSubmitOrder={handleSubmitOrder} />
      }
      {
        <ViewInventoryDialog onClose={() => {
          setIsOpenInventoryDialog({
            open: false,
            data: null
          });
        }} open={isOpenInventoryDialog} />
      }
      {/* {mappingData && openMaporder && 
            
            <MapOrderDialog from={'orders'} mapTo={'GoodsEntry'} open={openMaporder} onClose={setOpenMaporder} MappingData={mappingData} />
        } */}

    </>
  );
}

export default AddOrder;