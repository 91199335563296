import React from 'react';
import {
  Box,
  Grid,
  Typography,
  Checkbox,
  IconButton,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { ReadOnlyInput } from 'components/dialoges/ViewOrders/CommonOrderView';

const CommonPreOrderView = ({ 
  preOrderDetail, 
  onClose, 
  showHeader = true,
  spacing = 1,
  headerComponent ,
  isPreOrder = false
}) => {
  return (
    <Box
      sx={{
        p: { xs: 2, md: 3 },
        height: '100%',
        overflow: 'auto'
      }}
      onMouseLeave={isPreOrder ? undefined : onClose}
    >
      {showHeader && (
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          {headerComponent || (
            <>
              <Typography variant="h2" fontWeight="bold">
                Pre Order Details
              </Typography>
              <IconButton onClick={onClose} sx={{ color: 'text.secondary' }}>
                <Close sx={{ width: 20, height: 20 }} />
              </IconButton>
            </>
          )}
        </Box>
      )}

      <div className="border-2 border-gray-300 rounded-md mb-6">
        <div className="bg-gray-300 px-4 py-2 border-b sm:px-6">
          <h3 className="text-lg text-center font-bold leading-6 text-gray-900">
            General Information
          </h3>
        </div>
        <div className="px-6 py-4">
          <Grid container spacing={spacing}>
            <ReadOnlyInput label="Customer" value={preOrderDetail?.CustomerName} sm={isPreOrder ? 6 : 12} lg={isPreOrder ? 6 : 12} xs={12} />
            <ReadOnlyInput label="Collection" value={preOrderDetail?.CollectionName} sm={isPreOrder ? 6 : 12} lg={isPreOrder ? 6 : 12} xs={12} />
            <ReadOnlyInput label="Logo" value={preOrderDetail?.Logo} xs={12} lg={isPreOrder ? 4 : 12} />
            <ReadOnlyInput label="Quantity" value={preOrderDetail?.Quantity} xs={12} lg={isPreOrder ? 4 : 6} />
            <ReadOnlyInput label="Size" value={preOrderDetail?.Size} xs={12} lg={isPreOrder ? 4 : 6} />
            <ReadOnlyInput label="Buckram" xs={12} sm={isPreOrder ? 2.5 : 12} lg={isPreOrder ? 2.5 : 6} value={preOrderDetail?.Buckram} />
            <ReadOnlyInput label="SKU" xs={12} sm={isPreOrder ? 2.5 : 12} lg={isPreOrder ? 2.5 : 6} value={preOrderDetail?.SKU} />
            <ReadOnlyInput label="MTR" xs={12} sm={isPreOrder ? 2.5 : 12} lg={isPreOrder ? 2.5 : 6}  value={preOrderDetail?.MTR} />
            <ReadOnlyInput label="Created By" xs={12} sm={isPreOrder ? 4.5 : 12} lg={isPreOrder ? 4.5 : 6} value={preOrderDetail?.CreatedByName || "CUSTOMER"} />
            <ReadOnlyInput label="Width" value={preOrderDetail?.Width || "-"} xs={12} lg={4} />
            <ReadOnlyInput label="Weight" value={preOrderDetail?.Weight || "-"} xs={12} lg={4} />
            <ReadOnlyInput label="Rubs" value={preOrderDetail?.Rubs || "-"} xs={12} lg={4} />
            <ReadOnlyInput label="MRP" value={preOrderDetail?.MRP || "-"} xs={12} lg={4} />
            <ReadOnlyInput label="GST" value={preOrderDetail?.GST || "-"} xs={12} lg={4} />
            <ReadOnlyInput label="RRP" value={preOrderDetail?.RRP || "-"} xs={12} lg={4} />
          </Grid>
        </div>
      </div>

      <PreOrderSection
        title="Story"
        content={preOrderDetail?.Story || "-"}
      />

      <PreOrderSection
        title="Extra"
        content={
          <Box>
            <Box className="flex flex-wrap gap-4">
              <CheckboxItem
                label="Interlock"
                checked={preOrderDetail?.Interlock === 1}
              />
              <CheckboxItem
                label="ZigZag"
                checked={preOrderDetail?.ZigZag === 1}
              />
            </Box>
            <Box className="flex flex-wrap gap-4">
            <CheckboxItem
                label="Foil"
                checked={preOrderDetail?.Foil === 1}
              />
              <CheckboxItem
                label="Screen"
                checked={preOrderDetail?.Screen === 1}
              />
              <CheckboxItem
                label="Print"
                checked={preOrderDetail?.Print === 1}
              />
            </Box>
          </Box>
        }
      />

      <PreOrderSection
        title="E-Catalogue"
        content={
          <CheckboxItem
            label="E-Catalogue"
            checked={preOrderDetail?.ECatalogue === 1}
          />
        }
      />

      <PreOrderSection
        title="Technical Details"
        content={preOrderDetail?.TechnicalDetails || "-"}
      />

      <PreOrderSection
        title="Composition"
        content={preOrderDetail?.Composition || "-"}
      />

      <PreOrderSection
        title="Special Features"
        content={preOrderDetail?.SpecialFeatures || "-"}
      />
    </Box>
  );
};

const PreOrderSection = ({ title, content }) => (
  <div className="bg-gray-300 border-gray-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
    <div className="px-4 py-2 sm:px-6">
      <h3 className="text-lg text-center font-bold leading-6 text-gray-900">
        {title}
      </h3>
    </div>
    <div className="bg-white px-4 py-3 sm:px-6">
      {typeof content === 'string' ? (
        <Typography variant="body2" color="text.secondary">
          {content}
        </Typography>
      ) : (
        content
      )}
    </div>
  </div>
);

const CheckboxItem = ({ label, checked }) => (
  <Box display="flex" alignItems="center">
    <Checkbox
      checked={checked}
      disabled
      color="primary"
      size="small"
    />
    <Typography variant="body2">{label}</Typography>
  </Box>
);

export default CommonPreOrderView; 