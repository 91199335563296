import React from 'react';
import { Grid, TextField, Box, FilledInput, FormGroup, Button, Link, useMediaQuery, useTheme } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

function FileUploadForm(props) {
    const { inputFields, setInputFields } = props;
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleChange = (index, e, name) => {

        if (name === 'title') {
            let data = [...inputFields];
            data[index]['Title'] = e.target.value;
            setInputFields(data);
        }
        else if (name === 'file') {
            let data = [...inputFields];
            data[index]['SelectedFiles'] = e.target.files;
            setInputFields(data);
        }
    }


    const checkValidations = () => {
        return true;

    }

    const addFields = () => {
        let newfield = { Title: '', SelectedFiles: null }
        setInputFields([...inputFields, newfield])
    }
    const removeFields = (index) => {
        let data = [...inputFields];
        data.splice(index, 1)
        setInputFields(data)
    }

    return (
        <div className="mt-5">
            {inputFields.map((input, index) => {

                return <Grid container key={index}>
                    <Grid item xs={12} lg={5}>
                        <FormGroup className='mb-0'>
                            <TextField
                                variant="filled"
                                name="Title"
                                component={Box}
                                width="100%"
                                placeholder='Title'
                                marginBottom="1rem!important"
                                value={input.Title}
                                autoComplete="off"
                                sx={{
                                    '& .MuiFilledInput-input': {
                                      height: 35,
                                      fontSize: isSmallScreen ? "12px" : "14px"
                                    },
                                  }}
                                onChange={e => handleChange(index, e, 'title')}
                            >
                                <Box
                                    paddingLeft="0.75rem"
                                    paddingRight="0.75rem"
                                    component={FilledInput}
                                    autoComplete="off"
                                    type="text"
                                />
                            </TextField>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={3} sx={{mt: -0.5}}>
                        <FormGroup className='mb-0'>
                            <Button component="label" className='bg-gray-300 shadow-none text-[#424242]'>
                                Choose Files {input?.SelectedFiles?.length}
                                <input
                                    type="file"
                                    hidden
                                    onChange={e => handleChange(index, e, 'file')}
                                    multiple
                                />
                            </Button>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} lg={4} sx={{mt: -0.5}}>
                        <FormGroup className='mb-0'>
                            <Link href="#" onClick={() => removeFields(index)} className="inline-block mt-0.5" >
                                <img width="40" alt='delete-icon' src={`${process.env.REACT_APP_AWS_S3_BUCKET_URL}assets/images/delete-icon.png`} />
                            </Link>
                        </FormGroup>
                    </Grid>
                </Grid>
            })}

            <Grid container>
                <Grid item xs={12} lg={12}>
                    <Button autoFocus className='bg-blue-500 border-none py-1 px-3 shadow-none min-w-0 flex flex-wrap items-center' onClick={addFields}>
                        <AddCircleOutlineIcon className='w-8 h-auto text-white' /> <span className='text-base text-white font-semibold ml-1'>Add</span>
                    </Button>
                </Grid>
            </Grid>
        </div>
    );

};
export default FileUploadForm;