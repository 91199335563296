import React, { useState, useEffect, useCallback } from "react";
// @mui/material components
import { makeStyles } from "@mui/styles";
import { useTheme, Autocomplete, debounce, useMediaQuery, Stack, InputLabel } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import FilledInput from "@mui/material/FilledInput";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import componentStyles from "assets/theme/views/admin/profile.js";
import { dataService } from "services/data-service";
import { checkEmptyValidation } from "services/validation-service";
import { FormHelperText } from "@mui/material";
import MessageBar from "components/MessageBar/MessageBar";
import Header from "components/Headers/Header";
import { useHistory } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams } from 'react-router-dom';
import { showVerificationDialog } from "components/dialoges/VerifiyOTPDialog";
import DeliveryFileQuantity from "components/dialoges/DeliveryFileQuantity";
import { Edit } from "@mui/icons-material";
import { CustomButton } from "../../../components/Common/CustomButton";
//import FileUploadDialog from "../../../components/dialoges/FileUpload/FileUploadDialog";
import GoodsEntryDocControl from "./GoodsEntryDocControl";
import UploadedDocumentsView from "components/dialoges/FileUpload/UploadedDocumentsView";
import { createFilterOptions } from '@mui/material/Autocomplete';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const filter = createFilterOptions();
//import roles from "services/roles";

const useStyles = makeStyles(componentStyles);

function GoodsEntry() {

    //Use properties
    const classes = useStyles();
    const theme = useTheme();

    const history = useHistory();

    //Then inside your component
    const { id, customerGoodEntry, customerId } = useParams();
    const PageTitle = id ? 'Update Goods Entry' : 'Add Goods Entry';

    // Usef defined states
    const [goodsEntryModel, setGoodsEntryModel] = useState({
        CustomerId: 0,
        GoodsEntryNumber: 0,
        GoodsEntryNumberOriginal: 0,
        FileNames: [],
        DeleteGoodsEntryFiles: [],
        FilesData: [],
        DisplayFilesData: [],
        ReceiveDate: new Date(),
        Rolls: 0,
        Bundles: '',
        Samples: '',
        Store: '',
        Rack: '',
        Quantity: 1,
        LRNumbers: '',
        Remarks: '',
        BillingEmail: null,
        ShippingEmail: null
    });

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const iMDScreen = useMediaQuery(theme.breakpoints.down('md'));
    const iXLScreen = useMediaQuery(theme.breakpoints.down('xl'));


    
    // Usef defined states
    const [goodsEntryValidateModel, setGoodsEntryValidateModel] = useState({
        CustomerId: { message: '', touched: false },
        ReceiveDate: { message: '', touched: false },
        IsValidationError: false
    });

    const [showSubmitLoading, setShowSubmitLoading] = useState(false); // loading page state value
    const [productList, setProductList] = useState([]);
    const [customerList, setCustomerList] = useState([]);

    const [selectedFileNamesList, setSelectedFileNamesList] = useState([]);
    const [FileNamesList, setFileNamesList] = useState([]);


    const [selectedFileForQuantity, setSelectedFileForQuantity] = useState();
    const [openAddQuantityDialog, setOpenAddQuantityDialog] = useState(false);
    const [openEditQuantityDialog, setOpenEditQuantityDialog] = useState(false);
    const [isReload, setIsReload] = useState(false);
    const [uploadedDocuments, setUploadedDocuments] = useState([]);
    const [initialSelectedDocuments, setInitialSelectedDocuments] = useState([]);
    const [fileloading, setFileLoading] = useState(false);

    const [messageDialoge, setMessageDialoge] = useState({
        show: false,
        type: '',
        message: ''
    });

    useEffect(() => {
        const fetchData = async () => {
            if (id) {
                await getGoodsEntryData();
            }
            else {
                await GetNextGoodsEntryNumber()
            }
            await getProductList();
            await getCustomerList();
        }

        fetchData()
    }, [id, isReload])



    const GetNextGoodsEntryNumber = async () => {
        let res = await dataService('get', 'NextGoodsEntryNumberGet', null);
        if (res.status === 200) {
            if (res?.data) {

                if (customerGoodEntry) {
                    await getSyncGoodEntryData(res?.data?.NextGoodsEntryNumber);
                }
                else {
                    setGoodsEntryModel({ ...goodsEntryModel, GoodsEntryNumber: res?.data?.NextGoodsEntryNumber })
                }
            }

        }
    }

    const getProductList = async () => {
        let res = await dataService('get', 'CommonList', `Query=products`);
        if (res.status === 200) {
            if (res?.data?.length > 0) {
                setProductList(res?.data);
            }

        }
    }

    const getCustomerList = async () => {
        let res = await dataService('get', 'CommonList', `Query=customers`);
        if (res.status === 200) {
            if (res?.data?.length > 0) {
                setCustomerList(res?.data);
            }

        }
    }

    const getGoodsEntryData = async () => {
        let res = await dataService('get', `GoodsEntryGet/${id}`, '');
        if (res.status === 200) {
            const { GoodsEntryData, GoodsEntryFiles, GoodsEntryDocuments } = res?.data;
            if (GoodsEntryData) {
                setGoodsEntryModel({
                    ...goodsEntryModel,
                    GoodsEntryNumber: GoodsEntryData?.GoodsEntryNumber,
                    GoodsEntryNumberOriginal: GoodsEntryData?.GoodsEntryNumberOriginal,
                    CustomerId: GoodsEntryData?.CustomerId,
                    FileNames: GoodsEntryFiles?.length > 0 ? GoodsEntryFiles.reduce((acc, e) => { acc.push(e.Name); return acc }, []) : [],
                    DisplayFilesData: GoodsEntryFiles?.length > 0 ? GoodsEntryFiles : [],
                    //FileNames: GoodsEntryData?.FileNames,
                    ReceiveDate: GoodsEntryData?.ReceiveDate,
                    Rolls: GoodsEntryData?.Rolls,
                    Bundles: GoodsEntryData?.Bundles,
                    Samples: GoodsEntryData?.Samples,
                    Store: GoodsEntryData?.Store,
                    Rack: GoodsEntryData?.Rack,
                    Quantity: GoodsEntryData?.Quantity,
                    LRNumbers: GoodsEntryData?.LRNumbers,
                    Remarks: GoodsEntryData?.Remarks
                });
            }

            if (GoodsEntryFiles?.length > 0) {
                setSelectedFileNamesList(GoodsEntryFiles);

                // setGoodsEntryModel({
                //     ...goodsEntryModel,
                //     FileNames: GoodsEntryFiles.reduce((acc, e) => { acc.push(e.Name); return acc }, [])})
                //                goodsEntryModel.FileNames = GoodsEntryFiles.reduce((acc, e) => { acc.push(e.Name); return acc }, [])

            }

            if (GoodsEntryDocuments?.length > 0) {
                setUploadedDocuments(GoodsEntryDocuments);
            }

        }
    }

    const getSyncGoodEntryData = async (GoodsEntryNumber) => {
        let res = await dataService('get', `CustomerGoodsEntryGet`, `GoodsEntryId=${customerGoodEntry}&GoodsCustomerId=${customerId}`);
        if (res.status === 200) {
            const { Bundles, LRNumbers, TagDetails } = res?.data?.GoodsEntryData;
            const { GoodsEntryFiles } = res?.data

            setGoodsEntryModel({
                ...goodsEntryModel,
                Remarks: TagDetails,
                Bundles: Bundles,
                LRNumbers: LRNumbers,
                CustomerId: customerId,
                Status: "Approved",
                GoodsEntryNumber: GoodsEntryNumber,
                DisplayFilesData: GoodsEntryFiles?.length > 0 ? GoodsEntryFiles.map(item => ({
                    ...item,
                    Name: item?.FileNames
                })) : [],
                FilesData: GoodsEntryFiles?.length > 0 ? GoodsEntryFiles.map(item => ({
                    ...item,
                    Name: item?.FileNames
                })) : [],
                FileNames: GoodsEntryFiles?.length > 0 ? GoodsEntryFiles.reduce((acc, e) => { acc.push(e.FileNames); return acc }, []) : [],
                Quantity: GoodsEntryFiles?.length > 0 ? GoodsEntryFiles?.reduce((accum, item) => parseInt(accum ? accum : 0) + parseInt(item?.Quantity), 0) : 1
            });

            if (res?.data?.GoodsEntryFiles.length > 0) {

                const GoodsEntryFiles = res?.data?.GoodsEntryFiles.map(item => ({
                    ...item,
                    Name: item?.FileNames
                }))

                setSelectedFileNamesList(GoodsEntryFiles);
            }
        }
    }

    const handleChange = (evt, type, checkValidation) => {
        setGoodsEntryModel({ ...goodsEntryModel, [evt.target.name]: evt.target.value });
        if (checkValidation) {
            setGoodsEntryValidateModel({ ...goodsEntryValidateModel, [evt.target.name]: { message: checkEmptyValidation(evt.target.value, evt.target.name), touched: true } });
        }
    };

    const handleSelectChange = (id, value) => {
        switch (id) {
            case 'ReceiveDate':
                setGoodsEntryModel({ ...goodsEntryModel, ReceiveDate: value });
                setGoodsEntryValidateModel({ ...goodsEntryValidateModel, 'ReceiveDate': { message: checkEmptyValidation(value, 'Receive Date'), touched: true } });
                break;
            case 'CustomerId':
                //setCustomerModel({ ...customerModel, BillingCountryId: countryList?.find(c => { return c.CountryId === value.CountryId })});
                setGoodsEntryModel({ ...goodsEntryModel, CustomerId: value?.CustomerId, BillingEmail: value?.BillingEmail, ShippingEmail: value?.ShippingEmail });
                setGoodsEntryValidateModel({ ...goodsEntryValidateModel, 'CustomerId': { message: checkEmptyValidation(value?.CustomerId, 'Customer'), touched: true } });
                break;
            default:
                setGoodsEntryModel({ ...goodsEntryModel });
                break;
        }
    }

    const handleSubmitGoodsEntry = async () => {
        if (!checkValidations()) {

            setShowSubmitLoading(true);
            let reqData = { ...goodsEntryModel };

            let deleteGoodsEntryFiles = [];
            if (goodsEntryModel.DeleteGoodsEntryFiles?.length > 0) {
                deleteGoodsEntryFiles = goodsEntryModel.DeleteGoodsEntryFiles?.reduce((acc, e) => { acc.push(e.GoodsEntryFileId); return acc }, []);
            }

            const findCustomerData = customerList?.find((ele) => Number(ele?.CustomerId) === Number(goodsEntryModel.CustomerId))
            // let FilesData = [];
            // if(goodsEntryModel.FilesData?.length > 0){
            //     FilesData = goodsEntryModel.FilesData?.reduce((acc, e) => { acc.push(e.FileId); return acc }, []);
            // }

            //reqData.FilesData = FilesData;
            reqData.DeleteGoodsEntryFiles = deleteGoodsEntryFiles;
            reqData.BillingEmail = findCustomerData?.BillingEmail;
            reqData.ShippingEmail = findCustomerData?.ShippingEmail;

            if (id) {
                //TEMP HIDE VERIFICATION DIALOG

                showVerificationDialog('Verify Authorization', true, () => {
                    setShowSubmitLoading(false);
                }, async () => {
                    let res = await dataService('post', `GoodsEntrySave/${id ? id : 0}`, reqData);
                    if (res && (res.status === 200 || res.status === 304)) {
                        await uploadGoodsEntryDocuments(res?.data?.GoodsEntryId, res?.data?.GoodsEntryNumber);
                    }
                    handleSubmitResponse(res);
                })
            }
            else {
                const customerGoodEntryPayload = {
                    LRNumbers: goodsEntryModel?.LRNumbers,
                    Rolls: goodsEntryModel?.Rolls,
                    Store: goodsEntryModel?.Store,
                    Bundles: goodsEntryModel?.Bundles,
                    CustomerId: goodsEntryModel?.CustomerId,
                    Rack: goodsEntryModel?.Rack,
                    CreatedBy: goodsEntryModel?.CustomerId,
                    Status: "Approved",
                    CustomerGoodsEntryID: customerGoodEntry
                }

                await dataService('post', 'CustomerGoodsEntry', customerGoodEntryPayload)
                let res = await dataService('post', `GoodsEntrySave/${id ? id : 0}`, reqData);
                if (res && (res.status === 200 || res.status === 304)) {
                    await uploadGoodsEntryDocuments(res?.data?.GoodsEntryId, res?.data?.GoodsEntryNumber);
                }
                handleSubmitResponse(res);
                setShowSubmitLoading(false);
            }

        }

    }

    const handleSubmitResponse = async (res) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        setShowSubmitLoading(false);
        if (res && (res.status === 200 || res.status === 304)) {
            setMessageDialoge({ show: true, type: 'success', message: id ? 'Goods Entry updated successfully!' : 'Goods Entry Added successfully!' });
            if (!id) {
                setGoodsEntryModel({
                    ...goodsEntryModel,
                    DeleteGoodsEntryFiles: [],
                    FilesData: [],
                    DisplayFilesData: []
                })
                setTimeout(() => {
                    history.push('goods-entries')
                }, 500);
            }
            else {
                setIsReload(!isReload);
            }

        } else {
            //setOpen(res.message);
            setMessageDialoge({ show: true, type: 'error', message: res.message });
        }
    }


    const checkValidations = () => {

        let validateErrors = {
            CustomerId: { message: checkEmptyValidation(goodsEntryModel.CustomerId === 0 ? '' : goodsEntryModel.CustomerId, 'Customer'), touched: true },
            ReceiveDate: { message: checkEmptyValidation(goodsEntryModel.ReceiveDate, 'Receive Date'), touched: true },
        }
        if (validateErrors.CustomerId.message !== '' ||
            validateErrors.ReceiveDate.message !== '') {
            validateErrors.IsValidationError = true;
        }
        else {
            validateErrors.IsValidationError = false;
        }
        setGoodsEntryValidateModel(validateErrors);
        return validateErrors.IsValidationError;

    }

    const fileNameChangeHandle = (event, list, reason, detail) => {
        if (reason === 'removeOption') {
            let farr = goodsEntryModel.FileNames.filter(item => item !== detail?.option?.Name)
            let sFileNamesList = [...selectedFileNamesList];
            let sFileNamesListNew = [...selectedFileNamesList];
            //sFileNamesList = sFileNamesList.filter(item => item.Name !== detail?.option?.Name)

            sFileNamesList = detail?.option?.GoodsEntryFileId ? sFileNamesList.filter(item => item.GoodsEntryFileId !== detail?.option?.GoodsEntryFileId) : sFileNamesList.filter(item => item.Name !== detail?.option?.Name);
            let deleteGoodsEntryFilesId = detail?.option?.GoodsEntryFileId ? sFileNamesListNew.filter(item => Number(item.GoodsEntryFileId) === Number(detail?.option?.GoodsEntryFileId)) : [];
            let selFilesData = [...goodsEntryModel.FilesData]
            let selectedFileIds = selFilesData.filter(item => item.FileId ? item.FileId !== detail?.option?.FileId : item.Name !== detail?.option?.Name);

            let selDisplayFilesData = [...goodsEntryModel.DisplayFilesData]
            let selDisplayFileIds = selDisplayFilesData.filter(item => item.FileId ? item.FileId !== detail?.option?.FileId : item.Name !== detail?.option?.Name);

            setSelectedFileNamesList(sFileNamesList);
            setGoodsEntryModel({
                ...goodsEntryModel,
                FileNames: farr,
                DeleteGoodsEntryFiles: [...goodsEntryModel.DeleteGoodsEntryFiles, ...deleteGoodsEntryFilesId],
                FilesData: selectedFileIds,
                DisplayFilesData: selDisplayFileIds
            })
        }
        else {
            let insrtFile = {
                FileId: detail?.option?.FileId || null,
                Name: detail?.option?.Name
            }
            /*selectedFileNamesList.push({FileId: detail?.option?.FileId || null, Name: detail?.option?.Name})
            goodsEntryModel.FileNames.push(detail?.option?.Name);

            
            setGoodsEntryModel({
                ...goodsEntryModel,
                FilesData: [...goodsEntryModel.FilesData, ...[insrtFile]]})*/


            setOpenAddQuantityDialog(true);
            setSelectedFileForQuantity(insrtFile);
        }
    }

    const addFileQuantityHandle = (quantityFileData) => {
        //This line push data to selectedFileNamesList & goodsEntryModel.DisplayFilesData field also (Weird)
        let selList = [...selectedFileNamesList];

        selList.push({
            FileId: quantityFileData?.FileId || null,
            Name: quantityFileData?.Name,
            Quantity: quantityFileData?.Quantity,
            ProductId: quantityFileData?.ProductId,
            ProductFileId: quantityFileData?.ProductFileId,
            ProductName: quantityFileData?.ProductName,
            FileSize: quantityFileData?.FileSize,
            Client: quantityFileData?.Client
        })
        goodsEntryModel.FileNames.push(quantityFileData?.Name);
        setSelectedFileNamesList(selList);
        //if(!id || goodsEntryModel?.FilesData?.length === 0){
        setGoodsEntryModel({
            ...goodsEntryModel,
            FilesData: [...goodsEntryModel.FilesData, ...[quantityFileData]],
            DisplayFilesData: [...goodsEntryModel.DisplayFilesData, ...[quantityFileData]],
            Quantity: selList?.reduce((accum, item) => parseInt(accum ? accum : 0) + parseInt(item?.Quantity), 0)
        })
        //  }
        //  else{
        //     setGoodsEntryModel({
        //         ...goodsEntryModel,
        //         FilesData: [...goodsEntryModel.FilesData, ...[quantityFileData]]
        //     })
        //  }


        setOpenAddQuantityDialog(false);
        fetchFileListData("")
        setSelectedFileForQuantity(null);
    }

    const handleEditFileQuantityDialog = (index, label) => {
        setSelectedFileForQuantity(label);
        setOpenEditQuantityDialog(true);
    }

    const editFileQuantityHandle = (quantityFileData) => {
        let updatedData = selectedFileNamesList.map(x => (Number(x.FileId) === Number(quantityFileData?.FileId) ?
            {
                ...x, Quantity: quantityFileData?.Quantity,
                Client: quantityFileData?.Client,
                ProductName: quantityFileData?.ProductName,
                FileSize: quantityFileData?.FileSize,
                ProductId: quantityFileData?.ProductId,
                ProductFileId: quantityFileData?.ProductFileId,
            } : x));

        let currFilesData = [...goodsEntryModel.FilesData];
        if (currFilesData?.length > 0) {
            currFilesData = currFilesData?.filter((filesFilter => {
                return filesFilter?.FileId !== quantityFileData?.FileId && filesFilter?.Name !== quantityFileData?.Name
            }))
        }
        setSelectedFileNamesList(updatedData);
        setGoodsEntryModel({
            ...goodsEntryModel,
            FilesData: [...currFilesData, ...[quantityFileData]],
            DisplayFilesData: updatedData,
            Quantity: updatedData?.reduce((accum, item) => parseInt(accum ? accum : 0) + parseInt(item?.Quantity), 0)
        })
        //selectedFileNamesList?.find((sel) => {return quantityFileData?.FileId === sel?.FileId })
        setOpenEditQuantityDialog(false);
        setSelectedFileForQuantity(null);
    }

    // UPLOAD DOCUMENT FUNCTION
    const uploadGoodsEntryDocuments = async (GoodsEntryId, GoodsEntryNumber) => {
        let selectedFilesArray = []
        const formData = new FormData();
        formData.append(`GoodsEntryNumber`, GoodsEntryNumber);
        initialSelectedDocuments.forEach(selectedDocument => {
            if (selectedDocument?.SelectedFiles?.length > 0) {
                for (let i = 0; i < selectedDocument?.SelectedFiles?.length; i++) {
                    selectedFilesArray.push({ file: selectedDocument?.SelectedFiles[i], title: selectedDocument?.Title })
                }
            }
        });

        if (selectedFilesArray?.length > 0) {
            selectedFilesArray.forEach((element, index) => {
                formData.append(
                    `file_${index + 1}`,
                    element?.file,
                    element?.file?.name
                );
                formData.append(`file_${index + 1}`, element?.title);
            });
            await dataService('post', `GoodsEntryDocumentsUpload/${GoodsEntryId}`, formData, 'multipart/form-data');
        }
    }

    const fetchFileListData = useCallback(async (search = '') => {
        let res = await dataService('get', 'FileSearchByName', `searchText=${search}`);
        setTimeout(() => {
            setFileLoading(false)
        }, 1000);
        setFileNamesList(res?.data)
    }, []);

    const handleTextChange = useCallback(
        debounce(async function (event) {
            if (event.target.value.length === 0 || event.target.value.length >= 3) {
                setFileLoading(true)
                await fetchFileListData(event?.target?.value ?? '');
            }
        }, 300),
        [fetchFileListData]
    );


    return (
        <>
            <Header />
            {/* Page content */}
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-6rem"
                classes={{ root: classes.containerRoot }}
            // paddingTop="10rem"
            // paddingBottom="3rem"
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Card
                            // className={`bg-${ProductBGColors[selectedProduct?.Name]}`}
                            classes={{
                                root: classes.cardRoot + " " + classes.cardRootSecondary,
                            }}
                            sx={{
                                height: '100%',
                                overflow: 'visible',
                            }}
                        >
                            <CardHeader
                                subheader={
                                    <Grid
                                        container
                                        component={Box}
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Grid item xs="auto">
                                            <Box
                                                component={Typography}
                                                variant="h3"
                                                marginBottom="0!important"
                                            >
                                                {PageTitle}
                                            </Box>
                                        </Grid>
                                        <Grid item xs="auto">
                                            <Box
                                                component={Typography}
                                                variant="h1"
                                                marginBottom="0!important"
                                                className="text-xl text-blue-800"
                                            >
                                                <strong>{`${!id ? 'GE-' + goodsEntryModel?.GoodsEntryNumber : goodsEntryModel?.GoodsEntryNumber}`}</strong>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                }
                                classes={{ root: classes.cardHeaderRoot }}
                            ></CardHeader>
                            <CardContent sx={theme => ({ p: "1.5rem", [theme.breakpoints.down("sm")]: { p: "0.5rem" } })}>
                                <Grid item xs={12} lg={12}>
                                    <MessageBar show={messageDialoge.show} message={messageDialoge.message} type={messageDialoge.type} />
                                </Grid>
                                <Box
                                    component={Typography}
                                    variant="h6"
                                    color={theme.palette.gray[600] + "!important"}
                                    paddingTop=".25rem"
                                    paddingBottom=".25rem"
                                    fontSize=".75rem!important"
                                    letterSpacing=".04em"
                                    marginBottom="1.5rem!important"
                                    classes={{ root: classes.typographyRootH6 }}
                                >
                                    Goods Entry Information
                                </Box>
                                <div >
                                    <div className="px-3 xs:px-4 xs:py-2">
                                        <Grid container spacing={isSmallScreen ? 0.5 : 1}>
                                            <Grid item xs={12} md={6} lg={3} xl={3} pr={1} pl={1}>
                                                <Stack spacing={0.5}>
                                                    <InputLabel className="text-xs sm:text-sm" htmlFor="ReceiveDate">Receive Date</InputLabel>
                                                    <div className="bg-white custom-datepicker">
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DatePicker
                                                                className="w-full"
                                                                name="ReceiveDate"
                                                                format="dd/MM/yyyy"
                                                                value={goodsEntryModel?.ReceiveDate ? new Date(goodsEntryModel?.ReceiveDate) : null}
                                                                onChange={e => handleSelectChange('ReceiveDate', e)}
                                                                sx={{
                                                                    '& .MuiOutlinedInput-input': {
                                                                        height: 35,
                                                                    },
                                                                }}
                                                                size="small"
                                                            />
                                                        </LocalizationProvider>
                                                    </div>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={4} xl={5} pr={1} pl={1}>
                                                <Stack spacing={0.5}>
                                                    <InputLabel className="text-xs sm:text-sm" htmlFor="selectedProduct">Customer</InputLabel>
                                                    {customerList?.length > 0 &&
                                                        <Autocomplete
                                                            id="tags-outlined"
                                                            value={customerList?.find(c => Number(c.CustomerId) === Number(goodsEntryModel.CustomerId))}
                                                            defaultValue={customerList?.find(c => Number(c.CustomerId) === Number(goodsEntryModel.CustomerId))}
                                                            options={customerList}
                                                            onChange={(event, newValue) => {
                                                                handleSelectChange('CustomerId', newValue)
                                                            }}
                                                            sx={{
                                                                '& .MuiOutlinedInput-input': {
                                                                    height: 35,
                                                                },
                                                            }}
                                                            autoHighlight
                                                            getOptionLabel={(option) => option.Name}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    placeholder="Select Product"
                                                                    autoComplete="new-password"
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    <FormHelperText className="text-red-500">{goodsEntryValidateModel.CustomerId.message !== '' && goodsEntryValidateModel.CustomerId.touched ? goodsEntryValidateModel.CustomerId.message : ''}</FormHelperText>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={12} lg={5} xl={4} pr={1} pl={1}>
                                                <Stack spacing={0.5}>
                                                    <InputLabel className="text-xs sm:text-sm" htmlFor="LRNumbers">LR Numbers</InputLabel>
                                                    <TextField
                                                        variant="filled"
                                                        name="LRNumbers"
                                                        component={Box}
                                                        width="100%"
                                                        sx={{
                                                            '& .MuiFilledInput-input': {
                                                                height: 35,
                                                                fontSize: isSmallScreen ? "12px" : "14px"
                                                            },
                                                        }}
                                                        marginBottom="1rem!important"
                                                        value={goodsEntryModel.LRNumbers}
                                                        autoComplete="off"
                                                        onChange={e => handleChange(e)}
                                                    >
                                                        <Box
                                                            paddingLeft="0.75rem"
                                                            paddingRight="0.75rem"
                                                            component={FilledInput}
                                                            autoComplete="off"
                                                            type="text"
                                                        />
                                                    </TextField>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={2.4} lg={2.4}>
                                                <Stack spacing={0.5}>
                                                    <InputLabel className="text-xs sm:text-sm" htmlFor="Rolls">Rolls</InputLabel>
                                                    <TextField
                                                        variant="filled"
                                                        component={Box}
                                                        width="100%"
                                                        sx={{
                                                            '& .MuiFilledInput-input': {
                                                                height: 35,
                                                                fontSize: isSmallScreen ? "12px" : "14px"
                                                            },
                                                        }}
                                                        name="Rolls"
                                                        marginBottom="1rem!important"
                                                        value={goodsEntryModel.Rolls}
                                                        autoComplete="off"
                                                        onChange={e => handleChange(e)}
                                                    >
                                                        <Box
                                                            paddingLeft="0.75rem"
                                                            paddingRight="0.75rem"
                                                            component={FilledInput}
                                                            autoComplete="off"
                                                            type="text"
                                                        />
                                                    </TextField>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={2.4} lg={2.4}>
                                                <Stack spacing={0.5}>
                                                    <InputLabel className="text-xs sm:text-sm" htmlFor="Bundles">Bundles</InputLabel>
                                                    <TextField
                                                        variant="filled"
                                                        name="Bundles"
                                                        component={Box}
                                                        width="100%"
                                                        sx={{
                                                            '& .MuiFilledInput-input': {
                                                                height: 35,
                                                                fontSize: isSmallScreen ? "12px" : "14px"
                                                            },
                                                        }}
                                                        marginBottom="1rem!important"
                                                        value={goodsEntryModel.Bundles}
                                                        autoComplete="off"
                                                        onChange={e => handleChange(e)}
                                                    >
                                                        <Box
                                                            paddingLeft="0.75rem"
                                                            paddingRight="0.75rem"
                                                            component={FilledInput}
                                                            autoComplete="off"
                                                            type="text"
                                                        />
                                                    </TextField>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={2.4} lg={2.4}>
                                                <Stack spacing={0.5}>
                                                    <InputLabel className="text-xs sm:text-sm" htmlFor="Samples">Samples</InputLabel>
                                                    <TextField
                                                        variant="filled"
                                                        name="Samples"
                                                        component={Box}
                                                        width="100%"
                                                        sx={{
                                                            '& .MuiFilledInput-input': {
                                                                height: 35,
                                                                fontSize: isSmallScreen ? "12px" : "14px"
                                                            },
                                                        }}
                                                        marginBottom="1rem!important"
                                                        value={goodsEntryModel.Samples}
                                                        autoComplete="off"
                                                        onChange={e => handleChange(e)}
                                                    >
                                                        <Box
                                                            paddingLeft="0.75rem"
                                                            paddingRight="0.75rem"
                                                            component={FilledInput}
                                                            autoComplete="off"
                                                            type="text"
                                                        />
                                                    </TextField>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={2.4} lg={2.4}>
                                                <Stack spacing={0.5}>
                                                    <InputLabel className="text-xs sm:text-sm" htmlFor="Store">Store</InputLabel>
                                                    <TextField
                                                        variant="filled"
                                                        component={Box}
                                                        width="100%"
                                                        sx={{
                                                            '& .MuiFilledInput-input': {
                                                                height: 35,
                                                                fontSize: isSmallScreen ? "12px" : "14px"
                                                            },
                                                        }}
                                                        name="Store"
                                                        marginBottom="1rem!important"
                                                        value={goodsEntryModel.Store}
                                                        autoComplete="off"
                                                        onChange={e => handleChange(e)}
                                                    >
                                                        <Box
                                                            paddingLeft="0.75rem"
                                                            paddingRight="0.75rem"
                                                            component={FilledInput}
                                                            autoComplete="off"
                                                            type="text"
                                                        />
                                                    </TextField>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={2.4} lg={2.4}>
                                                <Stack spacing={0.5}>
                                                    <InputLabel className="text-xs sm:text-sm" htmlFor="Rack">Rack</InputLabel>
                                                    <TextField
                                                        variant="filled"
                                                        name="Rack"
                                                        component={Box}
                                                        width="100%"
                                                        sx={{
                                                            '& .MuiFilledInput-input': {
                                                                height: 35,
                                                                fontSize: isSmallScreen ? "12px" : "14px"
                                                            },
                                                        }}
                                                        marginBottom="1rem!important"
                                                        value={goodsEntryModel.Rack}
                                                        autoComplete="off"
                                                        onChange={e => handleChange(e)}
                                                    >
                                                        <Box
                                                            paddingLeft="0.75rem"
                                                            paddingRight="0.75rem"
                                                            component={FilledInput}
                                                            autoComplete="off"
                                                            type="text"
                                                        />
                                                    </TextField>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={3} lg={2}>
                                                <Stack spacing={0.5}>
                                                    <InputLabel className="text-xs sm:text-sm" htmlFor="Quantity">Quantity</InputLabel>
                                                    <TextField
                                                        variant="filled"
                                                        name="Quantity"
                                                        component={Box}
                                                        width="100%"
                                                        sx={{
                                                            '& .MuiFilledInput-input': {
                                                                height: 35,
                                                                fontSize: isSmallScreen ? "12px" : "14px"
                                                            },
                                                            '& input[type=number]::-webkit-outer-spin-button': {
                                                                WebkitAppearance: 'none'
                                                            },
                                                            '& input[type=number]::-webkit-inner-spin-button': {
                                                                WebkitAppearance: 'none'
                                                            },
                                                        }}
                                                        inputProps={{ min: 0 }}
                                                        marginBottom="1rem!important"
                                                        value={goodsEntryModel.Quantity}
                                                        autoComplete="off"
                                                        onChange={e => handleChange(e)}
                                                    >
                                                        <Box
                                                            paddingLeft="0.75rem"
                                                            paddingRight="0.75rem"
                                                            component={FilledInput}
                                                            autoComplete="off"
                                                            type="text"
                                                        />
                                                    </TextField>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={9} lg={6}>
                                                <Stack spacing={0.5}>
                                                    <InputLabel className="text-xs sm:text-sm" htmlFor="FileNames">File Names</InputLabel>
                                                    <Autocomplete
                                                        multiple
                                                        classes={{
                                                            inputRoot: classes.inputRoot,
                                                        }}
                                                        loading={fileloading}
                                                        filterSelectedOptions
                                                        sx={{
                                                            '& .MuiOutlinedInput-input': {
                                                                height: 35,
                                                            },
                                                            border: "none"
                                                        }}
                                                        id="tags-standard"
                                                        options={FileNamesList}
                                                        value={selectedFileNamesList}
                                                        onChange={fileNameChangeHandle}
                                                        filterOptions={(options, params) => {
                                                            const filtered = filter(options, params);
                                                            if (params.inputValue !== '') {
                                                                filtered.push({
                                                                    inputValue: params.inputValue,
                                                                    Name: params.inputValue
                                                                });
                                                            }
                                                            return filtered;
                                                        }}
                                                        getOptionLabel={(option) => option.Name}
                                                        className="w-full flex items-center"
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                onChange={handleTextChange}
                                                                variant="outlined"
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    endAdornment: (
                                                                        <React.Fragment>
                                                                            {fileloading ? <CircularProgress sx={{ marginRight: 5 }} color="inherit" size={20} /> : null}
                                                                            {params.InputProps.endAdornment}
                                                                        </React.Fragment>
                                                                    )
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Stack spacing={0.5}>
                                                    <InputLabel className="text-xs sm:text-sm" htmlFor="Remarks">Remarks</InputLabel>
                                                    <TextField
                                                        variant="filled"
                                                        name="Remarks"
                                                        component={Box}
                                                        multiline
                                                        minRows={2}
                                                        maxRows={4}
                                                        width="100%"
                                                        sx={{
                                                            '& .MuiFilledInput-input': {
                                                              height: 35,
                                                              fontSize: isSmallScreen ? "12px" : "14px",
                                                              border: 'none',
                                                              padding: '0px'
                            
                                                            },
                                                            '& .MuiFilledInput-root': {
                                                              border: '1px solid #cad1d7 !important',
                                                              borderRadius: '8px',
                                                            },
                                                            marginBottom: '1rem!important',
                                                          }}
                                                          size="small"
                                                        marginBottom="1rem!important"
                                                        value={goodsEntryModel.Remarks}
                                                        autoComplete="off"
                                                        onChange={e => handleChange(e)}
                                                    >
                                                        <Box
                                                            paddingLeft="0.75rem"
                                                            paddingRight="0.75rem"
                                                            component={FilledInput}
                                                            autoComplete="off"
                                                            type="text"
                                                        />
                                                    </TextField>
                                                </Stack>
                                            </Grid>
                                            {goodsEntryModel?.DisplayFilesData?.length > 0 &&
                                                <Grid item xs={12} lg={12}>
                                                    <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-6">
                                                        <div className="border-t border-gray-200">
                                                            <dl>
                                                                <div className={`px-4 py-5 sm:grid sm:grid-cols-6 sm:px-6 bg-white`}>
                                                                    <dt className="text-sm text-blue-700 font-bold">File Name</dt>
                                                                    <dt className="text-sm text-blue-700 font-bold">Product</dt>
                                                                    <dt className="text-sm text-blue-700 font-bold">File Size</dt>
                                                                    <dt className="text-sm text-blue-700 font-bold">Client</dt>
                                                                    <dt className="text-sm text-blue-700 font-bold">Quantity</dt>
                                                                    <dt className="text-sm text-blue-700 font-bold text-center">Edit</dt>
                                                                </div>
                                                                {goodsEntryModel?.DisplayFilesData?.map((label, index) => (
                                                                    <div className={`px-4 py-5 sm:grid sm:grid-cols-6 sm:px-6 ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}`}>
                                                                        <dt className="text-sm font-medium text-gray-500">{label.Name}</dt>
                                                                        <dt className="mt-1 text-sm text-gray-900 sm:mt-0">
                                                                            {label?.ProductName}
                                                                        </dt>
                                                                        <dt className="mt-1 text-sm text-gray-900 sm:mt-0">
                                                                            {label?.FileSize}
                                                                        </dt>
                                                                        <dt className="mt-1 text-sm text-gray-900 sm:mt-0">
                                                                            {label?.Client}
                                                                        </dt>
                                                                        <dt className="mt-1 text-sm text-gray-900 sm:mt-0">
                                                                            {label?.Quantity}
                                                                        </dt>
                                                                        <dt className="mt-1 text-sm text-gray-900 sm:mt-0  text-center">
                                                                            <CustomButton className='bg-white text-blue-700 border-none' size="small" label=" " onClick={() => { handleEditFileQuantityDialog(index, label) }} icon={Edit} iconClassName="w-5 h-auto" />
                                                                        </dt>
                                                                    </div>
                                                                ))}
                                                            </dl>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            }
                                            <Grid item xs={12} lg={12}>

                                                {uploadedDocuments?.length > 0 &&
                                                    <UploadedDocumentsView
                                                        documentsList={uploadedDocuments}
                                                    />
                                                }
                                            </Grid>
                                            <Grid item xs={12} lg={6}>
                                                <Box
                                                    justifyContent="flex-start"
                                                    display="flex"
                                                    flexWrap="wrap"
                                                >
                                                    <GoodsEntryDocControl goodsEntryId={id} goodsEntryNumber={goodsEntryModel?.GoodsEntryNumberOriginal} setInitialSelectedDocuments={setInitialSelectedDocuments} setRelaod={setIsReload} reload={isReload} />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                        <Grid item xs={12} lg={6}>
                                            <Box
                                                justifyContent="flex-end"
                                                display="flex"
                                                flexWrap="wrap"
                                            >
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="large"
                                                    onClick={handleSubmitGoodsEntry}
                                                    disabled={showSubmitLoading}
                                                    className="bg-[#5e72e4] border-[#5e72e4]"
                                                >
                                                    Submit
                                                    {showSubmitLoading && <CircularProgress size={24} className="text-white mt-0.50 ml-2 w-4 h-4" />}
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container >
            {openAddQuantityDialog &&
                <DeliveryFileQuantity onClose={setOpenAddQuantityDialog} customerId={goodsEntryModel?.CustomerId} open={openAddQuantityDialog} fileData={selectedFileForQuantity} productList={productList} addFileQuantityHandle={addFileQuantityHandle} isEdit={false} />
            }
            {
                openEditQuantityDialog &&
                <DeliveryFileQuantity onClose={setOpenEditQuantityDialog} customerId={goodsEntryModel?.CustomerId} open={openEditQuantityDialog} fileData={selectedFileForQuantity} productList={productList} addFileQuantityHandle={editFileQuantityHandle} isEdit={true} />
            }


        </>
    );
}

export default GoodsEntry;
