import { Box, Checkbox, FormGroup, Grid, InputLabel, Stack, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";


const useIsSmallScreen = () => {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.down('sm'));
};


export const commonInputFieldDisabled = {
    '& input:disabled': {
        backgroundColor: 'transparent',
        color: 'black',
        WebkitTextFillColor: (theme) => ('black'),
        border: "1px solid #00000033"
    }
}; 


export const commonInputFilledField = {
    '& .MuiFilledInput-input': {
        height: 35,
        
    },
};

export  const commonStyle = {
    border: '1px solid gray',
    padding: '4px 10px',
    borderRadius: '4px',
    fontWeight: 'bold',
    backgroundColor: '#ffffff',
};

export const CustomBox = ({ label, value, other }) => {
    const isSmallScreen = useIsSmallScreen();
    return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, minWidth: 'auto', flexWrap: 'wrap', ml : 1 }}>
        <Typography color="text.secondary" sx={{ fontSize: isSmallScreen ? "12px !important" : "14px !important" }}>
            {label}:
        </Typography>
        <Typography variant="body1" color="text.primary" sx={{ ...commonStyle, fontSize: isSmallScreen ? "12px !important" : "14px !important" , fontWeight: '600'}}>
            {value || '-'}
        </Typography>
       {other ? <Checkbox sx={{ p: 0.5 }} checked={Boolean(other?.checked)} color="primary" /> : null}
    </Box>
    )
}

export const CustomFormGroup = ({ items }) => (
    <FormGroup
        sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 3,
            alignItems: 'flex-start',
            flexDirection: 'row',
            rowGap: 2,
        }}
    >
        {items.map(({ label, value, other }) => (
            <CustomBox key={label} label={label} value={value} other={other} />
        ))}
    </FormGroup>
);

export const Section = ({ title, children }) => {
    const isSmallScreen = useIsSmallScreen();

    return (
    <div className="bg-gray-300 border-gray-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-2 sm:px-6">
            <h3 className="text-lg text-center font-bold leading-6 text-gray-900">{title}</h3>
        </div>
        <div className="border-t border-gray-200 bg-white">
            <div className="px-6 py-4">
                <Grid container spacing={isSmallScreen ? 0.5 : 1}>
                    {children}
                </Grid>
            </div>
        </div>
    </div>
    )
}

export const CheckboxGroup = ({ items }) => {
    const isSmallScreen = useIsSmallScreen();
    return (
    <FormGroup sx={{ display: 'flex', flexWrap: 'wrap', gap: 3, alignItems: 'flex-start', flexDirection: 'row', rowGap: 1 }}>
        {items.map(({ label, value, name }) => (
            <Box key={label} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                <Checkbox sx={{ p: 0.5 }} checked={value || Boolean(value)} name={name} color="primary" />
                <Typography color="text.secondary" sx={{ fontSize: isSmallScreen ? '12px !important' : '14px !important' }}>
                    {label}
                </Typography>
            </Box>
        ))}
    </FormGroup>
    )
};


export const ReadOnlyInput = ({ label, value, xs = 6, sm = 4, lg = 4,
    inputProps = {}, ...rest }) => {
        const isSmallScreen = useIsSmallScreen();

    return (
        <Grid item xs={xs} sm={sm} lg={lg} px={1} {...rest}>
            <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm">{label}</InputLabel>
                <TextField
                    variant="filled"
                    value={value}
                    inputProps={{ readOnly: true, ...inputProps }}
                    disabled
                    sx={{
                        ...commonInputFieldDisabled,
                        ...commonInputFilledField,
                        '& .MuiFilledInput-input': {
                            fontSize : isSmallScreen ? "10px !important" : "12px !important"
                        },
                    }}
                    size="small"
                    autoComplete="off"
                    width="100%"
                    marginBottom="1rem!important"
                />
            </Stack>
        </Grid>
    );
};

export const ReadOnlyInputRemarks = ({ label, value, xs = 6, sm = 4, lg = 4,
    inputProps = {}, ...rest }) => {
        const isSmallScreen = useIsSmallScreen();

    return (
        <Grid item xs={xs} sm={sm} lg={lg} px={1} {...rest}>
            <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm">{label}</InputLabel>
                <TextField
                    variant="filled"
                    value={value}
                    inputProps={{ readOnly: true, ...inputProps }}
                    disabled
                    sx={{
                        ...commonInputFieldDisabled,
                        ...commonInputFilledField,
                        '& .MuiFilledInput-input': {
                            fontSize : isSmallScreen ? "10px !important" : "12px !important"
                        },
                    }}
                    size="small"
                    autoComplete="off"
                    width="100%"
                    marginBottom="1rem!important"
                    multiline
                    minRows={1}
                    maxRows={4}
                />
            </Stack>
        </Grid>
    );
};