import axios from 'axios';  

import Cookies from 'js-cookie';
import { showMessageSnackBar } from 'components/MessageBar/ErrorSnackBar';
import axiosInstance from './axios-service';

export const dataService = async (method, endpoint, data) => {
  try {
    let response;
    
    switch (method.toLowerCase()) {
      case 'get':
        response = await axiosInstance.get(`/${endpoint}${data ? `?${data}` : ''}`);
        break;
      case 'post':
        response = await axiosInstance.post(`/${endpoint}`, data);
        break;
      case 'put':
        response = await axiosInstance.put(`/${endpoint}`, data);
        break;
      case 'delete':
        response = await axiosInstance.delete(`/${endpoint}`);
        break;
      default:
        throw new Error(`Unsupported HTTP method: ${method}`);
    }

    return response.data;
  } catch (error) {
    if (error.response?.status === 400) {
      showMessageSnackBar(
        '', 
        error.response.data?.message || 'Bad Request', 
        'error',
        true
      );
    }
    
    if (error.response?.status === 401) {
      return new Promise((resolve) => resolve());
    }
    
    throw error;
  }
};

export async function verifyUserService(data) {

  var config = {
    method: 'post',
    url: `${process.env.REACT_APP_API_URL}/api/verifyUser`,
    headers: {
      'Authorization': `Bearer ${data.Token}`,
      'Content-Type': 'application/json',
    },
    data: {VerificationCode: data.VerificationCode}
  };
  try {
    let resData = await axios(config);
    if (resData.data)
      return resData.data;
    else
      return resData
  }
  catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        Cookies.remove('token');
        window.location = '/auth/login';
      }
      if (error.response.status === 400) {
        let errorData = error.response.data;
        console.log('errorData: ', errorData);
        if (errorData) {
          errorData.is_error = true;
          return errorData;
        }
      }
    }

  }

}

export async function getProductListForStore(){
  let res = await dataService('get', 'ProductList', null);
  if(res?.data)
  {
      localStorage.setItem('products', JSON.stringify(res?.data));
      return res;
  } 
}

export async function getWorkCenterListForStore(){
  let res =  await dataService('get', 'CommonList', `Query=productWorkcenters`);
  let product = { ProductId: 0, Name: 'All' };
  if (localStorage.getItem('selected_flow')) {
      let selected_flow = JSON.parse(localStorage.getItem('selected_flow'));
      product = selected_flow?.product;
  }
  if (res?.data) {
      let workcenters = res?.data;
      let selected_flow = {
          product: product,
          workcenters: workcenters
      }
      localStorage.setItem('selected_flow', JSON.stringify(selected_flow))
      
  }

  return res;
}


export async function getURLData(url, headers) {
  var config = {
    method: 'get',
    url: url,
    headers: headers ? headers : {}
  };
  try {
    let resData = await axios(config);
    if (resData.data)
      return resData.data;
    else
      return resData
  }
  catch (error) {
    console.log('getURLData error: ', error)
    if (error.response) {
      if (error.response.status === 400) {
        let errorData = error.response.data;
        console.log('errorData: ', errorData);
        if (errorData) {
          errorData.is_error = true;
          return errorData;
        }
      }
    }
    else
    {
      if(error?.message === 'Network Error')
      {
        let errRes = {
          status: 500,
          message: `Error! Server can't be reached. Please try again later or contact server administrator!`
        }
        return errRes
      }
      else
      {
        let errRes = {
          status: 500,
          message: `Error! Please try again later or contact server administrator!`
        }
        return errRes
      }
    }

  }

}

export function currencyFormat(value) {
  return new Intl.NumberFormat('en-IN').format(value);

}
